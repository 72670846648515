body {
  margin: 0;
  padding: 0px;
  font-family: sans-serif;
}

@charset "UTF-8";
/**
 -- used http://chir.ag/projects/name-that-color/ to name color
*/
.abc-checkbox {
  cursor: default;
  padding-left: 4px; }
  .abc-checkbox label {
    cursor: pointer;
    display: inline;
    vertical-align: top;
    position: relative;
    padding-left: 5px; }
    .abc-checkbox label::before {
      cursor: pointer;
      content: "";
      display: inline-block;
      position: absolute;
      width: 17px;
      height: 17px;
      top: 2px;
      left: 0;
      margin-left: -1.25rem;
      border: 1px solid #ced4da;
      border-radius: 3px;
      background-color: #fff;
      -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
      transition: border 0.15s ease-in-out, color 0.15s ease-in-out; }
    .abc-checkbox label::after {
      cursor: pointer;
      display: inline-block;
      position: absolute;
      width: 16px;
      height: 16px;
      left: 0;
      top: 2px;
      margin-left: -1.25rem;
      padding-left: 3px;
      padding-top: 1px;
      font-size: 11px;
      color: #495057; }
  .abc-checkbox input[type="checkbox"],
  .abc-checkbox input[type="radio"] {
    position: static;
    margin-left: 0;
    cursor: pointer;
    opacity: 0;
    z-index: 1; }
    .abc-checkbox input[type="checkbox"]:focus + label::before,
    .abc-checkbox input[type="radio"]:focus + label::before {
      outline: thin dotted;
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px; }
    .abc-checkbox input[type="checkbox"]:checked + label::after,
    .abc-checkbox input[type="radio"]:checked + label::after {
      font-family: "FontAwesome";
      content: "\F00C"; }
    .abc-checkbox input[type="checkbox"]:indeterminate + label::after,
    .abc-checkbox input[type="radio"]:indeterminate + label::after {
      display: block;
      content: "";
      width: 10px;
      height: 3px;
      background-color: #555555;
      border-radius: 2px;
      margin-left: -16.5px;
      margin-top: 7px; }
    .abc-checkbox input[type="checkbox"]:disabled + label,
    .abc-checkbox input[type="radio"]:disabled + label {
      opacity: 0.65; }
      .abc-checkbox input[type="checkbox"]:disabled + label::before,
      .abc-checkbox input[type="radio"]:disabled + label::before {
        background-color: #e9ecef;
        cursor: not-allowed; }
      .abc-checkbox input[type="checkbox"]:disabled + label::after,
      .abc-checkbox input[type="radio"]:disabled + label::after {
        cursor: not-allowed; }
  .abc-checkbox.abc-checkbox-circle label::before {
    border-radius: 50%; }
  .abc-checkbox.checkbox-inline {
    margin-top: 0; }

.abc-checkbox-primary input[type="checkbox"]:checked + label::before,
.abc-checkbox-primary input[type="radio"]:checked + label::before {
  background-color: #007bff;
  border-color: #007bff; }

.abc-checkbox-primary input[type="checkbox"]:checked + label::after,
.abc-checkbox-primary input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-danger input[type="checkbox"]:checked + label::before,
.abc-checkbox-danger input[type="radio"]:checked + label::before {
  background-color: #dc3545;
  border-color: #dc3545; }

.abc-checkbox-danger input[type="checkbox"]:checked + label::after,
.abc-checkbox-danger input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-info input[type="checkbox"]:checked + label::before,
.abc-checkbox-info input[type="radio"]:checked + label::before {
  background-color: #17a2b8;
  border-color: #17a2b8; }

.abc-checkbox-info input[type="checkbox"]:checked + label::after,
.abc-checkbox-info input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-warning input[type="checkbox"]:checked + label::before,
.abc-checkbox-warning input[type="radio"]:checked + label::before {
  background-color: #ffc107;
  border-color: #ffc107; }

.abc-checkbox-warning input[type="checkbox"]:checked + label::after,
.abc-checkbox-warning input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-success input[type="checkbox"]:checked + label::before,
.abc-checkbox-success input[type="radio"]:checked + label::before {
  background-color: #28a745;
  border-color: #28a745; }

.abc-checkbox-success input[type="checkbox"]:checked + label::after,
.abc-checkbox-success input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-primary input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-primary input[type="radio"]:indeterminate + label::before {
  background-color: #007bff;
  border-color: #007bff; }

.abc-checkbox-primary input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-primary input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-danger input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-danger input[type="radio"]:indeterminate + label::before {
  background-color: #dc3545;
  border-color: #dc3545; }

.abc-checkbox-danger input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-danger input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-info input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-info input[type="radio"]:indeterminate + label::before {
  background-color: #17a2b8;
  border-color: #17a2b8; }

.abc-checkbox-info input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-info input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-warning input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-warning input[type="radio"]:indeterminate + label::before {
  background-color: #ffc107;
  border-color: #ffc107; }

.abc-checkbox-warning input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-warning input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-success input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-success input[type="radio"]:indeterminate + label::before {
  background-color: #28a745;
  border-color: #28a745; }

.abc-checkbox-success input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-success input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-radio {
  cursor: default;
  padding-left: 4px; }
  .abc-radio label {
    cursor: pointer;
    display: inline;
    vertical-align: top;
    position: relative;
    padding-left: 5px; }
    .abc-radio label::before {
      cursor: pointer;
      content: "";
      display: inline-block;
      position: absolute;
      width: 17px;
      height: 17px;
      top: 2px;
      left: 0;
      margin-left: -20px;
      border: 1px solid #ced4da;
      border-radius: 50%;
      background-color: #fff;
      -webkit-transition: border 0.15s ease-in-out;
      transition: border 0.15s ease-in-out; }
    .abc-radio label::after {
      cursor: pointer;
      display: inline-block;
      position: absolute;
      content: " ";
      width: 11px;
      height: 11px;
      left: 3px;
      top: 5px;
      margin-left: -20px;
      border-radius: 50%;
      background-color: #495057;
      -webkit-transform: scale(0, 0);
              transform: scale(0, 0);
      -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33), -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33); }
  .abc-radio input[type="radio"] {
    position: static;
    margin-left: 0;
    cursor: pointer;
    opacity: 0;
    z-index: 1; }
    .abc-radio input[type="radio"]:focus + label::before {
      outline: thin dotted;
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px; }
    .abc-radio input[type="radio"]:checked + label::after {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1); }
    .abc-radio input[type="radio"]:disabled + label {
      opacity: 0.65; }
      .abc-radio input[type="radio"]:disabled + label::before {
        cursor: not-allowed; }
      .abc-radio input[type="radio"]:disabled + label::after {
        cursor: not-allowed; }
  .abc-radio.radio-inline {
    margin-top: 0; }

.abc-radio-primary input[type="radio"] + label::after {
  background-color: #007bff; }

.abc-radio-primary input[type="radio"]:checked + label::before {
  border-color: #007bff; }

.abc-radio-primary input[type="radio"]:checked + label::after {
  background-color: #007bff; }

.abc-radio-danger input[type="radio"] + label::after {
  background-color: #dc3545; }

.abc-radio-danger input[type="radio"]:checked + label::before {
  border-color: #dc3545; }

.abc-radio-danger input[type="radio"]:checked + label::after {
  background-color: #dc3545; }

.abc-radio-info input[type="radio"] + label::after {
  background-color: #17a2b8; }

.abc-radio-info input[type="radio"]:checked + label::before {
  border-color: #17a2b8; }

.abc-radio-info input[type="radio"]:checked + label::after {
  background-color: #17a2b8; }

.abc-radio-warning input[type="radio"] + label::after {
  background-color: #ffc107; }

.abc-radio-warning input[type="radio"]:checked + label::before {
  border-color: #ffc107; }

.abc-radio-warning input[type="radio"]:checked + label::after {
  background-color: #ffc107; }

.abc-radio-success input[type="radio"] + label::after {
  background-color: #28a745; }

.abc-radio-success input[type="radio"]:checked + label::before {
  border-color: #28a745; }

.abc-radio-success input[type="radio"]:checked + label::after {
  background-color: #28a745; }

label .was-validated .form-check-input:invalid .abc-checkbox:before, label
.was-validated .form-check-input:invalid .abc-radio:before, label .form-check-input.is-invalid .abc-checkbox:before, label
.form-check-input.is-invalid .abc-radio:before {
  border-color: #dc3545; }

@font-face {
  font-family: "Petasense Open Sans";
  src: url(/static/media/OpenSans-Regular_1.73d5e4b3.woff2); }

@font-face {
  font-family: "Petasense Open Sans";
  src: url(/static/media/OpenSans-Semibold_1.59d1fe1e.woff2);
  font-weight: 600; }

@font-face {
  font-family: 'Petasense Open Sans';
  src: url(/static/media/OpenSans-Bold_1.1b0edf91.woff2);
  font-weight: bold; }

.ps-hover-pointer:hover {
  cursor: pointer; }

.ps-hover-move {
  cursor: move; }

.hide {
  height: 0;
  width: 0;
  opacity: 0; }

.ps-logo-container {
  text-align: center;
  margin-top: 15vh;
  margin-bottom: 5vh; }
  .ps-logo-container img {
    width: 61%; }

.container.align-center {
  text-align: center; }

.container .ps-routes {
  width: 32%;
  margin: 0 auto; }

form.scrollable-form {
  overflow-y: scroll;
  max-height: 60vh; }
  form.scrollable-form::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(136, 134, 134, 0.925);
            box-shadow: inset 0 0 6px rgba(136, 134, 134, 0.925);
    background-color: #f5f5f5; }
  form.scrollable-form::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5; }
  form.scrollable-form::-webkit-scrollbar-thumb {
    background-color: #999b95; }

.ps-align-center {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }

.ps-align-center-column {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-direction: column;
      flex-direction: column; }

.list-error-message {
  color: #878983;
  padding-top: 20px;
  font-weight: bold; }
  .list-error-message.machine-list {
    border: 2px dashed #D8DCD3;
    padding: 20px;
    margin-top: 10px; }
  .list-error-message p {
    font-weight: 300; }

.top-padding-20 {
  padding-top: 20px; }

.small-row {
  width: 50%; }

.dropdown-title {
  max-width: 110px;
  white-space: normal !important; }

.help-block {
  color: #a94442;
  font-size: 12px;
  opacity: 0;
  margin: 0;
  max-height: 0;
  font-weight: bold;
  pointer-events: none;
  -webkit-transition: max-height 300ms ease, opacity 300ms ease;
  transition: max-height 300ms ease, opacity 300ms ease; }
  .help-block.onboarding-header {
    padding: 0px 0px 15px 0px; }
  .help-block.pop {
    opacity: 1;
    height: auto;
    margin-top: 5px;
    margin-bottom: 5px;
    max-height: 150px;
    -webkit-transition: max-height 300ms ease, opacity 300ms ease;
    transition: max-height 300ms ease, opacity 300ms ease; }

.ps-vertical-center {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

input.error {
  color: #a94442; }

.ps-help-block {
  color: #a94442;
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: bold;
  height: 0; }

.ps-horizontal-center {
  text-align: center; }

.ps-card {
  -webkit-transition: -webkit-box-shadow 0.2s;
  transition: -webkit-box-shadow 0.2s;
  transition: box-shadow 0.2s;
  transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0), 0 3px 6px rgba(0, 0, 0, 0);
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0), 0 3px 6px rgba(0, 0, 0, 0);
  background: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  display: inline-block;
  margin: 1vh auto;
  padding: 1.5vh;
  position: relative;
  text-align: center;
  width: 90%;
  height: 70px; }
  .ps-card:hover {
    cursor: pointer; }
  .ps-card:hover {
    -webkit-box-shadow: 0 4px 12px rgba(33, 33, 33, 0.3), 0 4px 12px rgba(0, 0, 0, 0.3);
            box-shadow: 0 4px 12px rgba(33, 33, 33, 0.3), 0 4px 12px rgba(0, 0, 0, 0.3); }
  .ps-card.active {
    border-color: #91b846;
    color: #91b846; }
    .ps-card.active:hover {
      -webkit-box-shadow: 0 5px 14px rgba(145, 184, 70, 0.8), 0 5px 14px rgba(145, 184, 70, 0.8);
              box-shadow: 0 5px 14px rgba(145, 184, 70, 0.8), 0 5px 14px rgba(145, 184, 70, 0.8);
      cursor: pointer; }
  .ps-card .title {
    text-align: left;
    margin: auto;
    font-size: 95%;
    font-weight: bold; }

.abc-checkbox {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .abc-checkbox label {
    font-family: "Petasense Open Sans";
    font-size: 16px;
    font-weight: normal;
    margin: 0px 10px 0px 19px; }
    .abc-checkbox label:after {
      color: #91b846;
      margin-left: -23px; }
    .abc-checkbox label:before {
      border-radius: 0px;
      margin-left: -23px;
      top: 2px; }

.full-screen-center {
  position: absolute;
  top: 50%;
  left: 50%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }

.fill-remaining-space {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-positive: 1;
      flex-grow: 1; }

.btn {
  height: 35px;
  font-size: 16px;
  margin: 3px 0;
  border-width: 0;
  width: 100%; }
  .btn.btn-primary {
    font-family: "Petasense Open Sans";
    background-color: #91b846;
    border-radius: 0px;
    color: white;
    margin-bottom: 10px; }
    .btn.btn-primary:hover, .btn.btn-primary:focus {
      background-color: #91b846; }
  .btn.btn-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    font-family: "Petasense Open Sans";
    background-color: "transparent";
    color: #91b846;
    -ms-flex-pack: center;
        justify-content: center; }

/**
------------------------------------------------------- Machine Severity CSS ------------------------------------------------------
 */
.ps-good-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-good-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-good-health:before {
    background: #91B846; }

.ps-slight-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-slight-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-slight-health:before {
    background: #4E89F4; }

.ps-moderate-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-moderate-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-moderate-health:before {
    background: #E4BE0C; }

.ps-warning-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-warning-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-warning-health:before {
    background: #F38F0A; }

.ps-critical-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-critical-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-critical-health:before {
    background: #E61111; }

.ps-unknown-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-unknown-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-unknown-health:before {
    background: #d3d3d3; }

.machine-condition-devider {
  height: 16px;
  width: 1px;
  background-color: #d8d8d6;
  margin: 0px 30px;
  visibility: hidden; }

.fixed-bar {
  position: fixed;
  background: #ffffff;
  width: 100%;
  z-index: 2;
  top: 0px; }

.ui.segment {
  border: none;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin: 0px;
  -webkit-box-shadow: none;
          box-shadow: none; }

.ui.grid > .row {
  padding-left: 1.1em;
  padding-top: 1rem;
  padding-bottom: 1rem; }

.custom-grid {
  border: 2px solid #f0f0f0;
  padding: 20px;
  width: 100%; }

.auth-page {
  background: white;
  max-width: 350px; }
  .auth-page .help-block.pop {
    font-weight: 300;
    color: #ff4f39; }
  .auth-page .form-group {
    height: auto;
    margin-top: 15px; }
    .auth-page .form-group input {
      -webkit-transition: border-color .2s ease;
      transition: border-color .2s ease; }
      .auth-page .form-group input:focus {
        -webkit-box-shadow: none;
                box-shadow: none;
        border: 1px solid #8c96a9; }
    .auth-page .form-group .help-block.pop {
      padding-left: 4px; }
    .auth-page .form-group #LoginPage-Button-SignIn {
      border-radius: 4px;
      height: 40px;
      -webkit-transition: box-shadow .2s ease,border .2s ease;
      -webkit-transition: border .2s ease,-webkit-box-shadow .2s ease;
      transition: border .2s ease,-webkit-box-shadow .2s ease;
      transition: box-shadow .2s ease,border .2s ease;
      transition: box-shadow .2s ease,border .2s ease,-webkit-box-shadow .2s ease;
      -webkit-transition-property: border, -webkit-box-shadow;
      transition-property: border, -webkit-box-shadow;
      transition-property: box-shadow, border;
      transition-property: box-shadow, border, -webkit-box-shadow;
      -webkit-transition-duration: 0.2s, 0.2s;
              transition-duration: 0.2s, 0.2s;
      -webkit-transition-timing-function: ease, ease;
              transition-timing-function: ease, ease;
      -webkit-transition-delay: 0s, 0s;
              transition-delay: 0s, 0s;
      -webkit-transition-delay: initial, initial;
              transition-delay: initial, initial; }
      .auth-page .form-group #LoginPage-Button-SignIn:hover {
        -webkit-box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.25);
                box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.25); }
      .auth-page .form-group #LoginPage-Button-SignIn:focus {
        -webkit-box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.25);
                box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.25);
        border: none;
        outline: none; }
      .auth-page .form-group #LoginPage-Button-SignIn:active {
        -webkit-box-shadow: none;
                box-shadow: none; }
    .auth-page .form-group #LoginPage-Link-ForgotPassword {
      width: 134px;
      margin: 22px auto;
      height: 20px;
      padding: 0;
      text-decoration: unset;
      font-weight: 300; }
      .auth-page .form-group #LoginPage-Link-ForgotPassword:hover {
        outline: 0;
        font-weight: 600; }
  .auth-page.align-center {
    text-align: center; }
  .auth-page .warning-msg {
    color: #ccc;
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center; }
    .auth-page .warning-msg.red {
      color: red; }
    .auth-page .warning-msg.green {
      color: green; }
    .auth-page .warning-msg.black {
      color: black; }
    .auth-page .warning-msg.large {
      font-size: 17px; }
    .auth-page .warning-msg.bold {
      font-weight: bold; }

.normal-checkbox-label {
  white-space: nowrap; }

.form-control.fixed {
  resize: none;
  height: 100%; }

/**
 -- used http://chir.ag/projects/name-that-color/ to name color
*/
.abc-checkbox {
  cursor: default;
  padding-left: 4px; }
  .abc-checkbox label {
    cursor: pointer;
    display: inline;
    vertical-align: top;
    position: relative;
    padding-left: 5px; }
    .abc-checkbox label::before {
      cursor: pointer;
      content: "";
      display: inline-block;
      position: absolute;
      width: 17px;
      height: 17px;
      top: 2px;
      left: 0;
      margin-left: -1.25rem;
      border: 1px solid #ced4da;
      border-radius: 3px;
      background-color: #fff;
      -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
      transition: border 0.15s ease-in-out, color 0.15s ease-in-out; }
    .abc-checkbox label::after {
      cursor: pointer;
      display: inline-block;
      position: absolute;
      width: 16px;
      height: 16px;
      left: 0;
      top: 2px;
      margin-left: -1.25rem;
      padding-left: 3px;
      padding-top: 1px;
      font-size: 11px;
      color: #495057; }
  .abc-checkbox input[type="checkbox"],
  .abc-checkbox input[type="radio"] {
    position: static;
    margin-left: 0;
    cursor: pointer;
    opacity: 0;
    z-index: 1; }
    .abc-checkbox input[type="checkbox"]:focus + label::before,
    .abc-checkbox input[type="radio"]:focus + label::before {
      outline: thin dotted;
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px; }
    .abc-checkbox input[type="checkbox"]:checked + label::after,
    .abc-checkbox input[type="radio"]:checked + label::after {
      font-family: "FontAwesome";
      content: "\F00C"; }
    .abc-checkbox input[type="checkbox"]:indeterminate + label::after,
    .abc-checkbox input[type="radio"]:indeterminate + label::after {
      display: block;
      content: "";
      width: 10px;
      height: 3px;
      background-color: #555555;
      border-radius: 2px;
      margin-left: -16.5px;
      margin-top: 7px; }
    .abc-checkbox input[type="checkbox"]:disabled + label,
    .abc-checkbox input[type="radio"]:disabled + label {
      opacity: 0.65; }
      .abc-checkbox input[type="checkbox"]:disabled + label::before,
      .abc-checkbox input[type="radio"]:disabled + label::before {
        background-color: #e9ecef;
        cursor: not-allowed; }
      .abc-checkbox input[type="checkbox"]:disabled + label::after,
      .abc-checkbox input[type="radio"]:disabled + label::after {
        cursor: not-allowed; }
  .abc-checkbox.abc-checkbox-circle label::before {
    border-radius: 50%; }
  .abc-checkbox.checkbox-inline {
    margin-top: 0; }

.abc-checkbox-primary input[type="checkbox"]:checked + label::before,
.abc-checkbox-primary input[type="radio"]:checked + label::before {
  background-color: #007bff;
  border-color: #007bff; }

.abc-checkbox-primary input[type="checkbox"]:checked + label::after,
.abc-checkbox-primary input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-danger input[type="checkbox"]:checked + label::before,
.abc-checkbox-danger input[type="radio"]:checked + label::before {
  background-color: #dc3545;
  border-color: #dc3545; }

.abc-checkbox-danger input[type="checkbox"]:checked + label::after,
.abc-checkbox-danger input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-info input[type="checkbox"]:checked + label::before,
.abc-checkbox-info input[type="radio"]:checked + label::before {
  background-color: #17a2b8;
  border-color: #17a2b8; }

.abc-checkbox-info input[type="checkbox"]:checked + label::after,
.abc-checkbox-info input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-warning input[type="checkbox"]:checked + label::before,
.abc-checkbox-warning input[type="radio"]:checked + label::before {
  background-color: #ffc107;
  border-color: #ffc107; }

.abc-checkbox-warning input[type="checkbox"]:checked + label::after,
.abc-checkbox-warning input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-success input[type="checkbox"]:checked + label::before,
.abc-checkbox-success input[type="radio"]:checked + label::before {
  background-color: #28a745;
  border-color: #28a745; }

.abc-checkbox-success input[type="checkbox"]:checked + label::after,
.abc-checkbox-success input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-primary input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-primary input[type="radio"]:indeterminate + label::before {
  background-color: #007bff;
  border-color: #007bff; }

.abc-checkbox-primary input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-primary input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-danger input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-danger input[type="radio"]:indeterminate + label::before {
  background-color: #dc3545;
  border-color: #dc3545; }

.abc-checkbox-danger input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-danger input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-info input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-info input[type="radio"]:indeterminate + label::before {
  background-color: #17a2b8;
  border-color: #17a2b8; }

.abc-checkbox-info input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-info input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-warning input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-warning input[type="radio"]:indeterminate + label::before {
  background-color: #ffc107;
  border-color: #ffc107; }

.abc-checkbox-warning input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-warning input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-success input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-success input[type="radio"]:indeterminate + label::before {
  background-color: #28a745;
  border-color: #28a745; }

.abc-checkbox-success input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-success input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-radio {
  cursor: default;
  padding-left: 4px; }
  .abc-radio label {
    cursor: pointer;
    display: inline;
    vertical-align: top;
    position: relative;
    padding-left: 5px; }
    .abc-radio label::before {
      cursor: pointer;
      content: "";
      display: inline-block;
      position: absolute;
      width: 17px;
      height: 17px;
      top: 2px;
      left: 0;
      margin-left: -20px;
      border: 1px solid #ced4da;
      border-radius: 50%;
      background-color: #fff;
      -webkit-transition: border 0.15s ease-in-out;
      transition: border 0.15s ease-in-out; }
    .abc-radio label::after {
      cursor: pointer;
      display: inline-block;
      position: absolute;
      content: " ";
      width: 11px;
      height: 11px;
      left: 3px;
      top: 5px;
      margin-left: -20px;
      border-radius: 50%;
      background-color: #495057;
      -webkit-transform: scale(0, 0);
              transform: scale(0, 0);
      -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33), -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33); }
  .abc-radio input[type="radio"] {
    position: static;
    margin-left: 0;
    cursor: pointer;
    opacity: 0;
    z-index: 1; }
    .abc-radio input[type="radio"]:focus + label::before {
      outline: thin dotted;
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px; }
    .abc-radio input[type="radio"]:checked + label::after {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1); }
    .abc-radio input[type="radio"]:disabled + label {
      opacity: 0.65; }
      .abc-radio input[type="radio"]:disabled + label::before {
        cursor: not-allowed; }
      .abc-radio input[type="radio"]:disabled + label::after {
        cursor: not-allowed; }
  .abc-radio.radio-inline {
    margin-top: 0; }

.abc-radio-primary input[type="radio"] + label::after {
  background-color: #007bff; }

.abc-radio-primary input[type="radio"]:checked + label::before {
  border-color: #007bff; }

.abc-radio-primary input[type="radio"]:checked + label::after {
  background-color: #007bff; }

.abc-radio-danger input[type="radio"] + label::after {
  background-color: #dc3545; }

.abc-radio-danger input[type="radio"]:checked + label::before {
  border-color: #dc3545; }

.abc-radio-danger input[type="radio"]:checked + label::after {
  background-color: #dc3545; }

.abc-radio-info input[type="radio"] + label::after {
  background-color: #17a2b8; }

.abc-radio-info input[type="radio"]:checked + label::before {
  border-color: #17a2b8; }

.abc-radio-info input[type="radio"]:checked + label::after {
  background-color: #17a2b8; }

.abc-radio-warning input[type="radio"] + label::after {
  background-color: #ffc107; }

.abc-radio-warning input[type="radio"]:checked + label::before {
  border-color: #ffc107; }

.abc-radio-warning input[type="radio"]:checked + label::after {
  background-color: #ffc107; }

.abc-radio-success input[type="radio"] + label::after {
  background-color: #28a745; }

.abc-radio-success input[type="radio"]:checked + label::before {
  border-color: #28a745; }

.abc-radio-success input[type="radio"]:checked + label::after {
  background-color: #28a745; }

label .was-validated .form-check-input:invalid .abc-checkbox:before, label
.was-validated .form-check-input:invalid .abc-radio:before, label .form-check-input.is-invalid .abc-checkbox:before, label
.form-check-input.is-invalid .abc-radio:before {
  border-color: #dc3545; }

@font-face {
  font-family: "Petasense Open Sans";
  src: url(/static/media/OpenSans-Regular_1.73d5e4b3.woff2); }

@font-face {
  font-family: "Petasense Open Sans";
  src: url(/static/media/OpenSans-Semibold_1.59d1fe1e.woff2);
  font-weight: 600; }

@font-face {
  font-family: 'Petasense Open Sans';
  src: url(/static/media/OpenSans-Bold_1.1b0edf91.woff2);
  font-weight: bold; }

.ps-hover-pointer:hover {
  cursor: pointer; }

.ps-hover-move {
  cursor: move; }

.hide {
  height: 0;
  width: 0;
  opacity: 0; }

.ps-logo-container {
  text-align: center;
  margin-top: 15vh;
  margin-bottom: 5vh; }
  .ps-logo-container img {
    width: 61%; }

.container.align-center {
  text-align: center; }

.container .ps-routes {
  width: 32%;
  margin: 0 auto; }

form.scrollable-form {
  overflow-y: scroll;
  max-height: 60vh; }
  form.scrollable-form::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(136, 134, 134, 0.925);
            box-shadow: inset 0 0 6px rgba(136, 134, 134, 0.925);
    background-color: #f5f5f5; }
  form.scrollable-form::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5; }
  form.scrollable-form::-webkit-scrollbar-thumb {
    background-color: #999b95; }

.ps-align-center {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }

.ps-align-center-column {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-direction: column;
      flex-direction: column; }

.list-error-message {
  color: #878983;
  padding-top: 20px;
  font-weight: bold; }
  .list-error-message.machine-list {
    border: 2px dashed #D8DCD3;
    padding: 20px;
    margin-top: 10px; }
  .list-error-message p {
    font-weight: 300; }

.top-padding-20 {
  padding-top: 20px; }

.small-row {
  width: 50%; }

.dropdown-title {
  max-width: 110px;
  white-space: normal !important; }

.help-block {
  color: #a94442;
  font-size: 12px;
  opacity: 0;
  margin: 0;
  max-height: 0;
  font-weight: bold;
  pointer-events: none;
  -webkit-transition: max-height 300ms ease, opacity 300ms ease;
  transition: max-height 300ms ease, opacity 300ms ease; }
  .help-block.onboarding-header {
    padding: 0px 0px 15px 0px; }
  .help-block.pop {
    opacity: 1;
    height: auto;
    margin-top: 5px;
    margin-bottom: 5px;
    max-height: 150px;
    -webkit-transition: max-height 300ms ease, opacity 300ms ease;
    transition: max-height 300ms ease, opacity 300ms ease; }

.ps-vertical-center {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

input.error {
  color: #a94442; }

.ps-help-block {
  color: #a94442;
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: bold;
  height: 0; }

.ps-horizontal-center {
  text-align: center; }

.ps-card {
  -webkit-transition: -webkit-box-shadow 0.2s;
  transition: -webkit-box-shadow 0.2s;
  transition: box-shadow 0.2s;
  transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0), 0 3px 6px rgba(0, 0, 0, 0);
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0), 0 3px 6px rgba(0, 0, 0, 0);
  background: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  display: inline-block;
  margin: 1vh auto;
  padding: 1.5vh;
  position: relative;
  text-align: center;
  width: 90%;
  height: 70px; }
  .ps-card:hover {
    cursor: pointer; }
  .ps-card:hover {
    -webkit-box-shadow: 0 4px 12px rgba(33, 33, 33, 0.3), 0 4px 12px rgba(0, 0, 0, 0.3);
            box-shadow: 0 4px 12px rgba(33, 33, 33, 0.3), 0 4px 12px rgba(0, 0, 0, 0.3); }
  .ps-card.active {
    border-color: #91b846;
    color: #91b846; }
    .ps-card.active:hover {
      -webkit-box-shadow: 0 5px 14px rgba(145, 184, 70, 0.8), 0 5px 14px rgba(145, 184, 70, 0.8);
              box-shadow: 0 5px 14px rgba(145, 184, 70, 0.8), 0 5px 14px rgba(145, 184, 70, 0.8);
      cursor: pointer; }
  .ps-card .title {
    text-align: left;
    margin: auto;
    font-size: 95%;
    font-weight: bold; }

.abc-checkbox {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .abc-checkbox label {
    font-family: "Petasense Open Sans";
    font-size: 16px;
    font-weight: normal;
    margin: 0px 10px 0px 19px; }
    .abc-checkbox label:after {
      color: #91b846;
      margin-left: -23px; }
    .abc-checkbox label:before {
      border-radius: 0px;
      margin-left: -23px;
      top: 2px; }

.full-screen-center {
  position: absolute;
  top: 50%;
  left: 50%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }

.fill-remaining-space {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-positive: 1;
      flex-grow: 1; }

.btn {
  height: 35px;
  font-size: 16px;
  margin: 3px 0;
  border-width: 0;
  width: 100%; }
  .btn.btn-primary {
    font-family: "Petasense Open Sans";
    background-color: #91b846;
    border-radius: 0px;
    color: white;
    margin-bottom: 10px; }
    .btn.btn-primary:hover, .btn.btn-primary:focus {
      background-color: #91b846; }
  .btn.btn-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    font-family: "Petasense Open Sans";
    background-color: "transparent";
    color: #91b846;
    -ms-flex-pack: center;
        justify-content: center; }

/**
------------------------------------------------------- Machine Severity CSS ------------------------------------------------------
 */
.ps-good-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-good-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-good-health:before {
    background: #91B846; }

.ps-slight-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-slight-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-slight-health:before {
    background: #4E89F4; }

.ps-moderate-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-moderate-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-moderate-health:before {
    background: #E4BE0C; }

.ps-warning-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-warning-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-warning-health:before {
    background: #F38F0A; }

.ps-critical-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-critical-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-critical-health:before {
    background: #E61111; }

.ps-unknown-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-unknown-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-unknown-health:before {
    background: #d3d3d3; }

.machine-condition-devider {
  height: 16px;
  width: 1px;
  background-color: #d8d8d6;
  margin: 0px 30px;
  visibility: hidden; }

.fixed-bar {
  position: fixed;
  background: #ffffff;
  width: 100%;
  z-index: 2;
  top: 0px; }

.ui.segment {
  border: none;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin: 0px;
  -webkit-box-shadow: none;
          box-shadow: none; }

.ui.grid > .row {
  padding-left: 1.1em;
  padding-top: 1rem;
  padding-bottom: 1rem; }

.custom-grid {
  border: 2px solid #f0f0f0;
  padding: 20px;
  width: 100%; }

.register-page .help-block.info {
  color: #737373; }

.register-page .form-group {
  height: auto;
  margin-top: 15px;
  margin-bottom: 25px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }

.register-page .btn.btn-link {
  width: auto;
  padding: 0px; }

.shadow {
  -webkit-filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.3));
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.3)); }

.pad {
  padding: 20px; }

.head-label {
  margin: 0px;
  padding: 20px 0px 20px 0px; }

.selector-container {
  padding-left: 10%; }
  .selector-container a {
    display: inline-block;
    list-style-type: none;
    margin-left: 25px;
    padding: 3px 15px;
    margin-bottom: 10px;
    border: 1px solid transparent; }
  .selector-container a:hover {
    text-decoration: none; }
  .selector-container a span {
    margin-right: 9px;
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-top: 5px;
    border-radius: 20px;
    background-color: #3f5175; }
  .selector-container a.active {
    border: 1px solid rgba(0, 0, 0, 0.2); }

/**
 -- used http://chir.ag/projects/name-that-color/ to name color
*/
.abc-checkbox {
  cursor: default;
  padding-left: 4px; }
  .abc-checkbox label {
    cursor: pointer;
    display: inline;
    vertical-align: top;
    position: relative;
    padding-left: 5px; }
    .abc-checkbox label::before {
      cursor: pointer;
      content: "";
      display: inline-block;
      position: absolute;
      width: 17px;
      height: 17px;
      top: 2px;
      left: 0;
      margin-left: -1.25rem;
      border: 1px solid #ced4da;
      border-radius: 3px;
      background-color: #fff;
      -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
      transition: border 0.15s ease-in-out, color 0.15s ease-in-out; }
    .abc-checkbox label::after {
      cursor: pointer;
      display: inline-block;
      position: absolute;
      width: 16px;
      height: 16px;
      left: 0;
      top: 2px;
      margin-left: -1.25rem;
      padding-left: 3px;
      padding-top: 1px;
      font-size: 11px;
      color: #495057; }
  .abc-checkbox input[type="checkbox"],
  .abc-checkbox input[type="radio"] {
    position: static;
    margin-left: 0;
    cursor: pointer;
    opacity: 0;
    z-index: 1; }
    .abc-checkbox input[type="checkbox"]:focus + label::before,
    .abc-checkbox input[type="radio"]:focus + label::before {
      outline: thin dotted;
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px; }
    .abc-checkbox input[type="checkbox"]:checked + label::after,
    .abc-checkbox input[type="radio"]:checked + label::after {
      font-family: "FontAwesome";
      content: "\F00C"; }
    .abc-checkbox input[type="checkbox"]:indeterminate + label::after,
    .abc-checkbox input[type="radio"]:indeterminate + label::after {
      display: block;
      content: "";
      width: 10px;
      height: 3px;
      background-color: #555555;
      border-radius: 2px;
      margin-left: -16.5px;
      margin-top: 7px; }
    .abc-checkbox input[type="checkbox"]:disabled + label,
    .abc-checkbox input[type="radio"]:disabled + label {
      opacity: 0.65; }
      .abc-checkbox input[type="checkbox"]:disabled + label::before,
      .abc-checkbox input[type="radio"]:disabled + label::before {
        background-color: #e9ecef;
        cursor: not-allowed; }
      .abc-checkbox input[type="checkbox"]:disabled + label::after,
      .abc-checkbox input[type="radio"]:disabled + label::after {
        cursor: not-allowed; }
  .abc-checkbox.abc-checkbox-circle label::before {
    border-radius: 50%; }
  .abc-checkbox.checkbox-inline {
    margin-top: 0; }

.abc-checkbox-primary input[type="checkbox"]:checked + label::before,
.abc-checkbox-primary input[type="radio"]:checked + label::before {
  background-color: #007bff;
  border-color: #007bff; }

.abc-checkbox-primary input[type="checkbox"]:checked + label::after,
.abc-checkbox-primary input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-danger input[type="checkbox"]:checked + label::before,
.abc-checkbox-danger input[type="radio"]:checked + label::before {
  background-color: #dc3545;
  border-color: #dc3545; }

.abc-checkbox-danger input[type="checkbox"]:checked + label::after,
.abc-checkbox-danger input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-info input[type="checkbox"]:checked + label::before,
.abc-checkbox-info input[type="radio"]:checked + label::before {
  background-color: #17a2b8;
  border-color: #17a2b8; }

.abc-checkbox-info input[type="checkbox"]:checked + label::after,
.abc-checkbox-info input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-warning input[type="checkbox"]:checked + label::before,
.abc-checkbox-warning input[type="radio"]:checked + label::before {
  background-color: #ffc107;
  border-color: #ffc107; }

.abc-checkbox-warning input[type="checkbox"]:checked + label::after,
.abc-checkbox-warning input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-success input[type="checkbox"]:checked + label::before,
.abc-checkbox-success input[type="radio"]:checked + label::before {
  background-color: #28a745;
  border-color: #28a745; }

.abc-checkbox-success input[type="checkbox"]:checked + label::after,
.abc-checkbox-success input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-primary input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-primary input[type="radio"]:indeterminate + label::before {
  background-color: #007bff;
  border-color: #007bff; }

.abc-checkbox-primary input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-primary input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-danger input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-danger input[type="radio"]:indeterminate + label::before {
  background-color: #dc3545;
  border-color: #dc3545; }

.abc-checkbox-danger input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-danger input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-info input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-info input[type="radio"]:indeterminate + label::before {
  background-color: #17a2b8;
  border-color: #17a2b8; }

.abc-checkbox-info input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-info input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-warning input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-warning input[type="radio"]:indeterminate + label::before {
  background-color: #ffc107;
  border-color: #ffc107; }

.abc-checkbox-warning input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-warning input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-success input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-success input[type="radio"]:indeterminate + label::before {
  background-color: #28a745;
  border-color: #28a745; }

.abc-checkbox-success input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-success input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-radio {
  cursor: default;
  padding-left: 4px; }
  .abc-radio label {
    cursor: pointer;
    display: inline;
    vertical-align: top;
    position: relative;
    padding-left: 5px; }
    .abc-radio label::before {
      cursor: pointer;
      content: "";
      display: inline-block;
      position: absolute;
      width: 17px;
      height: 17px;
      top: 2px;
      left: 0;
      margin-left: -20px;
      border: 1px solid #ced4da;
      border-radius: 50%;
      background-color: #fff;
      -webkit-transition: border 0.15s ease-in-out;
      transition: border 0.15s ease-in-out; }
    .abc-radio label::after {
      cursor: pointer;
      display: inline-block;
      position: absolute;
      content: " ";
      width: 11px;
      height: 11px;
      left: 3px;
      top: 5px;
      margin-left: -20px;
      border-radius: 50%;
      background-color: #495057;
      -webkit-transform: scale(0, 0);
              transform: scale(0, 0);
      -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33), -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33); }
  .abc-radio input[type="radio"] {
    position: static;
    margin-left: 0;
    cursor: pointer;
    opacity: 0;
    z-index: 1; }
    .abc-radio input[type="radio"]:focus + label::before {
      outline: thin dotted;
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px; }
    .abc-radio input[type="radio"]:checked + label::after {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1); }
    .abc-radio input[type="radio"]:disabled + label {
      opacity: 0.65; }
      .abc-radio input[type="radio"]:disabled + label::before {
        cursor: not-allowed; }
      .abc-radio input[type="radio"]:disabled + label::after {
        cursor: not-allowed; }
  .abc-radio.radio-inline {
    margin-top: 0; }

.abc-radio-primary input[type="radio"] + label::after {
  background-color: #007bff; }

.abc-radio-primary input[type="radio"]:checked + label::before {
  border-color: #007bff; }

.abc-radio-primary input[type="radio"]:checked + label::after {
  background-color: #007bff; }

.abc-radio-danger input[type="radio"] + label::after {
  background-color: #dc3545; }

.abc-radio-danger input[type="radio"]:checked + label::before {
  border-color: #dc3545; }

.abc-radio-danger input[type="radio"]:checked + label::after {
  background-color: #dc3545; }

.abc-radio-info input[type="radio"] + label::after {
  background-color: #17a2b8; }

.abc-radio-info input[type="radio"]:checked + label::before {
  border-color: #17a2b8; }

.abc-radio-info input[type="radio"]:checked + label::after {
  background-color: #17a2b8; }

.abc-radio-warning input[type="radio"] + label::after {
  background-color: #ffc107; }

.abc-radio-warning input[type="radio"]:checked + label::before {
  border-color: #ffc107; }

.abc-radio-warning input[type="radio"]:checked + label::after {
  background-color: #ffc107; }

.abc-radio-success input[type="radio"] + label::after {
  background-color: #28a745; }

.abc-radio-success input[type="radio"]:checked + label::before {
  border-color: #28a745; }

.abc-radio-success input[type="radio"]:checked + label::after {
  background-color: #28a745; }

label .was-validated .form-check-input:invalid .abc-checkbox:before, label
.was-validated .form-check-input:invalid .abc-radio:before, label .form-check-input.is-invalid .abc-checkbox:before, label
.form-check-input.is-invalid .abc-radio:before {
  border-color: #dc3545; }

@font-face {
  font-family: "Petasense Open Sans";
  src: url(/static/media/OpenSans-Regular_1.73d5e4b3.woff2); }

@font-face {
  font-family: "Petasense Open Sans";
  src: url(/static/media/OpenSans-Semibold_1.59d1fe1e.woff2);
  font-weight: 600; }

@font-face {
  font-family: 'Petasense Open Sans';
  src: url(/static/media/OpenSans-Bold_1.1b0edf91.woff2);
  font-weight: bold; }

.ps-hover-pointer:hover {
  cursor: pointer; }

.ps-hover-move {
  cursor: move; }

.hide {
  height: 0;
  width: 0;
  opacity: 0; }

.ps-logo-container {
  text-align: center;
  margin-top: 15vh;
  margin-bottom: 5vh; }
  .ps-logo-container img {
    width: 61%; }

.container.align-center {
  text-align: center; }

.container .ps-routes {
  width: 32%;
  margin: 0 auto; }

form.scrollable-form {
  overflow-y: scroll;
  max-height: 60vh; }
  form.scrollable-form::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(136, 134, 134, 0.925);
            box-shadow: inset 0 0 6px rgba(136, 134, 134, 0.925);
    background-color: #f5f5f5; }
  form.scrollable-form::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5; }
  form.scrollable-form::-webkit-scrollbar-thumb {
    background-color: #999b95; }

.ps-align-center {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }

.ps-align-center-column {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-direction: column;
      flex-direction: column; }

.list-error-message {
  color: #878983;
  padding-top: 20px;
  font-weight: bold; }
  .list-error-message.machine-list {
    border: 2px dashed #D8DCD3;
    padding: 20px;
    margin-top: 10px; }
  .list-error-message p {
    font-weight: 300; }

.top-padding-20 {
  padding-top: 20px; }

.small-row {
  width: 50%; }

.dropdown-title {
  max-width: 110px;
  white-space: normal !important; }

.help-block {
  color: #a94442;
  font-size: 12px;
  opacity: 0;
  margin: 0;
  max-height: 0;
  font-weight: bold;
  pointer-events: none;
  -webkit-transition: max-height 300ms ease, opacity 300ms ease;
  transition: max-height 300ms ease, opacity 300ms ease; }
  .help-block.onboarding-header {
    padding: 0px 0px 15px 0px; }
  .help-block.pop {
    opacity: 1;
    height: auto;
    margin-top: 5px;
    margin-bottom: 5px;
    max-height: 150px;
    -webkit-transition: max-height 300ms ease, opacity 300ms ease;
    transition: max-height 300ms ease, opacity 300ms ease; }

.ps-vertical-center {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

input.error {
  color: #a94442; }

.ps-help-block {
  color: #a94442;
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: bold;
  height: 0; }

.ps-horizontal-center {
  text-align: center; }

.ps-card {
  -webkit-transition: -webkit-box-shadow 0.2s;
  transition: -webkit-box-shadow 0.2s;
  transition: box-shadow 0.2s;
  transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0), 0 3px 6px rgba(0, 0, 0, 0);
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0), 0 3px 6px rgba(0, 0, 0, 0);
  background: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  display: inline-block;
  margin: 1vh auto;
  padding: 1.5vh;
  position: relative;
  text-align: center;
  width: 90%;
  height: 70px; }
  .ps-card:hover {
    cursor: pointer; }
  .ps-card:hover {
    -webkit-box-shadow: 0 4px 12px rgba(33, 33, 33, 0.3), 0 4px 12px rgba(0, 0, 0, 0.3);
            box-shadow: 0 4px 12px rgba(33, 33, 33, 0.3), 0 4px 12px rgba(0, 0, 0, 0.3); }
  .ps-card.active {
    border-color: #91b846;
    color: #91b846; }
    .ps-card.active:hover {
      -webkit-box-shadow: 0 5px 14px rgba(145, 184, 70, 0.8), 0 5px 14px rgba(145, 184, 70, 0.8);
              box-shadow: 0 5px 14px rgba(145, 184, 70, 0.8), 0 5px 14px rgba(145, 184, 70, 0.8);
      cursor: pointer; }
  .ps-card .title {
    text-align: left;
    margin: auto;
    font-size: 95%;
    font-weight: bold; }

.abc-checkbox {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .abc-checkbox label {
    font-family: "Petasense Open Sans";
    font-size: 16px;
    font-weight: normal;
    margin: 0px 10px 0px 19px; }
    .abc-checkbox label:after {
      color: #91b846;
      margin-left: -23px; }
    .abc-checkbox label:before {
      border-radius: 0px;
      margin-left: -23px;
      top: 2px; }

.full-screen-center {
  position: absolute;
  top: 50%;
  left: 50%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }

.fill-remaining-space {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-positive: 1;
      flex-grow: 1; }

.btn {
  height: 35px;
  font-size: 16px;
  margin: 3px 0;
  border-width: 0;
  width: 100%; }
  .btn.btn-primary {
    font-family: "Petasense Open Sans";
    background-color: #91b846;
    border-radius: 0px;
    color: white;
    margin-bottom: 10px; }
    .btn.btn-primary:hover, .btn.btn-primary:focus {
      background-color: #91b846; }
  .btn.btn-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    font-family: "Petasense Open Sans";
    background-color: "transparent";
    color: #91b846;
    -ms-flex-pack: center;
        justify-content: center; }

/**
------------------------------------------------------- Machine Severity CSS ------------------------------------------------------
 */
.ps-good-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-good-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-good-health:before {
    background: #91B846; }

.ps-slight-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-slight-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-slight-health:before {
    background: #4E89F4; }

.ps-moderate-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-moderate-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-moderate-health:before {
    background: #E4BE0C; }

.ps-warning-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-warning-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-warning-health:before {
    background: #F38F0A; }

.ps-critical-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-critical-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-critical-health:before {
    background: #E61111; }

.ps-unknown-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-unknown-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-unknown-health:before {
    background: #d3d3d3; }

.machine-condition-devider {
  height: 16px;
  width: 1px;
  background-color: #d8d8d6;
  margin: 0px 30px;
  visibility: hidden; }

.fixed-bar {
  position: fixed;
  background: #ffffff;
  width: 100%;
  z-index: 2;
  top: 0px; }

.ui.segment {
  border: none;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin: 0px;
  -webkit-box-shadow: none;
          box-shadow: none; }

.ui.grid > .row {
  padding-left: 1.1em;
  padding-top: 1rem;
  padding-bottom: 1rem; }

.custom-grid {
  border: 2px solid #f0f0f0;
  padding: 20px;
  width: 100%; }

.ps-chart-svg {
  overflow: visible !important; }
  .ps-chart-svg .ps-chart-lines {
    pointer-events: none; }

.ps-charts-legend {
  font-size: 14px; }

.ps-overlay-rectangle {
  fill: none;
  pointer-events: all;
  cursor: pointer;
  stroke-width: 1px;
  stroke: #ccc; }

.ps-hover-line-group {
  pointer-events: none; }

.ps-chart-header {
  white-space: nowrap;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 1em; }
  .ps-chart-header h3 {
    font-size: 14px;
    margin: 0px; }
  .ps-chart-header .ps-chart-header-left {
    -ms-flex: 1 1;
        flex: 1 1; }
  .ps-chart-header .ps-chart-header-right {
    -ms-flex: 1 1;
        flex: 1 1;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: end;
        justify-content: flex-end;
    -ms-flex-align: center;
        align-items: center;
    margin-right: 30px; }

.linetooltip-container {
  margin-bottom: 10px;
  font-size: 10px; }
  .linetooltip-container .line-tooltip {
    color: #383838;
    height: 13px; }
    .linetooltip-container .line-tooltip.x {
      font-weight: normal; }
    .linetooltip-container .line-tooltip.y {
      font-weight: bold; }

.y-grid .tick {
  stroke: #eee;
  opacity: 1; }

.y-grid .domain {
  stroke-width: 0px; }

.axis {
  fill: #383838;
  opacity: 1; }
  .axis path {
    fill: none; }
  .axis line {
    fill: none;
    stroke: none; }
  .axis text {
    font-size: 10px; }

.x-axis-label {
  font-size: 10px;
  cursor: pointer; }

.y-axis-label {
  font-size: 10px; }

.brush .extent {
  fill: rgba(56, 56, 56, 0.125); }

.resize rect.handler {
  visibility: visible !important;
  fill: #AFAFAF;
  width: 3px;
  height: 14px; }

.resize rect.brush-border {
  visibility: visible !important;
  fill: #AFAFAF;
  width: 1px;
  height: 20px; }

/**
 -- used http://chir.ag/projects/name-that-color/ to name color
*/
.abc-checkbox {
  cursor: default;
  padding-left: 4px; }
  .abc-checkbox label {
    cursor: pointer;
    display: inline;
    vertical-align: top;
    position: relative;
    padding-left: 5px; }
    .abc-checkbox label::before {
      cursor: pointer;
      content: "";
      display: inline-block;
      position: absolute;
      width: 17px;
      height: 17px;
      top: 2px;
      left: 0;
      margin-left: -1.25rem;
      border: 1px solid #ced4da;
      border-radius: 3px;
      background-color: #fff;
      -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
      transition: border 0.15s ease-in-out, color 0.15s ease-in-out; }
    .abc-checkbox label::after {
      cursor: pointer;
      display: inline-block;
      position: absolute;
      width: 16px;
      height: 16px;
      left: 0;
      top: 2px;
      margin-left: -1.25rem;
      padding-left: 3px;
      padding-top: 1px;
      font-size: 11px;
      color: #495057; }
  .abc-checkbox input[type="checkbox"],
  .abc-checkbox input[type="radio"] {
    position: static;
    margin-left: 0;
    cursor: pointer;
    opacity: 0;
    z-index: 1; }
    .abc-checkbox input[type="checkbox"]:focus + label::before,
    .abc-checkbox input[type="radio"]:focus + label::before {
      outline: thin dotted;
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px; }
    .abc-checkbox input[type="checkbox"]:checked + label::after,
    .abc-checkbox input[type="radio"]:checked + label::after {
      font-family: "FontAwesome";
      content: "\F00C"; }
    .abc-checkbox input[type="checkbox"]:indeterminate + label::after,
    .abc-checkbox input[type="radio"]:indeterminate + label::after {
      display: block;
      content: "";
      width: 10px;
      height: 3px;
      background-color: #555555;
      border-radius: 2px;
      margin-left: -16.5px;
      margin-top: 7px; }
    .abc-checkbox input[type="checkbox"]:disabled + label,
    .abc-checkbox input[type="radio"]:disabled + label {
      opacity: 0.65; }
      .abc-checkbox input[type="checkbox"]:disabled + label::before,
      .abc-checkbox input[type="radio"]:disabled + label::before {
        background-color: #e9ecef;
        cursor: not-allowed; }
      .abc-checkbox input[type="checkbox"]:disabled + label::after,
      .abc-checkbox input[type="radio"]:disabled + label::after {
        cursor: not-allowed; }
  .abc-checkbox.abc-checkbox-circle label::before {
    border-radius: 50%; }
  .abc-checkbox.checkbox-inline {
    margin-top: 0; }

.abc-checkbox-primary input[type="checkbox"]:checked + label::before,
.abc-checkbox-primary input[type="radio"]:checked + label::before {
  background-color: #007bff;
  border-color: #007bff; }

.abc-checkbox-primary input[type="checkbox"]:checked + label::after,
.abc-checkbox-primary input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-danger input[type="checkbox"]:checked + label::before,
.abc-checkbox-danger input[type="radio"]:checked + label::before {
  background-color: #dc3545;
  border-color: #dc3545; }

.abc-checkbox-danger input[type="checkbox"]:checked + label::after,
.abc-checkbox-danger input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-info input[type="checkbox"]:checked + label::before,
.abc-checkbox-info input[type="radio"]:checked + label::before {
  background-color: #17a2b8;
  border-color: #17a2b8; }

.abc-checkbox-info input[type="checkbox"]:checked + label::after,
.abc-checkbox-info input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-warning input[type="checkbox"]:checked + label::before,
.abc-checkbox-warning input[type="radio"]:checked + label::before {
  background-color: #ffc107;
  border-color: #ffc107; }

.abc-checkbox-warning input[type="checkbox"]:checked + label::after,
.abc-checkbox-warning input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-success input[type="checkbox"]:checked + label::before,
.abc-checkbox-success input[type="radio"]:checked + label::before {
  background-color: #28a745;
  border-color: #28a745; }

.abc-checkbox-success input[type="checkbox"]:checked + label::after,
.abc-checkbox-success input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-primary input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-primary input[type="radio"]:indeterminate + label::before {
  background-color: #007bff;
  border-color: #007bff; }

.abc-checkbox-primary input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-primary input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-danger input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-danger input[type="radio"]:indeterminate + label::before {
  background-color: #dc3545;
  border-color: #dc3545; }

.abc-checkbox-danger input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-danger input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-info input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-info input[type="radio"]:indeterminate + label::before {
  background-color: #17a2b8;
  border-color: #17a2b8; }

.abc-checkbox-info input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-info input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-warning input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-warning input[type="radio"]:indeterminate + label::before {
  background-color: #ffc107;
  border-color: #ffc107; }

.abc-checkbox-warning input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-warning input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-success input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-success input[type="radio"]:indeterminate + label::before {
  background-color: #28a745;
  border-color: #28a745; }

.abc-checkbox-success input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-success input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-radio {
  cursor: default;
  padding-left: 4px; }
  .abc-radio label {
    cursor: pointer;
    display: inline;
    vertical-align: top;
    position: relative;
    padding-left: 5px; }
    .abc-radio label::before {
      cursor: pointer;
      content: "";
      display: inline-block;
      position: absolute;
      width: 17px;
      height: 17px;
      top: 2px;
      left: 0;
      margin-left: -20px;
      border: 1px solid #ced4da;
      border-radius: 50%;
      background-color: #fff;
      -webkit-transition: border 0.15s ease-in-out;
      transition: border 0.15s ease-in-out; }
    .abc-radio label::after {
      cursor: pointer;
      display: inline-block;
      position: absolute;
      content: " ";
      width: 11px;
      height: 11px;
      left: 3px;
      top: 5px;
      margin-left: -20px;
      border-radius: 50%;
      background-color: #495057;
      -webkit-transform: scale(0, 0);
              transform: scale(0, 0);
      -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33), -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33); }
  .abc-radio input[type="radio"] {
    position: static;
    margin-left: 0;
    cursor: pointer;
    opacity: 0;
    z-index: 1; }
    .abc-radio input[type="radio"]:focus + label::before {
      outline: thin dotted;
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px; }
    .abc-radio input[type="radio"]:checked + label::after {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1); }
    .abc-radio input[type="radio"]:disabled + label {
      opacity: 0.65; }
      .abc-radio input[type="radio"]:disabled + label::before {
        cursor: not-allowed; }
      .abc-radio input[type="radio"]:disabled + label::after {
        cursor: not-allowed; }
  .abc-radio.radio-inline {
    margin-top: 0; }

.abc-radio-primary input[type="radio"] + label::after {
  background-color: #007bff; }

.abc-radio-primary input[type="radio"]:checked + label::before {
  border-color: #007bff; }

.abc-radio-primary input[type="radio"]:checked + label::after {
  background-color: #007bff; }

.abc-radio-danger input[type="radio"] + label::after {
  background-color: #dc3545; }

.abc-radio-danger input[type="radio"]:checked + label::before {
  border-color: #dc3545; }

.abc-radio-danger input[type="radio"]:checked + label::after {
  background-color: #dc3545; }

.abc-radio-info input[type="radio"] + label::after {
  background-color: #17a2b8; }

.abc-radio-info input[type="radio"]:checked + label::before {
  border-color: #17a2b8; }

.abc-radio-info input[type="radio"]:checked + label::after {
  background-color: #17a2b8; }

.abc-radio-warning input[type="radio"] + label::after {
  background-color: #ffc107; }

.abc-radio-warning input[type="radio"]:checked + label::before {
  border-color: #ffc107; }

.abc-radio-warning input[type="radio"]:checked + label::after {
  background-color: #ffc107; }

.abc-radio-success input[type="radio"] + label::after {
  background-color: #28a745; }

.abc-radio-success input[type="radio"]:checked + label::before {
  border-color: #28a745; }

.abc-radio-success input[type="radio"]:checked + label::after {
  background-color: #28a745; }

label .was-validated .form-check-input:invalid .abc-checkbox:before, label
.was-validated .form-check-input:invalid .abc-radio:before, label .form-check-input.is-invalid .abc-checkbox:before, label
.form-check-input.is-invalid .abc-radio:before {
  border-color: #dc3545; }

@font-face {
  font-family: "Petasense Open Sans";
  src: url(/static/media/OpenSans-Regular_1.73d5e4b3.woff2); }

@font-face {
  font-family: "Petasense Open Sans";
  src: url(/static/media/OpenSans-Semibold_1.59d1fe1e.woff2);
  font-weight: 600; }

@font-face {
  font-family: 'Petasense Open Sans';
  src: url(/static/media/OpenSans-Bold_1.1b0edf91.woff2);
  font-weight: bold; }

.ps-hover-pointer:hover {
  cursor: pointer; }

.ps-hover-move {
  cursor: move; }

.hide {
  height: 0;
  width: 0;
  opacity: 0; }

.ps-logo-container {
  text-align: center;
  margin-top: 15vh;
  margin-bottom: 5vh; }
  .ps-logo-container img {
    width: 61%; }

.container.align-center {
  text-align: center; }

.container .ps-routes {
  width: 32%;
  margin: 0 auto; }

form.scrollable-form {
  overflow-y: scroll;
  max-height: 60vh; }
  form.scrollable-form::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(136, 134, 134, 0.925);
            box-shadow: inset 0 0 6px rgba(136, 134, 134, 0.925);
    background-color: #f5f5f5; }
  form.scrollable-form::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5; }
  form.scrollable-form::-webkit-scrollbar-thumb {
    background-color: #999b95; }

.ps-align-center {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }

.ps-align-center-column {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-direction: column;
      flex-direction: column; }

.list-error-message {
  color: #878983;
  padding-top: 20px;
  font-weight: bold; }
  .list-error-message.machine-list {
    border: 2px dashed #D8DCD3;
    padding: 20px;
    margin-top: 10px; }
  .list-error-message p {
    font-weight: 300; }

.top-padding-20 {
  padding-top: 20px; }

.small-row {
  width: 50%; }

.dropdown-title {
  max-width: 110px;
  white-space: normal !important; }

.help-block {
  color: #a94442;
  font-size: 12px;
  opacity: 0;
  margin: 0;
  max-height: 0;
  font-weight: bold;
  pointer-events: none;
  -webkit-transition: max-height 300ms ease, opacity 300ms ease;
  transition: max-height 300ms ease, opacity 300ms ease; }
  .help-block.onboarding-header {
    padding: 0px 0px 15px 0px; }
  .help-block.pop {
    opacity: 1;
    height: auto;
    margin-top: 5px;
    margin-bottom: 5px;
    max-height: 150px;
    -webkit-transition: max-height 300ms ease, opacity 300ms ease;
    transition: max-height 300ms ease, opacity 300ms ease; }

.ps-vertical-center {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

input.error {
  color: #a94442; }

.ps-help-block {
  color: #a94442;
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: bold;
  height: 0; }

.ps-horizontal-center {
  text-align: center; }

.ps-card {
  -webkit-transition: -webkit-box-shadow 0.2s;
  transition: -webkit-box-shadow 0.2s;
  transition: box-shadow 0.2s;
  transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0), 0 3px 6px rgba(0, 0, 0, 0);
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0), 0 3px 6px rgba(0, 0, 0, 0);
  background: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  display: inline-block;
  margin: 1vh auto;
  padding: 1.5vh;
  position: relative;
  text-align: center;
  width: 90%;
  height: 70px; }
  .ps-card:hover {
    cursor: pointer; }
  .ps-card:hover {
    -webkit-box-shadow: 0 4px 12px rgba(33, 33, 33, 0.3), 0 4px 12px rgba(0, 0, 0, 0.3);
            box-shadow: 0 4px 12px rgba(33, 33, 33, 0.3), 0 4px 12px rgba(0, 0, 0, 0.3); }
  .ps-card.active {
    border-color: #91b846;
    color: #91b846; }
    .ps-card.active:hover {
      -webkit-box-shadow: 0 5px 14px rgba(145, 184, 70, 0.8), 0 5px 14px rgba(145, 184, 70, 0.8);
              box-shadow: 0 5px 14px rgba(145, 184, 70, 0.8), 0 5px 14px rgba(145, 184, 70, 0.8);
      cursor: pointer; }
  .ps-card .title {
    text-align: left;
    margin: auto;
    font-size: 95%;
    font-weight: bold; }

.abc-checkbox {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .abc-checkbox label {
    font-family: "Petasense Open Sans";
    font-size: 16px;
    font-weight: normal;
    margin: 0px 10px 0px 19px; }
    .abc-checkbox label:after {
      color: #91b846;
      margin-left: -23px; }
    .abc-checkbox label:before {
      border-radius: 0px;
      margin-left: -23px;
      top: 2px; }

.full-screen-center {
  position: absolute;
  top: 50%;
  left: 50%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }

.fill-remaining-space {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-positive: 1;
      flex-grow: 1; }

.btn {
  height: 35px;
  font-size: 16px;
  margin: 3px 0;
  border-width: 0;
  width: 100%; }
  .btn.btn-primary {
    font-family: "Petasense Open Sans";
    background-color: #91b846;
    border-radius: 0px;
    color: white;
    margin-bottom: 10px; }
    .btn.btn-primary:hover, .btn.btn-primary:focus {
      background-color: #91b846; }
  .btn.btn-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    font-family: "Petasense Open Sans";
    background-color: "transparent";
    color: #91b846;
    -ms-flex-pack: center;
        justify-content: center; }

/**
------------------------------------------------------- Machine Severity CSS ------------------------------------------------------
 */
.ps-good-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-good-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-good-health:before {
    background: #91B846; }

.ps-slight-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-slight-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-slight-health:before {
    background: #4E89F4; }

.ps-moderate-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-moderate-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-moderate-health:before {
    background: #E4BE0C; }

.ps-warning-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-warning-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-warning-health:before {
    background: #F38F0A; }

.ps-critical-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-critical-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-critical-health:before {
    background: #E61111; }

.ps-unknown-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-unknown-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-unknown-health:before {
    background: #d3d3d3; }

.machine-condition-devider {
  height: 16px;
  width: 1px;
  background-color: #d8d8d6;
  margin: 0px 30px;
  visibility: hidden; }

.fixed-bar {
  position: fixed;
  background: #ffffff;
  width: 100%;
  z-index: 2;
  top: 0px; }

.ui.segment {
  border: none;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin: 0px;
  -webkit-box-shadow: none;
          box-shadow: none; }

.ui.grid > .row {
  padding-left: 1.1em;
  padding-top: 1rem;
  padding-bottom: 1rem; }

.custom-grid {
  border: 2px solid #f0f0f0;
  padding: 20px;
  width: 100%; }

.chart-heading {
  border: none;
  border-radius: 0px;
  outline: none;
  padding-top: 20px;
  color: #333;
  font-size: 16px;
  text-align: left;
  margin-bottom: 10px; }
  .chart-heading .beta {
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-size: 12px;
    position: relative;
    top: -10px; }

/**
 -- used http://chir.ag/projects/name-that-color/ to name color
*/
.abc-checkbox {
  cursor: default;
  padding-left: 4px; }
  .abc-checkbox label {
    cursor: pointer;
    display: inline;
    vertical-align: top;
    position: relative;
    padding-left: 5px; }
    .abc-checkbox label::before {
      cursor: pointer;
      content: "";
      display: inline-block;
      position: absolute;
      width: 17px;
      height: 17px;
      top: 2px;
      left: 0;
      margin-left: -1.25rem;
      border: 1px solid #ced4da;
      border-radius: 3px;
      background-color: #fff;
      -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
      transition: border 0.15s ease-in-out, color 0.15s ease-in-out; }
    .abc-checkbox label::after {
      cursor: pointer;
      display: inline-block;
      position: absolute;
      width: 16px;
      height: 16px;
      left: 0;
      top: 2px;
      margin-left: -1.25rem;
      padding-left: 3px;
      padding-top: 1px;
      font-size: 11px;
      color: #495057; }
  .abc-checkbox input[type="checkbox"],
  .abc-checkbox input[type="radio"] {
    position: static;
    margin-left: 0;
    cursor: pointer;
    opacity: 0;
    z-index: 1; }
    .abc-checkbox input[type="checkbox"]:focus + label::before,
    .abc-checkbox input[type="radio"]:focus + label::before {
      outline: thin dotted;
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px; }
    .abc-checkbox input[type="checkbox"]:checked + label::after,
    .abc-checkbox input[type="radio"]:checked + label::after {
      font-family: "FontAwesome";
      content: "\F00C"; }
    .abc-checkbox input[type="checkbox"]:indeterminate + label::after,
    .abc-checkbox input[type="radio"]:indeterminate + label::after {
      display: block;
      content: "";
      width: 10px;
      height: 3px;
      background-color: #555555;
      border-radius: 2px;
      margin-left: -16.5px;
      margin-top: 7px; }
    .abc-checkbox input[type="checkbox"]:disabled + label,
    .abc-checkbox input[type="radio"]:disabled + label {
      opacity: 0.65; }
      .abc-checkbox input[type="checkbox"]:disabled + label::before,
      .abc-checkbox input[type="radio"]:disabled + label::before {
        background-color: #e9ecef;
        cursor: not-allowed; }
      .abc-checkbox input[type="checkbox"]:disabled + label::after,
      .abc-checkbox input[type="radio"]:disabled + label::after {
        cursor: not-allowed; }
  .abc-checkbox.abc-checkbox-circle label::before {
    border-radius: 50%; }
  .abc-checkbox.checkbox-inline {
    margin-top: 0; }

.abc-checkbox-primary input[type="checkbox"]:checked + label::before,
.abc-checkbox-primary input[type="radio"]:checked + label::before {
  background-color: #007bff;
  border-color: #007bff; }

.abc-checkbox-primary input[type="checkbox"]:checked + label::after,
.abc-checkbox-primary input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-danger input[type="checkbox"]:checked + label::before,
.abc-checkbox-danger input[type="radio"]:checked + label::before {
  background-color: #dc3545;
  border-color: #dc3545; }

.abc-checkbox-danger input[type="checkbox"]:checked + label::after,
.abc-checkbox-danger input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-info input[type="checkbox"]:checked + label::before,
.abc-checkbox-info input[type="radio"]:checked + label::before {
  background-color: #17a2b8;
  border-color: #17a2b8; }

.abc-checkbox-info input[type="checkbox"]:checked + label::after,
.abc-checkbox-info input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-warning input[type="checkbox"]:checked + label::before,
.abc-checkbox-warning input[type="radio"]:checked + label::before {
  background-color: #ffc107;
  border-color: #ffc107; }

.abc-checkbox-warning input[type="checkbox"]:checked + label::after,
.abc-checkbox-warning input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-success input[type="checkbox"]:checked + label::before,
.abc-checkbox-success input[type="radio"]:checked + label::before {
  background-color: #28a745;
  border-color: #28a745; }

.abc-checkbox-success input[type="checkbox"]:checked + label::after,
.abc-checkbox-success input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-primary input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-primary input[type="radio"]:indeterminate + label::before {
  background-color: #007bff;
  border-color: #007bff; }

.abc-checkbox-primary input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-primary input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-danger input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-danger input[type="radio"]:indeterminate + label::before {
  background-color: #dc3545;
  border-color: #dc3545; }

.abc-checkbox-danger input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-danger input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-info input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-info input[type="radio"]:indeterminate + label::before {
  background-color: #17a2b8;
  border-color: #17a2b8; }

.abc-checkbox-info input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-info input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-warning input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-warning input[type="radio"]:indeterminate + label::before {
  background-color: #ffc107;
  border-color: #ffc107; }

.abc-checkbox-warning input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-warning input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-success input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-success input[type="radio"]:indeterminate + label::before {
  background-color: #28a745;
  border-color: #28a745; }

.abc-checkbox-success input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-success input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-radio {
  cursor: default;
  padding-left: 4px; }
  .abc-radio label {
    cursor: pointer;
    display: inline;
    vertical-align: top;
    position: relative;
    padding-left: 5px; }
    .abc-radio label::before {
      cursor: pointer;
      content: "";
      display: inline-block;
      position: absolute;
      width: 17px;
      height: 17px;
      top: 2px;
      left: 0;
      margin-left: -20px;
      border: 1px solid #ced4da;
      border-radius: 50%;
      background-color: #fff;
      -webkit-transition: border 0.15s ease-in-out;
      transition: border 0.15s ease-in-out; }
    .abc-radio label::after {
      cursor: pointer;
      display: inline-block;
      position: absolute;
      content: " ";
      width: 11px;
      height: 11px;
      left: 3px;
      top: 5px;
      margin-left: -20px;
      border-radius: 50%;
      background-color: #495057;
      -webkit-transform: scale(0, 0);
              transform: scale(0, 0);
      -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33), -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33); }
  .abc-radio input[type="radio"] {
    position: static;
    margin-left: 0;
    cursor: pointer;
    opacity: 0;
    z-index: 1; }
    .abc-radio input[type="radio"]:focus + label::before {
      outline: thin dotted;
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px; }
    .abc-radio input[type="radio"]:checked + label::after {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1); }
    .abc-radio input[type="radio"]:disabled + label {
      opacity: 0.65; }
      .abc-radio input[type="radio"]:disabled + label::before {
        cursor: not-allowed; }
      .abc-radio input[type="radio"]:disabled + label::after {
        cursor: not-allowed; }
  .abc-radio.radio-inline {
    margin-top: 0; }

.abc-radio-primary input[type="radio"] + label::after {
  background-color: #007bff; }

.abc-radio-primary input[type="radio"]:checked + label::before {
  border-color: #007bff; }

.abc-radio-primary input[type="radio"]:checked + label::after {
  background-color: #007bff; }

.abc-radio-danger input[type="radio"] + label::after {
  background-color: #dc3545; }

.abc-radio-danger input[type="radio"]:checked + label::before {
  border-color: #dc3545; }

.abc-radio-danger input[type="radio"]:checked + label::after {
  background-color: #dc3545; }

.abc-radio-info input[type="radio"] + label::after {
  background-color: #17a2b8; }

.abc-radio-info input[type="radio"]:checked + label::before {
  border-color: #17a2b8; }

.abc-radio-info input[type="radio"]:checked + label::after {
  background-color: #17a2b8; }

.abc-radio-warning input[type="radio"] + label::after {
  background-color: #ffc107; }

.abc-radio-warning input[type="radio"]:checked + label::before {
  border-color: #ffc107; }

.abc-radio-warning input[type="radio"]:checked + label::after {
  background-color: #ffc107; }

.abc-radio-success input[type="radio"] + label::after {
  background-color: #28a745; }

.abc-radio-success input[type="radio"]:checked + label::before {
  border-color: #28a745; }

.abc-radio-success input[type="radio"]:checked + label::after {
  background-color: #28a745; }

label .was-validated .form-check-input:invalid .abc-checkbox:before, label
.was-validated .form-check-input:invalid .abc-radio:before, label .form-check-input.is-invalid .abc-checkbox:before, label
.form-check-input.is-invalid .abc-radio:before {
  border-color: #dc3545; }

@font-face {
  font-family: "Petasense Open Sans";
  src: url(/static/media/OpenSans-Regular_1.73d5e4b3.woff2); }

@font-face {
  font-family: "Petasense Open Sans";
  src: url(/static/media/OpenSans-Semibold_1.59d1fe1e.woff2);
  font-weight: 600; }

@font-face {
  font-family: 'Petasense Open Sans';
  src: url(/static/media/OpenSans-Bold_1.1b0edf91.woff2);
  font-weight: bold; }

.ps-hover-pointer:hover {
  cursor: pointer; }

.ps-hover-move {
  cursor: move; }

.hide {
  height: 0;
  width: 0;
  opacity: 0; }

.ps-logo-container {
  text-align: center;
  margin-top: 15vh;
  margin-bottom: 5vh; }
  .ps-logo-container img {
    width: 61%; }

.container.align-center {
  text-align: center; }

.container .ps-routes {
  width: 32%;
  margin: 0 auto; }

form.scrollable-form {
  overflow-y: scroll;
  max-height: 60vh; }
  form.scrollable-form::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(136, 134, 134, 0.925);
            box-shadow: inset 0 0 6px rgba(136, 134, 134, 0.925);
    background-color: #f5f5f5; }
  form.scrollable-form::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5; }
  form.scrollable-form::-webkit-scrollbar-thumb {
    background-color: #999b95; }

.ps-align-center {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }

.ps-align-center-column {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-direction: column;
      flex-direction: column; }

.list-error-message {
  color: #878983;
  padding-top: 20px;
  font-weight: bold; }
  .list-error-message.machine-list {
    border: 2px dashed #D8DCD3;
    padding: 20px;
    margin-top: 10px; }
  .list-error-message p {
    font-weight: 300; }

.top-padding-20 {
  padding-top: 20px; }

.small-row {
  width: 50%; }

.dropdown-title {
  max-width: 110px;
  white-space: normal !important; }

.help-block {
  color: #a94442;
  font-size: 12px;
  opacity: 0;
  margin: 0;
  max-height: 0;
  font-weight: bold;
  pointer-events: none;
  -webkit-transition: max-height 300ms ease, opacity 300ms ease;
  transition: max-height 300ms ease, opacity 300ms ease; }
  .help-block.onboarding-header {
    padding: 0px 0px 15px 0px; }
  .help-block.pop {
    opacity: 1;
    height: auto;
    margin-top: 5px;
    margin-bottom: 5px;
    max-height: 150px;
    -webkit-transition: max-height 300ms ease, opacity 300ms ease;
    transition: max-height 300ms ease, opacity 300ms ease; }

.ps-vertical-center {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

input.error {
  color: #a94442; }

.ps-help-block {
  color: #a94442;
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: bold;
  height: 0; }

.ps-horizontal-center {
  text-align: center; }

.ps-card {
  -webkit-transition: -webkit-box-shadow 0.2s;
  transition: -webkit-box-shadow 0.2s;
  transition: box-shadow 0.2s;
  transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0), 0 3px 6px rgba(0, 0, 0, 0);
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0), 0 3px 6px rgba(0, 0, 0, 0);
  background: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  display: inline-block;
  margin: 1vh auto;
  padding: 1.5vh;
  position: relative;
  text-align: center;
  width: 90%;
  height: 70px; }
  .ps-card:hover {
    cursor: pointer; }
  .ps-card:hover {
    -webkit-box-shadow: 0 4px 12px rgba(33, 33, 33, 0.3), 0 4px 12px rgba(0, 0, 0, 0.3);
            box-shadow: 0 4px 12px rgba(33, 33, 33, 0.3), 0 4px 12px rgba(0, 0, 0, 0.3); }
  .ps-card.active {
    border-color: #91b846;
    color: #91b846; }
    .ps-card.active:hover {
      -webkit-box-shadow: 0 5px 14px rgba(145, 184, 70, 0.8), 0 5px 14px rgba(145, 184, 70, 0.8);
              box-shadow: 0 5px 14px rgba(145, 184, 70, 0.8), 0 5px 14px rgba(145, 184, 70, 0.8);
      cursor: pointer; }
  .ps-card .title {
    text-align: left;
    margin: auto;
    font-size: 95%;
    font-weight: bold; }

.abc-checkbox {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .abc-checkbox label {
    font-family: "Petasense Open Sans";
    font-size: 16px;
    font-weight: normal;
    margin: 0px 10px 0px 19px; }
    .abc-checkbox label:after {
      color: #91b846;
      margin-left: -23px; }
    .abc-checkbox label:before {
      border-radius: 0px;
      margin-left: -23px;
      top: 2px; }

.full-screen-center {
  position: absolute;
  top: 50%;
  left: 50%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }

.fill-remaining-space {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-positive: 1;
      flex-grow: 1; }

.btn {
  height: 35px;
  font-size: 16px;
  margin: 3px 0;
  border-width: 0;
  width: 100%; }
  .btn.btn-primary {
    font-family: "Petasense Open Sans";
    background-color: #91b846;
    border-radius: 0px;
    color: white;
    margin-bottom: 10px; }
    .btn.btn-primary:hover, .btn.btn-primary:focus {
      background-color: #91b846; }
  .btn.btn-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    font-family: "Petasense Open Sans";
    background-color: "transparent";
    color: #91b846;
    -ms-flex-pack: center;
        justify-content: center; }

/**
------------------------------------------------------- Machine Severity CSS ------------------------------------------------------
 */
.ps-good-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-good-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-good-health:before {
    background: #91B846; }

.ps-slight-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-slight-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-slight-health:before {
    background: #4E89F4; }

.ps-moderate-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-moderate-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-moderate-health:before {
    background: #E4BE0C; }

.ps-warning-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-warning-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-warning-health:before {
    background: #F38F0A; }

.ps-critical-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-critical-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-critical-health:before {
    background: #E61111; }

.ps-unknown-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-unknown-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-unknown-health:before {
    background: #d3d3d3; }

.machine-condition-devider {
  height: 16px;
  width: 1px;
  background-color: #d8d8d6;
  margin: 0px 30px;
  visibility: hidden; }

.fixed-bar {
  position: fixed;
  background: #ffffff;
  width: 100%;
  z-index: 2;
  top: 0px; }

.ui.segment {
  border: none;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin: 0px;
  -webkit-box-shadow: none;
          box-shadow: none; }

.ui.grid > .row {
  padding-left: 1.1em;
  padding-top: 1rem;
  padding-bottom: 1rem; }

.custom-grid {
  border: 2px solid #f0f0f0;
  padding: 20px;
  width: 100%; }

.hierarchy-drag-helper {
  z-index: 999;
  -webkit-box-shadow: rgba(0, 0, 0, 0.2) 0px 16px 32px 0px;
          box-shadow: rgba(0, 0, 0, 0.2) 0px 16px 32px 0px;
  overflow: hidden;
  border-top: 1px solid #91b846; }

.home-page {
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh; }
  .home-page .side-menu-container {
    height: 100vh;
    position: fixed;
    z-index: 4; }
    .home-page .side-menu-container .ui.vertical.icon.menu {
      background: #eff0ee;
      display: -ms-flexbox;
      display: flex;
      font-family: "Petasense Open Sans";
      width: 62px;
      height: 100%;
      border-radius: 0px;
      border: 0px;
      border-right: 2px solid #d8dcd3; }
      .home-page .side-menu-container .ui.vertical.icon.menu .tool-tip:before {
        display: none; }
      .home-page .side-menu-container .ui.vertical.icon.menu .tool-tip:after {
        height: 26px;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: center;
            justify-content: center;
        -ms-flex-align: center;
            align-items: center;
        z-index: 7;
        -webkit-transform: none !important;
                transform: none !important;
        -webkit-transition-property: none !important;
        transition-property: none !important;
        -webkit-animation: none !important;
                animation: none !important;
        -webkit-transform-origin: unset;
        transform-origin: unset;
        top: -25% !important; }
      .home-page .side-menu-container .ui.vertical.icon.menu .bottom-menu {
        -ms-flex-positive: 1;
            flex-grow: 1;
        -ms-flex-pack: end;
            justify-content: flex-end;
        -ms-flex-direction: column;
            flex-direction: column;
        display: -ms-flexbox;
        display: flex; }
        .home-page .side-menu-container .ui.vertical.icon.menu .bottom-menu .item:first-child:before {
          display: block !important; }
      .home-page .side-menu-container .ui.vertical.icon.menu .item {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: center;
            justify-content: center;
        -ms-flex-align: center;
            align-items: center;
        color: #999b95;
        font-size: 16px;
        font-weight: 600;
        height: 40px;
        margin: 8px 0px; }
        .home-page .side-menu-container .ui.vertical.icon.menu .item svg > g > g {
          fill: #999b95; }
        .home-page .side-menu-container .ui.vertical.icon.menu .item:before {
          height: 0px; }
        .home-page .side-menu-container .ui.vertical.icon.menu .item:hover {
          background: transparent; }
        .home-page .side-menu-container .ui.vertical.icon.menu .item.active {
          background: transparent; }
          .home-page .side-menu-container .ui.vertical.icon.menu .item.active:before {
            background: transparent;
            height: 100%;
            width: 4px;
            display: block; }
          .home-page .side-menu-container .ui.vertical.icon.menu .item.active svg > g > g {
            fill: #91b846; }
          .home-page .side-menu-container .ui.vertical.icon.menu .item.active span > div {
            color: #91b846; }
      .home-page .side-menu-container .ui.vertical.icon.menu .menu-item-logo {
        height: 65px;
        margin: 0px; }
    .home-page .side-menu-container .ui.secondary.menu {
      top: 0; }
      .home-page .side-menu-container .ui.secondary.menu .active.item {
        background: transparent;
        color: #3c3d3a;
        font-weight: 600; }
      .home-page .side-menu-container .ui.secondary.menu a.item:hover {
        background: transparent; }
  .home-page #SitesDropdown.ui.dropdown > .dropdown.icon {
    display: none; }
  .home-page #SitesDropdown.ui.dropdown .menu.transition.visible::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(136, 134, 134, 0.925);
            box-shadow: inset 0 0 6px rgba(136, 134, 134, 0.925);
    background-color: #f5f5f5; }
  .home-page #SitesDropdown.ui.dropdown .menu.transition.visible::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5; }
  .home-page #SitesDropdown.ui.dropdown .menu.transition.visible::-webkit-scrollbar-thumb {
    background-color: #999b95; }
  .home-page #SitesDropdown .menu > .active.item {
    background-color: transparent !important;
    color: #86c338 !important; }
  .home-page .line-chart {
    width: 900px; }
  .home-page .menu-container {
    top: 0px;
    z-index: 3; }

.semantic-ui-popup.popup.visible:before {
  height: 0;
  width: 0; }

@media only screen and (min-width: 1280px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .Resizer {
    background: rgba(0, 0, 0, 0.5);
    opacity: 0.2;
    z-index: 1;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box; }
  .Resizer:hover {
    -webkit-transition: all 2s ease;
    transition: all 2s ease; }
  .Resizer.horizontal {
    height: 11px;
    margin: -5px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%; }
  .Resizer.horizontal:hover {
    border-top: 5px solid rgba(0, 0, 0, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5); }
  .Resizer.vertical {
    width: 11px;
    margin: 0 -5px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize; }
  .Resizer.vertical:hover {
    border-left: 5px solid rgba(0, 0, 0, 0.5);
    border-right: 5px solid rgba(0, 0, 0, 0.5); }
  .Resizer.disabled {
    cursor: not-allowed; }
  .Resizer.disabled:hover {
    border-color: transparent; } }

/**
 -- used http://chir.ag/projects/name-that-color/ to name color
*/
.abc-checkbox {
  cursor: default;
  padding-left: 4px; }
  .abc-checkbox label {
    cursor: pointer;
    display: inline;
    vertical-align: top;
    position: relative;
    padding-left: 5px; }
    .abc-checkbox label::before {
      cursor: pointer;
      content: "";
      display: inline-block;
      position: absolute;
      width: 17px;
      height: 17px;
      top: 2px;
      left: 0;
      margin-left: -1.25rem;
      border: 1px solid #ced4da;
      border-radius: 3px;
      background-color: #fff;
      -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
      transition: border 0.15s ease-in-out, color 0.15s ease-in-out; }
    .abc-checkbox label::after {
      cursor: pointer;
      display: inline-block;
      position: absolute;
      width: 16px;
      height: 16px;
      left: 0;
      top: 2px;
      margin-left: -1.25rem;
      padding-left: 3px;
      padding-top: 1px;
      font-size: 11px;
      color: #495057; }
  .abc-checkbox input[type="checkbox"],
  .abc-checkbox input[type="radio"] {
    position: static;
    margin-left: 0;
    cursor: pointer;
    opacity: 0;
    z-index: 1; }
    .abc-checkbox input[type="checkbox"]:focus + label::before,
    .abc-checkbox input[type="radio"]:focus + label::before {
      outline: thin dotted;
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px; }
    .abc-checkbox input[type="checkbox"]:checked + label::after,
    .abc-checkbox input[type="radio"]:checked + label::after {
      font-family: "FontAwesome";
      content: "\F00C"; }
    .abc-checkbox input[type="checkbox"]:indeterminate + label::after,
    .abc-checkbox input[type="radio"]:indeterminate + label::after {
      display: block;
      content: "";
      width: 10px;
      height: 3px;
      background-color: #555555;
      border-radius: 2px;
      margin-left: -16.5px;
      margin-top: 7px; }
    .abc-checkbox input[type="checkbox"]:disabled + label,
    .abc-checkbox input[type="radio"]:disabled + label {
      opacity: 0.65; }
      .abc-checkbox input[type="checkbox"]:disabled + label::before,
      .abc-checkbox input[type="radio"]:disabled + label::before {
        background-color: #e9ecef;
        cursor: not-allowed; }
      .abc-checkbox input[type="checkbox"]:disabled + label::after,
      .abc-checkbox input[type="radio"]:disabled + label::after {
        cursor: not-allowed; }
  .abc-checkbox.abc-checkbox-circle label::before {
    border-radius: 50%; }
  .abc-checkbox.checkbox-inline {
    margin-top: 0; }

.abc-checkbox-primary input[type="checkbox"]:checked + label::before,
.abc-checkbox-primary input[type="radio"]:checked + label::before {
  background-color: #007bff;
  border-color: #007bff; }

.abc-checkbox-primary input[type="checkbox"]:checked + label::after,
.abc-checkbox-primary input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-danger input[type="checkbox"]:checked + label::before,
.abc-checkbox-danger input[type="radio"]:checked + label::before {
  background-color: #dc3545;
  border-color: #dc3545; }

.abc-checkbox-danger input[type="checkbox"]:checked + label::after,
.abc-checkbox-danger input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-info input[type="checkbox"]:checked + label::before,
.abc-checkbox-info input[type="radio"]:checked + label::before {
  background-color: #17a2b8;
  border-color: #17a2b8; }

.abc-checkbox-info input[type="checkbox"]:checked + label::after,
.abc-checkbox-info input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-warning input[type="checkbox"]:checked + label::before,
.abc-checkbox-warning input[type="radio"]:checked + label::before {
  background-color: #ffc107;
  border-color: #ffc107; }

.abc-checkbox-warning input[type="checkbox"]:checked + label::after,
.abc-checkbox-warning input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-success input[type="checkbox"]:checked + label::before,
.abc-checkbox-success input[type="radio"]:checked + label::before {
  background-color: #28a745;
  border-color: #28a745; }

.abc-checkbox-success input[type="checkbox"]:checked + label::after,
.abc-checkbox-success input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-primary input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-primary input[type="radio"]:indeterminate + label::before {
  background-color: #007bff;
  border-color: #007bff; }

.abc-checkbox-primary input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-primary input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-danger input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-danger input[type="radio"]:indeterminate + label::before {
  background-color: #dc3545;
  border-color: #dc3545; }

.abc-checkbox-danger input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-danger input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-info input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-info input[type="radio"]:indeterminate + label::before {
  background-color: #17a2b8;
  border-color: #17a2b8; }

.abc-checkbox-info input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-info input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-warning input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-warning input[type="radio"]:indeterminate + label::before {
  background-color: #ffc107;
  border-color: #ffc107; }

.abc-checkbox-warning input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-warning input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-success input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-success input[type="radio"]:indeterminate + label::before {
  background-color: #28a745;
  border-color: #28a745; }

.abc-checkbox-success input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-success input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-radio {
  cursor: default;
  padding-left: 4px; }
  .abc-radio label {
    cursor: pointer;
    display: inline;
    vertical-align: top;
    position: relative;
    padding-left: 5px; }
    .abc-radio label::before {
      cursor: pointer;
      content: "";
      display: inline-block;
      position: absolute;
      width: 17px;
      height: 17px;
      top: 2px;
      left: 0;
      margin-left: -20px;
      border: 1px solid #ced4da;
      border-radius: 50%;
      background-color: #fff;
      -webkit-transition: border 0.15s ease-in-out;
      transition: border 0.15s ease-in-out; }
    .abc-radio label::after {
      cursor: pointer;
      display: inline-block;
      position: absolute;
      content: " ";
      width: 11px;
      height: 11px;
      left: 3px;
      top: 5px;
      margin-left: -20px;
      border-radius: 50%;
      background-color: #495057;
      -webkit-transform: scale(0, 0);
              transform: scale(0, 0);
      -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33), -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33); }
  .abc-radio input[type="radio"] {
    position: static;
    margin-left: 0;
    cursor: pointer;
    opacity: 0;
    z-index: 1; }
    .abc-radio input[type="radio"]:focus + label::before {
      outline: thin dotted;
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px; }
    .abc-radio input[type="radio"]:checked + label::after {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1); }
    .abc-radio input[type="radio"]:disabled + label {
      opacity: 0.65; }
      .abc-radio input[type="radio"]:disabled + label::before {
        cursor: not-allowed; }
      .abc-radio input[type="radio"]:disabled + label::after {
        cursor: not-allowed; }
  .abc-radio.radio-inline {
    margin-top: 0; }

.abc-radio-primary input[type="radio"] + label::after {
  background-color: #007bff; }

.abc-radio-primary input[type="radio"]:checked + label::before {
  border-color: #007bff; }

.abc-radio-primary input[type="radio"]:checked + label::after {
  background-color: #007bff; }

.abc-radio-danger input[type="radio"] + label::after {
  background-color: #dc3545; }

.abc-radio-danger input[type="radio"]:checked + label::before {
  border-color: #dc3545; }

.abc-radio-danger input[type="radio"]:checked + label::after {
  background-color: #dc3545; }

.abc-radio-info input[type="radio"] + label::after {
  background-color: #17a2b8; }

.abc-radio-info input[type="radio"]:checked + label::before {
  border-color: #17a2b8; }

.abc-radio-info input[type="radio"]:checked + label::after {
  background-color: #17a2b8; }

.abc-radio-warning input[type="radio"] + label::after {
  background-color: #ffc107; }

.abc-radio-warning input[type="radio"]:checked + label::before {
  border-color: #ffc107; }

.abc-radio-warning input[type="radio"]:checked + label::after {
  background-color: #ffc107; }

.abc-radio-success input[type="radio"] + label::after {
  background-color: #28a745; }

.abc-radio-success input[type="radio"]:checked + label::before {
  border-color: #28a745; }

.abc-radio-success input[type="radio"]:checked + label::after {
  background-color: #28a745; }

label .was-validated .form-check-input:invalid .abc-checkbox:before, label
.was-validated .form-check-input:invalid .abc-radio:before, label .form-check-input.is-invalid .abc-checkbox:before, label
.form-check-input.is-invalid .abc-radio:before {
  border-color: #dc3545; }

@font-face {
  font-family: "Petasense Open Sans";
  src: url(/static/media/OpenSans-Regular_1.73d5e4b3.woff2); }

@font-face {
  font-family: "Petasense Open Sans";
  src: url(/static/media/OpenSans-Semibold_1.59d1fe1e.woff2);
  font-weight: 600; }

@font-face {
  font-family: 'Petasense Open Sans';
  src: url(/static/media/OpenSans-Bold_1.1b0edf91.woff2);
  font-weight: bold; }

.ps-hover-pointer:hover {
  cursor: pointer; }

.ps-hover-move {
  cursor: move; }

.hide {
  height: 0;
  width: 0;
  opacity: 0; }

.ps-logo-container {
  text-align: center;
  margin-top: 15vh;
  margin-bottom: 5vh; }
  .ps-logo-container img {
    width: 61%; }

.container.align-center {
  text-align: center; }

.container .ps-routes {
  width: 32%;
  margin: 0 auto; }

form.scrollable-form {
  overflow-y: scroll;
  max-height: 60vh; }
  form.scrollable-form::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(136, 134, 134, 0.925);
            box-shadow: inset 0 0 6px rgba(136, 134, 134, 0.925);
    background-color: #f5f5f5; }
  form.scrollable-form::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5; }
  form.scrollable-form::-webkit-scrollbar-thumb {
    background-color: #999b95; }

.ps-align-center {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }

.ps-align-center-column {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-direction: column;
      flex-direction: column; }

.list-error-message {
  color: #878983;
  padding-top: 20px;
  font-weight: bold; }
  .list-error-message.machine-list {
    border: 2px dashed #D8DCD3;
    padding: 20px;
    margin-top: 10px; }
  .list-error-message p {
    font-weight: 300; }

.top-padding-20 {
  padding-top: 20px; }

.small-row {
  width: 50%; }

.dropdown-title {
  max-width: 110px;
  white-space: normal !important; }

.help-block {
  color: #a94442;
  font-size: 12px;
  opacity: 0;
  margin: 0;
  max-height: 0;
  font-weight: bold;
  pointer-events: none;
  -webkit-transition: max-height 300ms ease, opacity 300ms ease;
  transition: max-height 300ms ease, opacity 300ms ease; }
  .help-block.onboarding-header {
    padding: 0px 0px 15px 0px; }
  .help-block.pop {
    opacity: 1;
    height: auto;
    margin-top: 5px;
    margin-bottom: 5px;
    max-height: 150px;
    -webkit-transition: max-height 300ms ease, opacity 300ms ease;
    transition: max-height 300ms ease, opacity 300ms ease; }

.ps-vertical-center {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

input.error {
  color: #a94442; }

.ps-help-block {
  color: #a94442;
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: bold;
  height: 0; }

.ps-horizontal-center {
  text-align: center; }

.ps-card {
  -webkit-transition: -webkit-box-shadow 0.2s;
  transition: -webkit-box-shadow 0.2s;
  transition: box-shadow 0.2s;
  transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0), 0 3px 6px rgba(0, 0, 0, 0);
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0), 0 3px 6px rgba(0, 0, 0, 0);
  background: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  display: inline-block;
  margin: 1vh auto;
  padding: 1.5vh;
  position: relative;
  text-align: center;
  width: 90%;
  height: 70px; }
  .ps-card:hover {
    cursor: pointer; }
  .ps-card:hover {
    -webkit-box-shadow: 0 4px 12px rgba(33, 33, 33, 0.3), 0 4px 12px rgba(0, 0, 0, 0.3);
            box-shadow: 0 4px 12px rgba(33, 33, 33, 0.3), 0 4px 12px rgba(0, 0, 0, 0.3); }
  .ps-card.active {
    border-color: #91b846;
    color: #91b846; }
    .ps-card.active:hover {
      -webkit-box-shadow: 0 5px 14px rgba(145, 184, 70, 0.8), 0 5px 14px rgba(145, 184, 70, 0.8);
              box-shadow: 0 5px 14px rgba(145, 184, 70, 0.8), 0 5px 14px rgba(145, 184, 70, 0.8);
      cursor: pointer; }
  .ps-card .title {
    text-align: left;
    margin: auto;
    font-size: 95%;
    font-weight: bold; }

.abc-checkbox {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .abc-checkbox label {
    font-family: "Petasense Open Sans";
    font-size: 16px;
    font-weight: normal;
    margin: 0px 10px 0px 19px; }
    .abc-checkbox label:after {
      color: #91b846;
      margin-left: -23px; }
    .abc-checkbox label:before {
      border-radius: 0px;
      margin-left: -23px;
      top: 2px; }

.full-screen-center {
  position: absolute;
  top: 50%;
  left: 50%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }

.fill-remaining-space {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-positive: 1;
      flex-grow: 1; }

.btn {
  height: 35px;
  font-size: 16px;
  margin: 3px 0;
  border-width: 0;
  width: 100%; }
  .btn.btn-primary {
    font-family: "Petasense Open Sans";
    background-color: #91b846;
    border-radius: 0px;
    color: white;
    margin-bottom: 10px; }
    .btn.btn-primary:hover, .btn.btn-primary:focus {
      background-color: #91b846; }
  .btn.btn-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    font-family: "Petasense Open Sans";
    background-color: "transparent";
    color: #91b846;
    -ms-flex-pack: center;
        justify-content: center; }

/**
------------------------------------------------------- Machine Severity CSS ------------------------------------------------------
 */
.ps-good-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-good-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-good-health:before {
    background: #91B846; }

.ps-slight-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-slight-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-slight-health:before {
    background: #4E89F4; }

.ps-moderate-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-moderate-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-moderate-health:before {
    background: #E4BE0C; }

.ps-warning-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-warning-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-warning-health:before {
    background: #F38F0A; }

.ps-critical-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-critical-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-critical-health:before {
    background: #E61111; }

.ps-unknown-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-unknown-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-unknown-health:before {
    background: #d3d3d3; }

.machine-condition-devider {
  height: 16px;
  width: 1px;
  background-color: #d8d8d6;
  margin: 0px 30px;
  visibility: hidden; }

.fixed-bar {
  position: fixed;
  background: #ffffff;
  width: 100%;
  z-index: 2;
  top: 0px; }

.ui.segment {
  border: none;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin: 0px;
  -webkit-box-shadow: none;
          box-shadow: none; }

.ui.grid > .row {
  padding-left: 1.1em;
  padding-top: 1rem;
  padding-bottom: 1rem; }

.custom-grid {
  border: 2px solid #f0f0f0;
  padding: 20px;
  width: 100%; }

.machines {
  margin-top: 16px; }
  .machines hr {
    margin: 15px 0px 10px;
    color: #D8DCD3;
    margin-left: -18px; }
    .machines hr.thick {
      height: 2px;
      background-color: #D8DCD3;
      margin-top: 10px;
      margin-bottom: 0px;
      border: none; }
  .machines .fixed-bar {
    padding: 45px 0px 0px 80px;
    right: 0px; }
  .machines .machine-item {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    color: #3C3D3A;
    -webkit-transition: -webkit-box-shadow .2s;
    transition: -webkit-box-shadow .2s;
    transition: box-shadow .2s;
    transition: box-shadow .2s, -webkit-box-shadow .2s;
    -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0), 0 3px 6px rgba(0, 0, 0, 0);
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0), 0 3px 6px rgba(0, 0, 0, 0);
    background: #ffffff;
    margin: 0px 0px;
    padding: 15px 0px;
    border-radius: 4px;
    font-size: 16px;
    padding-left: 18px;
    padding-right: 22px; }
    .machines .machine-item:not(:last-child) {
      border-bottom: 1px solid #E5E8E1; }
    .machines .machine-item .col-xs-12 {
      padding-left: 0; }
    .machines .machine-item a {
      color: #3C3D3A; }
    .machines .machine-item .machine-info-container {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
          align-items: center; }
      .machines .machine-item .machine-info-container .machine-info {
        display: none;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAwCAYAAAFSyqunAAAAAXNSR0IArs4c6QAAAyFJREFUSA29VU9IFFEYf98bZS3TSiiQDh0kwepQpGWpxJK77rYrKGEHCQTpUCGBXbt6KIQOFWR5CcqgLAh3xl13XAiiQyaiiXSQ7h2CymM27/V7s846MzuTkNSA7vf9vj/vfd/vfe8xhi8/q1/iubxx2RLyiQLsj7JmZo1JVuMATCELQI4zCHMcUkvRls1n5KZEubz+XjJ5shToCCSl5DlTtxTAiUg4lgpbIPrMiO5SzjT6pRQTNogYyub1H0qB4KyEBLZ5y3+lGCL2uhQjJevBDpiu4mt27q315pmfn6/ERu39lRIol1ICpah6sNmimwKKH01qre3Na1AiDrTxe4QnY921KHzWbaigqj1u3SOT0rCONmMai+hqIzY9lIh1j4MN/QMszR53KJwTu+8H/05XBWoo8JcnnGhaa21r+Q7QX+Ahnoynd6Mzk+6IfXX11XYdM6Z+FZ0fxWlZhGO7clKn6g3O0ll3RDLeTZ6+O0b0bsrDgWNAJw4HGojzYV5VyetLnhDQxFddnSmbfTe+DdkuMDtr3EDTboOyjyDphMqn5uEdmDvjTv6nAo3AOhDdGGwgus6rq7T97vxY9mmiM5XzYtvSHAZvCilHwMMnNPCoGmLF4NzmbVBcJLxAxrKBdUiihkCDJrVrfNcOqnNvHwuPx+PJgr2rQiFz4KdgE/5j5ArIIMFAR0f6G+gwrkgmHriMoSLnWi9nJEZDPXwGKaxR3Iy04sPDVaIV3hVLnWbECuFeRQuO7bNELN1jF60gdQHlC8agFGwQQ9WEK8kiRsugfywRS73YKuE/tqvtqb+gZTaIMw6uW/I5xuNUsBOZkcrq/mg0+hU3bWYIt8e9IEc/xjnrUzzc8hvCdAFfdfqWwhz8OPa/xBPxdBtO4pZjBk4eg7gLnk7YzxwTgwCbcHItDMAy53ys69z5jH+1/6yvrk5H1BsYtOwGcdMN65Z4CeKOBToRvY1U0MVoNPWF8HQNCynuBDmWY7yfw3mk3BCCkBzBiLKFEHM5LNmCeoc7QNxUudWHED3CS9XnIS5fyPQKe+KoCe6YOLlMxB/iQjSd8N+WKC3YfzhTrQAAAABJRU5ErkJggg==);
        margin-left: auto;
        color: #878983;
        background-repeat: no-repeat;
        background-size: cover;
        width: 4px;
        height: 16px; }
  .machines .machine-list-scroller {
    padding-top: 145px; }
    .machines .machine-list-scroller .loading-more {
      height: 50px; }
    @media screen and (max-width: 768px) {
      .machines .machine-list-scroller {
        padding-top: 235px; } }

/**
 -- used http://chir.ag/projects/name-that-color/ to name color
*/
.abc-checkbox {
  cursor: default;
  padding-left: 4px; }
  .abc-checkbox label {
    cursor: pointer;
    display: inline;
    vertical-align: top;
    position: relative;
    padding-left: 5px; }
    .abc-checkbox label::before {
      cursor: pointer;
      content: "";
      display: inline-block;
      position: absolute;
      width: 17px;
      height: 17px;
      top: 2px;
      left: 0;
      margin-left: -1.25rem;
      border: 1px solid #ced4da;
      border-radius: 3px;
      background-color: #fff;
      -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
      transition: border 0.15s ease-in-out, color 0.15s ease-in-out; }
    .abc-checkbox label::after {
      cursor: pointer;
      display: inline-block;
      position: absolute;
      width: 16px;
      height: 16px;
      left: 0;
      top: 2px;
      margin-left: -1.25rem;
      padding-left: 3px;
      padding-top: 1px;
      font-size: 11px;
      color: #495057; }
  .abc-checkbox input[type="checkbox"],
  .abc-checkbox input[type="radio"] {
    position: static;
    margin-left: 0;
    cursor: pointer;
    opacity: 0;
    z-index: 1; }
    .abc-checkbox input[type="checkbox"]:focus + label::before,
    .abc-checkbox input[type="radio"]:focus + label::before {
      outline: thin dotted;
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px; }
    .abc-checkbox input[type="checkbox"]:checked + label::after,
    .abc-checkbox input[type="radio"]:checked + label::after {
      font-family: "FontAwesome";
      content: "\F00C"; }
    .abc-checkbox input[type="checkbox"]:indeterminate + label::after,
    .abc-checkbox input[type="radio"]:indeterminate + label::after {
      display: block;
      content: "";
      width: 10px;
      height: 3px;
      background-color: #555555;
      border-radius: 2px;
      margin-left: -16.5px;
      margin-top: 7px; }
    .abc-checkbox input[type="checkbox"]:disabled + label,
    .abc-checkbox input[type="radio"]:disabled + label {
      opacity: 0.65; }
      .abc-checkbox input[type="checkbox"]:disabled + label::before,
      .abc-checkbox input[type="radio"]:disabled + label::before {
        background-color: #e9ecef;
        cursor: not-allowed; }
      .abc-checkbox input[type="checkbox"]:disabled + label::after,
      .abc-checkbox input[type="radio"]:disabled + label::after {
        cursor: not-allowed; }
  .abc-checkbox.abc-checkbox-circle label::before {
    border-radius: 50%; }
  .abc-checkbox.checkbox-inline {
    margin-top: 0; }

.abc-checkbox-primary input[type="checkbox"]:checked + label::before,
.abc-checkbox-primary input[type="radio"]:checked + label::before {
  background-color: #007bff;
  border-color: #007bff; }

.abc-checkbox-primary input[type="checkbox"]:checked + label::after,
.abc-checkbox-primary input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-danger input[type="checkbox"]:checked + label::before,
.abc-checkbox-danger input[type="radio"]:checked + label::before {
  background-color: #dc3545;
  border-color: #dc3545; }

.abc-checkbox-danger input[type="checkbox"]:checked + label::after,
.abc-checkbox-danger input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-info input[type="checkbox"]:checked + label::before,
.abc-checkbox-info input[type="radio"]:checked + label::before {
  background-color: #17a2b8;
  border-color: #17a2b8; }

.abc-checkbox-info input[type="checkbox"]:checked + label::after,
.abc-checkbox-info input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-warning input[type="checkbox"]:checked + label::before,
.abc-checkbox-warning input[type="radio"]:checked + label::before {
  background-color: #ffc107;
  border-color: #ffc107; }

.abc-checkbox-warning input[type="checkbox"]:checked + label::after,
.abc-checkbox-warning input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-success input[type="checkbox"]:checked + label::before,
.abc-checkbox-success input[type="radio"]:checked + label::before {
  background-color: #28a745;
  border-color: #28a745; }

.abc-checkbox-success input[type="checkbox"]:checked + label::after,
.abc-checkbox-success input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-primary input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-primary input[type="radio"]:indeterminate + label::before {
  background-color: #007bff;
  border-color: #007bff; }

.abc-checkbox-primary input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-primary input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-danger input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-danger input[type="radio"]:indeterminate + label::before {
  background-color: #dc3545;
  border-color: #dc3545; }

.abc-checkbox-danger input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-danger input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-info input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-info input[type="radio"]:indeterminate + label::before {
  background-color: #17a2b8;
  border-color: #17a2b8; }

.abc-checkbox-info input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-info input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-warning input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-warning input[type="radio"]:indeterminate + label::before {
  background-color: #ffc107;
  border-color: #ffc107; }

.abc-checkbox-warning input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-warning input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-success input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-success input[type="radio"]:indeterminate + label::before {
  background-color: #28a745;
  border-color: #28a745; }

.abc-checkbox-success input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-success input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-radio {
  cursor: default;
  padding-left: 4px; }
  .abc-radio label {
    cursor: pointer;
    display: inline;
    vertical-align: top;
    position: relative;
    padding-left: 5px; }
    .abc-radio label::before {
      cursor: pointer;
      content: "";
      display: inline-block;
      position: absolute;
      width: 17px;
      height: 17px;
      top: 2px;
      left: 0;
      margin-left: -20px;
      border: 1px solid #ced4da;
      border-radius: 50%;
      background-color: #fff;
      -webkit-transition: border 0.15s ease-in-out;
      transition: border 0.15s ease-in-out; }
    .abc-radio label::after {
      cursor: pointer;
      display: inline-block;
      position: absolute;
      content: " ";
      width: 11px;
      height: 11px;
      left: 3px;
      top: 5px;
      margin-left: -20px;
      border-radius: 50%;
      background-color: #495057;
      -webkit-transform: scale(0, 0);
              transform: scale(0, 0);
      -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33), -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33); }
  .abc-radio input[type="radio"] {
    position: static;
    margin-left: 0;
    cursor: pointer;
    opacity: 0;
    z-index: 1; }
    .abc-radio input[type="radio"]:focus + label::before {
      outline: thin dotted;
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px; }
    .abc-radio input[type="radio"]:checked + label::after {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1); }
    .abc-radio input[type="radio"]:disabled + label {
      opacity: 0.65; }
      .abc-radio input[type="radio"]:disabled + label::before {
        cursor: not-allowed; }
      .abc-radio input[type="radio"]:disabled + label::after {
        cursor: not-allowed; }
  .abc-radio.radio-inline {
    margin-top: 0; }

.abc-radio-primary input[type="radio"] + label::after {
  background-color: #007bff; }

.abc-radio-primary input[type="radio"]:checked + label::before {
  border-color: #007bff; }

.abc-radio-primary input[type="radio"]:checked + label::after {
  background-color: #007bff; }

.abc-radio-danger input[type="radio"] + label::after {
  background-color: #dc3545; }

.abc-radio-danger input[type="radio"]:checked + label::before {
  border-color: #dc3545; }

.abc-radio-danger input[type="radio"]:checked + label::after {
  background-color: #dc3545; }

.abc-radio-info input[type="radio"] + label::after {
  background-color: #17a2b8; }

.abc-radio-info input[type="radio"]:checked + label::before {
  border-color: #17a2b8; }

.abc-radio-info input[type="radio"]:checked + label::after {
  background-color: #17a2b8; }

.abc-radio-warning input[type="radio"] + label::after {
  background-color: #ffc107; }

.abc-radio-warning input[type="radio"]:checked + label::before {
  border-color: #ffc107; }

.abc-radio-warning input[type="radio"]:checked + label::after {
  background-color: #ffc107; }

.abc-radio-success input[type="radio"] + label::after {
  background-color: #28a745; }

.abc-radio-success input[type="radio"]:checked + label::before {
  border-color: #28a745; }

.abc-radio-success input[type="radio"]:checked + label::after {
  background-color: #28a745; }

label .was-validated .form-check-input:invalid .abc-checkbox:before, label
.was-validated .form-check-input:invalid .abc-radio:before, label .form-check-input.is-invalid .abc-checkbox:before, label
.form-check-input.is-invalid .abc-radio:before {
  border-color: #dc3545; }

@font-face {
  font-family: "Petasense Open Sans";
  src: url(/static/media/OpenSans-Regular_1.73d5e4b3.woff2); }

@font-face {
  font-family: "Petasense Open Sans";
  src: url(/static/media/OpenSans-Semibold_1.59d1fe1e.woff2);
  font-weight: 600; }

@font-face {
  font-family: 'Petasense Open Sans';
  src: url(/static/media/OpenSans-Bold_1.1b0edf91.woff2);
  font-weight: bold; }

.ps-hover-pointer:hover {
  cursor: pointer; }

.ps-hover-move {
  cursor: move; }

.hide {
  height: 0;
  width: 0;
  opacity: 0; }

.ps-logo-container {
  text-align: center;
  margin-top: 15vh;
  margin-bottom: 5vh; }
  .ps-logo-container img {
    width: 61%; }

.container.align-center {
  text-align: center; }

.container .ps-routes {
  width: 32%;
  margin: 0 auto; }

form.scrollable-form {
  overflow-y: scroll;
  max-height: 60vh; }
  form.scrollable-form::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(136, 134, 134, 0.925);
            box-shadow: inset 0 0 6px rgba(136, 134, 134, 0.925);
    background-color: #f5f5f5; }
  form.scrollable-form::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5; }
  form.scrollable-form::-webkit-scrollbar-thumb {
    background-color: #999b95; }

.ps-align-center {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }

.ps-align-center-column {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-direction: column;
      flex-direction: column; }

.list-error-message {
  color: #878983;
  padding-top: 20px;
  font-weight: bold; }
  .list-error-message.machine-list {
    border: 2px dashed #D8DCD3;
    padding: 20px;
    margin-top: 10px; }
  .list-error-message p {
    font-weight: 300; }

.top-padding-20 {
  padding-top: 20px; }

.small-row {
  width: 50%; }

.dropdown-title {
  max-width: 110px;
  white-space: normal !important; }

.help-block {
  color: #a94442;
  font-size: 12px;
  opacity: 0;
  margin: 0;
  max-height: 0;
  font-weight: bold;
  pointer-events: none;
  -webkit-transition: max-height 300ms ease, opacity 300ms ease;
  transition: max-height 300ms ease, opacity 300ms ease; }
  .help-block.onboarding-header {
    padding: 0px 0px 15px 0px; }
  .help-block.pop {
    opacity: 1;
    height: auto;
    margin-top: 5px;
    margin-bottom: 5px;
    max-height: 150px;
    -webkit-transition: max-height 300ms ease, opacity 300ms ease;
    transition: max-height 300ms ease, opacity 300ms ease; }

.ps-vertical-center {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

input.error {
  color: #a94442; }

.ps-help-block {
  color: #a94442;
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: bold;
  height: 0; }

.ps-horizontal-center {
  text-align: center; }

.ps-card {
  -webkit-transition: -webkit-box-shadow 0.2s;
  transition: -webkit-box-shadow 0.2s;
  transition: box-shadow 0.2s;
  transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0), 0 3px 6px rgba(0, 0, 0, 0);
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0), 0 3px 6px rgba(0, 0, 0, 0);
  background: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  display: inline-block;
  margin: 1vh auto;
  padding: 1.5vh;
  position: relative;
  text-align: center;
  width: 90%;
  height: 70px; }
  .ps-card:hover {
    cursor: pointer; }
  .ps-card:hover {
    -webkit-box-shadow: 0 4px 12px rgba(33, 33, 33, 0.3), 0 4px 12px rgba(0, 0, 0, 0.3);
            box-shadow: 0 4px 12px rgba(33, 33, 33, 0.3), 0 4px 12px rgba(0, 0, 0, 0.3); }
  .ps-card.active {
    border-color: #91b846;
    color: #91b846; }
    .ps-card.active:hover {
      -webkit-box-shadow: 0 5px 14px rgba(145, 184, 70, 0.8), 0 5px 14px rgba(145, 184, 70, 0.8);
              box-shadow: 0 5px 14px rgba(145, 184, 70, 0.8), 0 5px 14px rgba(145, 184, 70, 0.8);
      cursor: pointer; }
  .ps-card .title {
    text-align: left;
    margin: auto;
    font-size: 95%;
    font-weight: bold; }

.abc-checkbox {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .abc-checkbox label {
    font-family: "Petasense Open Sans";
    font-size: 16px;
    font-weight: normal;
    margin: 0px 10px 0px 19px; }
    .abc-checkbox label:after {
      color: #91b846;
      margin-left: -23px; }
    .abc-checkbox label:before {
      border-radius: 0px;
      margin-left: -23px;
      top: 2px; }

.full-screen-center {
  position: absolute;
  top: 50%;
  left: 50%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }

.fill-remaining-space {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-positive: 1;
      flex-grow: 1; }

.btn {
  height: 35px;
  font-size: 16px;
  margin: 3px 0;
  border-width: 0;
  width: 100%; }
  .btn.btn-primary {
    font-family: "Petasense Open Sans";
    background-color: #91b846;
    border-radius: 0px;
    color: white;
    margin-bottom: 10px; }
    .btn.btn-primary:hover, .btn.btn-primary:focus {
      background-color: #91b846; }
  .btn.btn-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    font-family: "Petasense Open Sans";
    background-color: "transparent";
    color: #91b846;
    -ms-flex-pack: center;
        justify-content: center; }

/**
------------------------------------------------------- Machine Severity CSS ------------------------------------------------------
 */
.ps-good-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-good-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-good-health:before {
    background: #91B846; }

.ps-slight-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-slight-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-slight-health:before {
    background: #4E89F4; }

.ps-moderate-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-moderate-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-moderate-health:before {
    background: #E4BE0C; }

.ps-warning-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-warning-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-warning-health:before {
    background: #F38F0A; }

.ps-critical-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-critical-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-critical-health:before {
    background: #E61111; }

.ps-unknown-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-unknown-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-unknown-health:before {
    background: #d3d3d3; }

.machine-condition-devider {
  height: 16px;
  width: 1px;
  background-color: #d8d8d6;
  margin: 0px 30px;
  visibility: hidden; }

.fixed-bar {
  position: fixed;
  background: #ffffff;
  width: 100%;
  z-index: 2;
  top: 0px; }

.ui.segment {
  border: none;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin: 0px;
  -webkit-box-shadow: none;
          box-shadow: none; }

.ui.grid > .row {
  padding-left: 1.1em;
  padding-top: 1rem;
  padding-bottom: 1rem; }

.custom-grid {
  border: 2px solid #f0f0f0;
  padding: 20px;
  width: 100%; }

.dashboard-list-container table {
  margin-left: -15px;
  margin-right: -15px;
  width: calc(100% + 30px); }
  .dashboard-list-container table th {
    padding-top: 0; }

.dashboard-list-container .dashboard-heading {
  border: none;
  border-radius: 0px;
  outline: none;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #333;
  font-size: 16px;
  text-align: left; }

.dashboard-list-container .dashboard-list-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-transition: -webkit-box-shadow .2s;
  transition: -webkit-box-shadow .2s;
  transition: box-shadow .2s;
  transition: box-shadow .2s, -webkit-box-shadow .2s;
  font-weight: normal;
  font-family: "Petasense Open Sans";
  padding: 8px 8px 8px 8px;
  font-size: 16px;
  color: #3C3D3A; }
  .dashboard-list-container .dashboard-list-item a {
    color: #3C3D3A; }

.dashboard-list-container .dashboard-activity-list .dashboard-activity-card {
  padding-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
  padding-bottom: 5px;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  -ms-flex-direction: column;
      flex-direction: column; }
  .dashboard-list-container .dashboard-activity-list .dashboard-activity-card .dashboard-card-timestamp {
    color: #808080;
    font-size: 12px; }

/**
 -- used http://chir.ag/projects/name-that-color/ to name color
*/
.abc-checkbox {
  cursor: default;
  padding-left: 4px; }
  .abc-checkbox label {
    cursor: pointer;
    display: inline;
    vertical-align: top;
    position: relative;
    padding-left: 5px; }
    .abc-checkbox label::before {
      cursor: pointer;
      content: "";
      display: inline-block;
      position: absolute;
      width: 17px;
      height: 17px;
      top: 2px;
      left: 0;
      margin-left: -1.25rem;
      border: 1px solid #ced4da;
      border-radius: 3px;
      background-color: #fff;
      -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
      transition: border 0.15s ease-in-out, color 0.15s ease-in-out; }
    .abc-checkbox label::after {
      cursor: pointer;
      display: inline-block;
      position: absolute;
      width: 16px;
      height: 16px;
      left: 0;
      top: 2px;
      margin-left: -1.25rem;
      padding-left: 3px;
      padding-top: 1px;
      font-size: 11px;
      color: #495057; }
  .abc-checkbox input[type="checkbox"],
  .abc-checkbox input[type="radio"] {
    position: static;
    margin-left: 0;
    cursor: pointer;
    opacity: 0;
    z-index: 1; }
    .abc-checkbox input[type="checkbox"]:focus + label::before,
    .abc-checkbox input[type="radio"]:focus + label::before {
      outline: thin dotted;
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px; }
    .abc-checkbox input[type="checkbox"]:checked + label::after,
    .abc-checkbox input[type="radio"]:checked + label::after {
      font-family: "FontAwesome";
      content: "\F00C"; }
    .abc-checkbox input[type="checkbox"]:indeterminate + label::after,
    .abc-checkbox input[type="radio"]:indeterminate + label::after {
      display: block;
      content: "";
      width: 10px;
      height: 3px;
      background-color: #555555;
      border-radius: 2px;
      margin-left: -16.5px;
      margin-top: 7px; }
    .abc-checkbox input[type="checkbox"]:disabled + label,
    .abc-checkbox input[type="radio"]:disabled + label {
      opacity: 0.65; }
      .abc-checkbox input[type="checkbox"]:disabled + label::before,
      .abc-checkbox input[type="radio"]:disabled + label::before {
        background-color: #e9ecef;
        cursor: not-allowed; }
      .abc-checkbox input[type="checkbox"]:disabled + label::after,
      .abc-checkbox input[type="radio"]:disabled + label::after {
        cursor: not-allowed; }
  .abc-checkbox.abc-checkbox-circle label::before {
    border-radius: 50%; }
  .abc-checkbox.checkbox-inline {
    margin-top: 0; }

.abc-checkbox-primary input[type="checkbox"]:checked + label::before,
.abc-checkbox-primary input[type="radio"]:checked + label::before {
  background-color: #007bff;
  border-color: #007bff; }

.abc-checkbox-primary input[type="checkbox"]:checked + label::after,
.abc-checkbox-primary input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-danger input[type="checkbox"]:checked + label::before,
.abc-checkbox-danger input[type="radio"]:checked + label::before {
  background-color: #dc3545;
  border-color: #dc3545; }

.abc-checkbox-danger input[type="checkbox"]:checked + label::after,
.abc-checkbox-danger input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-info input[type="checkbox"]:checked + label::before,
.abc-checkbox-info input[type="radio"]:checked + label::before {
  background-color: #17a2b8;
  border-color: #17a2b8; }

.abc-checkbox-info input[type="checkbox"]:checked + label::after,
.abc-checkbox-info input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-warning input[type="checkbox"]:checked + label::before,
.abc-checkbox-warning input[type="radio"]:checked + label::before {
  background-color: #ffc107;
  border-color: #ffc107; }

.abc-checkbox-warning input[type="checkbox"]:checked + label::after,
.abc-checkbox-warning input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-success input[type="checkbox"]:checked + label::before,
.abc-checkbox-success input[type="radio"]:checked + label::before {
  background-color: #28a745;
  border-color: #28a745; }

.abc-checkbox-success input[type="checkbox"]:checked + label::after,
.abc-checkbox-success input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-primary input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-primary input[type="radio"]:indeterminate + label::before {
  background-color: #007bff;
  border-color: #007bff; }

.abc-checkbox-primary input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-primary input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-danger input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-danger input[type="radio"]:indeterminate + label::before {
  background-color: #dc3545;
  border-color: #dc3545; }

.abc-checkbox-danger input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-danger input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-info input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-info input[type="radio"]:indeterminate + label::before {
  background-color: #17a2b8;
  border-color: #17a2b8; }

.abc-checkbox-info input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-info input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-warning input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-warning input[type="radio"]:indeterminate + label::before {
  background-color: #ffc107;
  border-color: #ffc107; }

.abc-checkbox-warning input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-warning input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-success input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-success input[type="radio"]:indeterminate + label::before {
  background-color: #28a745;
  border-color: #28a745; }

.abc-checkbox-success input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-success input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-radio {
  cursor: default;
  padding-left: 4px; }
  .abc-radio label {
    cursor: pointer;
    display: inline;
    vertical-align: top;
    position: relative;
    padding-left: 5px; }
    .abc-radio label::before {
      cursor: pointer;
      content: "";
      display: inline-block;
      position: absolute;
      width: 17px;
      height: 17px;
      top: 2px;
      left: 0;
      margin-left: -20px;
      border: 1px solid #ced4da;
      border-radius: 50%;
      background-color: #fff;
      -webkit-transition: border 0.15s ease-in-out;
      transition: border 0.15s ease-in-out; }
    .abc-radio label::after {
      cursor: pointer;
      display: inline-block;
      position: absolute;
      content: " ";
      width: 11px;
      height: 11px;
      left: 3px;
      top: 5px;
      margin-left: -20px;
      border-radius: 50%;
      background-color: #495057;
      -webkit-transform: scale(0, 0);
              transform: scale(0, 0);
      -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33), -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33); }
  .abc-radio input[type="radio"] {
    position: static;
    margin-left: 0;
    cursor: pointer;
    opacity: 0;
    z-index: 1; }
    .abc-radio input[type="radio"]:focus + label::before {
      outline: thin dotted;
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px; }
    .abc-radio input[type="radio"]:checked + label::after {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1); }
    .abc-radio input[type="radio"]:disabled + label {
      opacity: 0.65; }
      .abc-radio input[type="radio"]:disabled + label::before {
        cursor: not-allowed; }
      .abc-radio input[type="radio"]:disabled + label::after {
        cursor: not-allowed; }
  .abc-radio.radio-inline {
    margin-top: 0; }

.abc-radio-primary input[type="radio"] + label::after {
  background-color: #007bff; }

.abc-radio-primary input[type="radio"]:checked + label::before {
  border-color: #007bff; }

.abc-radio-primary input[type="radio"]:checked + label::after {
  background-color: #007bff; }

.abc-radio-danger input[type="radio"] + label::after {
  background-color: #dc3545; }

.abc-radio-danger input[type="radio"]:checked + label::before {
  border-color: #dc3545; }

.abc-radio-danger input[type="radio"]:checked + label::after {
  background-color: #dc3545; }

.abc-radio-info input[type="radio"] + label::after {
  background-color: #17a2b8; }

.abc-radio-info input[type="radio"]:checked + label::before {
  border-color: #17a2b8; }

.abc-radio-info input[type="radio"]:checked + label::after {
  background-color: #17a2b8; }

.abc-radio-warning input[type="radio"] + label::after {
  background-color: #ffc107; }

.abc-radio-warning input[type="radio"]:checked + label::before {
  border-color: #ffc107; }

.abc-radio-warning input[type="radio"]:checked + label::after {
  background-color: #ffc107; }

.abc-radio-success input[type="radio"] + label::after {
  background-color: #28a745; }

.abc-radio-success input[type="radio"]:checked + label::before {
  border-color: #28a745; }

.abc-radio-success input[type="radio"]:checked + label::after {
  background-color: #28a745; }

label .was-validated .form-check-input:invalid .abc-checkbox:before, label
.was-validated .form-check-input:invalid .abc-radio:before, label .form-check-input.is-invalid .abc-checkbox:before, label
.form-check-input.is-invalid .abc-radio:before {
  border-color: #dc3545; }

@font-face {
  font-family: "Petasense Open Sans";
  src: url(/static/media/OpenSans-Regular_1.73d5e4b3.woff2); }

@font-face {
  font-family: "Petasense Open Sans";
  src: url(/static/media/OpenSans-Semibold_1.59d1fe1e.woff2);
  font-weight: 600; }

@font-face {
  font-family: 'Petasense Open Sans';
  src: url(/static/media/OpenSans-Bold_1.1b0edf91.woff2);
  font-weight: bold; }

.ps-hover-pointer:hover {
  cursor: pointer; }

.ps-hover-move {
  cursor: move; }

.hide {
  height: 0;
  width: 0;
  opacity: 0; }

.ps-logo-container {
  text-align: center;
  margin-top: 15vh;
  margin-bottom: 5vh; }
  .ps-logo-container img {
    width: 61%; }

.container.align-center {
  text-align: center; }

.container .ps-routes {
  width: 32%;
  margin: 0 auto; }

form.scrollable-form {
  overflow-y: scroll;
  max-height: 60vh; }
  form.scrollable-form::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(136, 134, 134, 0.925);
            box-shadow: inset 0 0 6px rgba(136, 134, 134, 0.925);
    background-color: #f5f5f5; }
  form.scrollable-form::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5; }
  form.scrollable-form::-webkit-scrollbar-thumb {
    background-color: #999b95; }

.ps-align-center {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }

.ps-align-center-column {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-direction: column;
      flex-direction: column; }

.list-error-message {
  color: #878983;
  padding-top: 20px;
  font-weight: bold; }
  .list-error-message.machine-list {
    border: 2px dashed #D8DCD3;
    padding: 20px;
    margin-top: 10px; }
  .list-error-message p {
    font-weight: 300; }

.top-padding-20 {
  padding-top: 20px; }

.small-row {
  width: 50%; }

.dropdown-title {
  max-width: 110px;
  white-space: normal !important; }

.help-block {
  color: #a94442;
  font-size: 12px;
  opacity: 0;
  margin: 0;
  max-height: 0;
  font-weight: bold;
  pointer-events: none;
  -webkit-transition: max-height 300ms ease, opacity 300ms ease;
  transition: max-height 300ms ease, opacity 300ms ease; }
  .help-block.onboarding-header {
    padding: 0px 0px 15px 0px; }
  .help-block.pop {
    opacity: 1;
    height: auto;
    margin-top: 5px;
    margin-bottom: 5px;
    max-height: 150px;
    -webkit-transition: max-height 300ms ease, opacity 300ms ease;
    transition: max-height 300ms ease, opacity 300ms ease; }

.ps-vertical-center {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

input.error {
  color: #a94442; }

.ps-help-block {
  color: #a94442;
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: bold;
  height: 0; }

.ps-horizontal-center {
  text-align: center; }

.ps-card {
  -webkit-transition: -webkit-box-shadow 0.2s;
  transition: -webkit-box-shadow 0.2s;
  transition: box-shadow 0.2s;
  transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0), 0 3px 6px rgba(0, 0, 0, 0);
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0), 0 3px 6px rgba(0, 0, 0, 0);
  background: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  display: inline-block;
  margin: 1vh auto;
  padding: 1.5vh;
  position: relative;
  text-align: center;
  width: 90%;
  height: 70px; }
  .ps-card:hover {
    cursor: pointer; }
  .ps-card:hover {
    -webkit-box-shadow: 0 4px 12px rgba(33, 33, 33, 0.3), 0 4px 12px rgba(0, 0, 0, 0.3);
            box-shadow: 0 4px 12px rgba(33, 33, 33, 0.3), 0 4px 12px rgba(0, 0, 0, 0.3); }
  .ps-card.active {
    border-color: #91b846;
    color: #91b846; }
    .ps-card.active:hover {
      -webkit-box-shadow: 0 5px 14px rgba(145, 184, 70, 0.8), 0 5px 14px rgba(145, 184, 70, 0.8);
              box-shadow: 0 5px 14px rgba(145, 184, 70, 0.8), 0 5px 14px rgba(145, 184, 70, 0.8);
      cursor: pointer; }
  .ps-card .title {
    text-align: left;
    margin: auto;
    font-size: 95%;
    font-weight: bold; }

.abc-checkbox {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .abc-checkbox label {
    font-family: "Petasense Open Sans";
    font-size: 16px;
    font-weight: normal;
    margin: 0px 10px 0px 19px; }
    .abc-checkbox label:after {
      color: #91b846;
      margin-left: -23px; }
    .abc-checkbox label:before {
      border-radius: 0px;
      margin-left: -23px;
      top: 2px; }

.full-screen-center {
  position: absolute;
  top: 50%;
  left: 50%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }

.fill-remaining-space {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-positive: 1;
      flex-grow: 1; }

.btn {
  height: 35px;
  font-size: 16px;
  margin: 3px 0;
  border-width: 0;
  width: 100%; }
  .btn.btn-primary {
    font-family: "Petasense Open Sans";
    background-color: #91b846;
    border-radius: 0px;
    color: white;
    margin-bottom: 10px; }
    .btn.btn-primary:hover, .btn.btn-primary:focus {
      background-color: #91b846; }
  .btn.btn-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    font-family: "Petasense Open Sans";
    background-color: "transparent";
    color: #91b846;
    -ms-flex-pack: center;
        justify-content: center; }

/**
------------------------------------------------------- Machine Severity CSS ------------------------------------------------------
 */
.ps-good-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-good-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-good-health:before {
    background: #91B846; }

.ps-slight-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-slight-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-slight-health:before {
    background: #4E89F4; }

.ps-moderate-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-moderate-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-moderate-health:before {
    background: #E4BE0C; }

.ps-warning-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-warning-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-warning-health:before {
    background: #F38F0A; }

.ps-critical-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-critical-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-critical-health:before {
    background: #E61111; }

.ps-unknown-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-unknown-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-unknown-health:before {
    background: #d3d3d3; }

.machine-condition-devider {
  height: 16px;
  width: 1px;
  background-color: #d8d8d6;
  margin: 0px 30px;
  visibility: hidden; }

.fixed-bar {
  position: fixed;
  background: #ffffff;
  width: 100%;
  z-index: 2;
  top: 0px; }

.ui.segment {
  border: none;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin: 0px;
  -webkit-box-shadow: none;
          box-shadow: none; }

.ui.grid > .row {
  padding-left: 1.1em;
  padding-top: 1rem;
  padding-bottom: 1rem; }

.custom-grid {
  border: 2px solid #f0f0f0;
  padding: 20px;
  width: 100%; }

.sensors-container {
  padding-top: 10px;
  background-color: #fff; }
  .sensors-container .ui.secondary.pointing.menu {
    border-bottom: 1px solid rgba(34, 36, 38, 0.15);
    margin: 0;
    z-index: 3;
    background: white; }
    .sensors-container .ui.secondary.pointing.menu .item {
      border-bottom-width: 4px;
      padding: 24px 16px; }
      .sensors-container .ui.secondary.pointing.menu .item.active {
        color: rgba(0, 0, 0, 0.87) !important; }
  .sensors-container .ui.attached.segment .sensors-list-scroller {
    overflow: hidden; }
    .sensors-container .ui.attached.segment .sensors-list-scroller::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(136, 134, 134, 0.925);
              box-shadow: inset 0 0 6px rgba(136, 134, 134, 0.925);
      background-color: #f5f5f5; }
    .sensors-container .ui.attached.segment .sensors-list-scroller::-webkit-scrollbar {
      width: 5px;
      background-color: #f5f5f5; }
    .sensors-container .ui.attached.segment .sensors-list-scroller::-webkit-scrollbar-thumb {
      background-color: #999b95; }
    .sensors-container .ui.attached.segment .sensors-list-scroller .sensor-item {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      -webkit-transition: width 0.3s, -webkit-box-shadow 0.2s;
      transition: width 0.3s, -webkit-box-shadow 0.2s;
      transition: box-shadow 0.2s, width 0.3s;
      transition: box-shadow 0.2s, width 0.3s, -webkit-box-shadow 0.2s;
      -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0), 0 3px 6px rgba(0, 0, 0, 0);
              box-shadow: 0 3px 6px rgba(0, 0, 0, 0), 0 3px 6px rgba(0, 0, 0, 0);
      border-radius: 4px;
      width: 100%;
      text-align: left;
      font-weight: 300;
      font-size: 16px;
      margin: 0;
      padding: 7px 22px 7px 20px;
      border-bottom: 1px solid #e5e8e1;
      background: #ffffff;
      color: #2d2e2c;
      height: 50px; }
      .sensors-container .ui.attached.segment .sensors-list-scroller .sensor-item .col-xs-12 {
        padding-left: 0; }
      .sensors-container .ui.attached.segment .sensors-list-scroller .sensor-item .battery, .sensors-container .ui.attached.segment .sensors-list-scroller .sensor-item .wifi {
        padding-left: 30px; }
      .sensors-container .ui.attached.segment .sensors-list-scroller .sensor-item.compact {
        width: 50%; }
      .sensors-container .ui.attached.segment .sensors-list-scroller .sensor-item a {
        color: #3C3D3A; }
      .sensors-container .ui.attached.segment .sensors-list-scroller .sensor-item .sensor-info-container {
        max-width: 214px;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
            align-items: center;
        display: flex;
        align-items: center;
        white-space: pre; }
        .sensors-container .ui.attached.segment .sensors-list-scroller .sensor-item .sensor-info-container .sensor-info {
          margin-left: auto;
          color: #999B95;
          font-size: 19px;
          font-weight: 300; }
  .sensors-container hr {
    height: 1px;
    background-color: #d3d4d5; }
  .sensors-container .search-input {
    padding-top: 33px; }
    .sensors-container .search-input:after {
      top: 43px; }
  .sensors-container .ui.active.tab {
    border: none;
    background-color: white;
    padding: 0;
    width: 100%; }
    .sensors-container .ui.active.tab .sensors {
      border-top: none; }
  .sensors-container .margin-zero {
    margin: 0; }

/**
 -- used http://chir.ag/projects/name-that-color/ to name color
*/
.abc-checkbox {
  cursor: default;
  padding-left: 4px; }
  .abc-checkbox label {
    cursor: pointer;
    display: inline;
    vertical-align: top;
    position: relative;
    padding-left: 5px; }
    .abc-checkbox label::before {
      cursor: pointer;
      content: "";
      display: inline-block;
      position: absolute;
      width: 17px;
      height: 17px;
      top: 2px;
      left: 0;
      margin-left: -1.25rem;
      border: 1px solid #ced4da;
      border-radius: 3px;
      background-color: #fff;
      -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
      transition: border 0.15s ease-in-out, color 0.15s ease-in-out; }
    .abc-checkbox label::after {
      cursor: pointer;
      display: inline-block;
      position: absolute;
      width: 16px;
      height: 16px;
      left: 0;
      top: 2px;
      margin-left: -1.25rem;
      padding-left: 3px;
      padding-top: 1px;
      font-size: 11px;
      color: #495057; }
  .abc-checkbox input[type="checkbox"],
  .abc-checkbox input[type="radio"] {
    position: static;
    margin-left: 0;
    cursor: pointer;
    opacity: 0;
    z-index: 1; }
    .abc-checkbox input[type="checkbox"]:focus + label::before,
    .abc-checkbox input[type="radio"]:focus + label::before {
      outline: thin dotted;
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px; }
    .abc-checkbox input[type="checkbox"]:checked + label::after,
    .abc-checkbox input[type="radio"]:checked + label::after {
      font-family: "FontAwesome";
      content: "\F00C"; }
    .abc-checkbox input[type="checkbox"]:indeterminate + label::after,
    .abc-checkbox input[type="radio"]:indeterminate + label::after {
      display: block;
      content: "";
      width: 10px;
      height: 3px;
      background-color: #555555;
      border-radius: 2px;
      margin-left: -16.5px;
      margin-top: 7px; }
    .abc-checkbox input[type="checkbox"]:disabled + label,
    .abc-checkbox input[type="radio"]:disabled + label {
      opacity: 0.65; }
      .abc-checkbox input[type="checkbox"]:disabled + label::before,
      .abc-checkbox input[type="radio"]:disabled + label::before {
        background-color: #e9ecef;
        cursor: not-allowed; }
      .abc-checkbox input[type="checkbox"]:disabled + label::after,
      .abc-checkbox input[type="radio"]:disabled + label::after {
        cursor: not-allowed; }
  .abc-checkbox.abc-checkbox-circle label::before {
    border-radius: 50%; }
  .abc-checkbox.checkbox-inline {
    margin-top: 0; }

.abc-checkbox-primary input[type="checkbox"]:checked + label::before,
.abc-checkbox-primary input[type="radio"]:checked + label::before {
  background-color: #007bff;
  border-color: #007bff; }

.abc-checkbox-primary input[type="checkbox"]:checked + label::after,
.abc-checkbox-primary input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-danger input[type="checkbox"]:checked + label::before,
.abc-checkbox-danger input[type="radio"]:checked + label::before {
  background-color: #dc3545;
  border-color: #dc3545; }

.abc-checkbox-danger input[type="checkbox"]:checked + label::after,
.abc-checkbox-danger input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-info input[type="checkbox"]:checked + label::before,
.abc-checkbox-info input[type="radio"]:checked + label::before {
  background-color: #17a2b8;
  border-color: #17a2b8; }

.abc-checkbox-info input[type="checkbox"]:checked + label::after,
.abc-checkbox-info input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-warning input[type="checkbox"]:checked + label::before,
.abc-checkbox-warning input[type="radio"]:checked + label::before {
  background-color: #ffc107;
  border-color: #ffc107; }

.abc-checkbox-warning input[type="checkbox"]:checked + label::after,
.abc-checkbox-warning input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-success input[type="checkbox"]:checked + label::before,
.abc-checkbox-success input[type="radio"]:checked + label::before {
  background-color: #28a745;
  border-color: #28a745; }

.abc-checkbox-success input[type="checkbox"]:checked + label::after,
.abc-checkbox-success input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-primary input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-primary input[type="radio"]:indeterminate + label::before {
  background-color: #007bff;
  border-color: #007bff; }

.abc-checkbox-primary input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-primary input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-danger input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-danger input[type="radio"]:indeterminate + label::before {
  background-color: #dc3545;
  border-color: #dc3545; }

.abc-checkbox-danger input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-danger input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-info input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-info input[type="radio"]:indeterminate + label::before {
  background-color: #17a2b8;
  border-color: #17a2b8; }

.abc-checkbox-info input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-info input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-warning input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-warning input[type="radio"]:indeterminate + label::before {
  background-color: #ffc107;
  border-color: #ffc107; }

.abc-checkbox-warning input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-warning input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-success input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-success input[type="radio"]:indeterminate + label::before {
  background-color: #28a745;
  border-color: #28a745; }

.abc-checkbox-success input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-success input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-radio {
  cursor: default;
  padding-left: 4px; }
  .abc-radio label {
    cursor: pointer;
    display: inline;
    vertical-align: top;
    position: relative;
    padding-left: 5px; }
    .abc-radio label::before {
      cursor: pointer;
      content: "";
      display: inline-block;
      position: absolute;
      width: 17px;
      height: 17px;
      top: 2px;
      left: 0;
      margin-left: -20px;
      border: 1px solid #ced4da;
      border-radius: 50%;
      background-color: #fff;
      -webkit-transition: border 0.15s ease-in-out;
      transition: border 0.15s ease-in-out; }
    .abc-radio label::after {
      cursor: pointer;
      display: inline-block;
      position: absolute;
      content: " ";
      width: 11px;
      height: 11px;
      left: 3px;
      top: 5px;
      margin-left: -20px;
      border-radius: 50%;
      background-color: #495057;
      -webkit-transform: scale(0, 0);
              transform: scale(0, 0);
      -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33), -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33); }
  .abc-radio input[type="radio"] {
    position: static;
    margin-left: 0;
    cursor: pointer;
    opacity: 0;
    z-index: 1; }
    .abc-radio input[type="radio"]:focus + label::before {
      outline: thin dotted;
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px; }
    .abc-radio input[type="radio"]:checked + label::after {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1); }
    .abc-radio input[type="radio"]:disabled + label {
      opacity: 0.65; }
      .abc-radio input[type="radio"]:disabled + label::before {
        cursor: not-allowed; }
      .abc-radio input[type="radio"]:disabled + label::after {
        cursor: not-allowed; }
  .abc-radio.radio-inline {
    margin-top: 0; }

.abc-radio-primary input[type="radio"] + label::after {
  background-color: #007bff; }

.abc-radio-primary input[type="radio"]:checked + label::before {
  border-color: #007bff; }

.abc-radio-primary input[type="radio"]:checked + label::after {
  background-color: #007bff; }

.abc-radio-danger input[type="radio"] + label::after {
  background-color: #dc3545; }

.abc-radio-danger input[type="radio"]:checked + label::before {
  border-color: #dc3545; }

.abc-radio-danger input[type="radio"]:checked + label::after {
  background-color: #dc3545; }

.abc-radio-info input[type="radio"] + label::after {
  background-color: #17a2b8; }

.abc-radio-info input[type="radio"]:checked + label::before {
  border-color: #17a2b8; }

.abc-radio-info input[type="radio"]:checked + label::after {
  background-color: #17a2b8; }

.abc-radio-warning input[type="radio"] + label::after {
  background-color: #ffc107; }

.abc-radio-warning input[type="radio"]:checked + label::before {
  border-color: #ffc107; }

.abc-radio-warning input[type="radio"]:checked + label::after {
  background-color: #ffc107; }

.abc-radio-success input[type="radio"] + label::after {
  background-color: #28a745; }

.abc-radio-success input[type="radio"]:checked + label::before {
  border-color: #28a745; }

.abc-radio-success input[type="radio"]:checked + label::after {
  background-color: #28a745; }

label .was-validated .form-check-input:invalid .abc-checkbox:before, label
.was-validated .form-check-input:invalid .abc-radio:before, label .form-check-input.is-invalid .abc-checkbox:before, label
.form-check-input.is-invalid .abc-radio:before {
  border-color: #dc3545; }

@font-face {
  font-family: "Petasense Open Sans";
  src: url(/static/media/OpenSans-Regular_1.73d5e4b3.woff2); }

@font-face {
  font-family: "Petasense Open Sans";
  src: url(/static/media/OpenSans-Semibold_1.59d1fe1e.woff2);
  font-weight: 600; }

@font-face {
  font-family: 'Petasense Open Sans';
  src: url(/static/media/OpenSans-Bold_1.1b0edf91.woff2);
  font-weight: bold; }

.ps-hover-pointer:hover {
  cursor: pointer; }

.ps-hover-move {
  cursor: move; }

.hide {
  height: 0;
  width: 0;
  opacity: 0; }

.ps-logo-container {
  text-align: center;
  margin-top: 15vh;
  margin-bottom: 5vh; }
  .ps-logo-container img {
    width: 61%; }

.container.align-center {
  text-align: center; }

.container .ps-routes {
  width: 32%;
  margin: 0 auto; }

form.scrollable-form {
  overflow-y: scroll;
  max-height: 60vh; }
  form.scrollable-form::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(136, 134, 134, 0.925);
            box-shadow: inset 0 0 6px rgba(136, 134, 134, 0.925);
    background-color: #f5f5f5; }
  form.scrollable-form::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5; }
  form.scrollable-form::-webkit-scrollbar-thumb {
    background-color: #999b95; }

.ps-align-center {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }

.ps-align-center-column {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-direction: column;
      flex-direction: column; }

.list-error-message {
  color: #878983;
  padding-top: 20px;
  font-weight: bold; }
  .list-error-message.machine-list {
    border: 2px dashed #D8DCD3;
    padding: 20px;
    margin-top: 10px; }
  .list-error-message p {
    font-weight: 300; }

.top-padding-20 {
  padding-top: 20px; }

.small-row {
  width: 50%; }

.dropdown-title {
  max-width: 110px;
  white-space: normal !important; }

.help-block {
  color: #a94442;
  font-size: 12px;
  opacity: 0;
  margin: 0;
  max-height: 0;
  font-weight: bold;
  pointer-events: none;
  -webkit-transition: max-height 300ms ease, opacity 300ms ease;
  transition: max-height 300ms ease, opacity 300ms ease; }
  .help-block.onboarding-header {
    padding: 0px 0px 15px 0px; }
  .help-block.pop {
    opacity: 1;
    height: auto;
    margin-top: 5px;
    margin-bottom: 5px;
    max-height: 150px;
    -webkit-transition: max-height 300ms ease, opacity 300ms ease;
    transition: max-height 300ms ease, opacity 300ms ease; }

.ps-vertical-center {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

input.error {
  color: #a94442; }

.ps-help-block {
  color: #a94442;
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: bold;
  height: 0; }

.ps-horizontal-center {
  text-align: center; }

.ps-card {
  -webkit-transition: -webkit-box-shadow 0.2s;
  transition: -webkit-box-shadow 0.2s;
  transition: box-shadow 0.2s;
  transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0), 0 3px 6px rgba(0, 0, 0, 0);
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0), 0 3px 6px rgba(0, 0, 0, 0);
  background: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  display: inline-block;
  margin: 1vh auto;
  padding: 1.5vh;
  position: relative;
  text-align: center;
  width: 90%;
  height: 70px; }
  .ps-card:hover {
    cursor: pointer; }
  .ps-card:hover {
    -webkit-box-shadow: 0 4px 12px rgba(33, 33, 33, 0.3), 0 4px 12px rgba(0, 0, 0, 0.3);
            box-shadow: 0 4px 12px rgba(33, 33, 33, 0.3), 0 4px 12px rgba(0, 0, 0, 0.3); }
  .ps-card.active {
    border-color: #91b846;
    color: #91b846; }
    .ps-card.active:hover {
      -webkit-box-shadow: 0 5px 14px rgba(145, 184, 70, 0.8), 0 5px 14px rgba(145, 184, 70, 0.8);
              box-shadow: 0 5px 14px rgba(145, 184, 70, 0.8), 0 5px 14px rgba(145, 184, 70, 0.8);
      cursor: pointer; }
  .ps-card .title {
    text-align: left;
    margin: auto;
    font-size: 95%;
    font-weight: bold; }

.abc-checkbox {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .abc-checkbox label {
    font-family: "Petasense Open Sans";
    font-size: 16px;
    font-weight: normal;
    margin: 0px 10px 0px 19px; }
    .abc-checkbox label:after {
      color: #91b846;
      margin-left: -23px; }
    .abc-checkbox label:before {
      border-radius: 0px;
      margin-left: -23px;
      top: 2px; }

.full-screen-center {
  position: absolute;
  top: 50%;
  left: 50%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }

.fill-remaining-space {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-positive: 1;
      flex-grow: 1; }

.btn {
  height: 35px;
  font-size: 16px;
  margin: 3px 0;
  border-width: 0;
  width: 100%; }
  .btn.btn-primary {
    font-family: "Petasense Open Sans";
    background-color: #91b846;
    border-radius: 0px;
    color: white;
    margin-bottom: 10px; }
    .btn.btn-primary:hover, .btn.btn-primary:focus {
      background-color: #91b846; }
  .btn.btn-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    font-family: "Petasense Open Sans";
    background-color: "transparent";
    color: #91b846;
    -ms-flex-pack: center;
        justify-content: center; }

/**
------------------------------------------------------- Machine Severity CSS ------------------------------------------------------
 */
.ps-good-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-good-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-good-health:before {
    background: #91B846; }

.ps-slight-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-slight-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-slight-health:before {
    background: #4E89F4; }

.ps-moderate-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-moderate-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-moderate-health:before {
    background: #E4BE0C; }

.ps-warning-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-warning-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-warning-health:before {
    background: #F38F0A; }

.ps-critical-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-critical-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-critical-health:before {
    background: #E61111; }

.ps-unknown-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-unknown-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-unknown-health:before {
    background: #d3d3d3; }

.machine-condition-devider {
  height: 16px;
  width: 1px;
  background-color: #d8d8d6;
  margin: 0px 30px;
  visibility: hidden; }

.fixed-bar {
  position: fixed;
  background: #ffffff;
  width: 100%;
  z-index: 2;
  top: 0px; }

.ui.segment {
  border: none;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin: 0px;
  -webkit-box-shadow: none;
          box-shadow: none; }

.ui.grid > .row {
  padding-left: 1.1em;
  padding-top: 1rem;
  padding-bottom: 1rem; }

.custom-grid {
  border: 2px solid #f0f0f0;
  padding: 20px;
  width: 100%; }

.machine-details .machine-title {
  color: #3c3d3a;
  font-family: "Petasense Open Sans";
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  cursor: pointer; }

.machine-details .machine-title-editing {
  font-weight: normal;
  cursor: auto; }

.machine-details .machine-description-label {
  padding: 10px 0px 10px 46px;
  margin-left: -46px; }
  .machine-details .machine-description-label.no-description {
    display: none; }

.machine-details .machine-condition-container {
  top: 126px;
  margin: 0px -46px;
  padding: 0px 46px 12px 46px;
  display: -ms-flexbox;
  display: flex;
  color: #aaa;
  font-size: 14px; }
  .machine-details .machine-condition-container .machine-condition-label {
    margin-right: 12px; }
  .machine-details .machine-condition-container .machine-condition-on-off {
    color: #afc447;
    font-family: "Petasense Open Sans";
    font-size: 12px;
    margin-left: 35px; }

.machine-details .tab-content {
  background: #EFF0EE;
  color: #383838;
  min-height: calc(100vh - 138px); }
  .machine-details .tab-content .tags-count {
    font-weight: 600;
    color: #999b95;
    padding-bottom: 15px; }
  .machine-details .tab-content .inner-tab-content {
    padding: 2em; }
    .machine-details .tab-content .inner-tab-content .ui.secondary.pointing.menu {
      margin: 0px -17px;
      padding-top: 0;
      padding-left: 17px;
      background: white;
      border-bottom: 1px solid rgba(34, 36, 38, 0.15); }
      .machine-details .tab-content .inner-tab-content .ui.secondary.pointing.menu .item {
        border-bottom-width: 3px; }
        .machine-details .tab-content .inner-tab-content .ui.secondary.pointing.menu .item.active {
          color: #3C3D3A !important; }
      .machine-details .tab-content .inner-tab-content .ui.secondary.pointing.menu a.item {
        padding: 26px 16px 24px 16px; }
    .machine-details .tab-content .inner-tab-content .sensors {
      margin-left: 10px; }
      .machine-details .tab-content .inner-tab-content .sensors hr {
        margin-left: -25px;
        margin-right: -20px; }
      .machine-details .tab-content .inner-tab-content .sensors .row.sensors-sorters {
        padding: 14px 0px;
        background: white;
        margin-left: -24px;
        border-bottom: 2px solid #D8DCD3;
        font-size: 12px;
        color: #999B95; }
      .machine-details .tab-content .inner-tab-content .sensors .sensor-item {
        min-height: 50px;
        background: white;
        margin: 0px -15px 0px -24px;
        border-bottom: 1px solid #E5E8E1;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
            align-items: center;
        font-size: 16px; }
        .machine-details .tab-content .inner-tab-content .sensors .sensor-item .sensor-info-container {
          display: -ms-flexbox;
          display: flex;
          -ms-flex-pack: justify;
              justify-content: space-between;
          -ms-flex-align: center;
              align-items: center; }
          .machine-details .tab-content .inner-tab-content .sensors .sensor-item .sensor-info-container i {
            color: #999B95;
            font-size: 19px;
            font-weight: 300; }
        .machine-details .tab-content .inner-tab-content .sensors .sensor-item .wifi {
          padding-left: 38px; }
        .machine-details .tab-content .inner-tab-content .sensors .sensor-item .battery {
          font-size: 14px; }

.machine-details .main-tab > .ui.secondary.menu {
  margin: 0;
  width: 100%;
  min-height: auto;
  z-index: 1;
  background: white;
  border-bottom: 1px solid #D8DCD3; }
  .machine-details .main-tab > .ui.secondary.menu .item {
    font-weight: 600;
    font-size: 14px;
    color: #3C3D3A;
    padding: 24px 3px;
    margin-right: 35px;
    margin-left: 0px; }
    .machine-details .main-tab > .ui.secondary.menu .item:first-of-type {
      margin-left: 2em; }
    .machine-details .main-tab > .ui.secondary.menu .item.active {
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
      background-color: white;
      position: relative; }
      .machine-details .main-tab > .ui.secondary.menu .item.active:after {
        content: '';
        height: 3px;
        width: 100%;
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        background: -webkit-gradient(linear, left top, left bottom, from(#AFC447), to(#91b846));
        background: linear-gradient(to bottom, #AFC447, #91b846); }
  .machine-details .main-tab > .ui.secondary.menu a.item:hover {
    background: white; }

.tag-charts-container {
  display: -ms-flexbox;
  display: flex;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 1em;
  padding-bottom: 1em;
  position: relative; }
  .tag-charts-container.selected:before {
    content: '';
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 2px;
    height: 70%;
    background-color: #AFC447; }
  .tag-charts-container .tag-charts-element {
    -ms-flex: 1 1 auto;
        flex: 1 1 auto;
    width: 100%;
    position: relative;
    font-weight: 600; }
    .tag-charts-container .tag-charts-element > h3 {
      margin-top: 20px;
      margin-left: 20px; }
    .tag-charts-container .tag-charts-element > div .right-pane {
      font-size: 10px;
      margin-top: 31px; }
      .tag-charts-container .tag-charts-element > div .right-pane .comment-button-container {
        width: 140px;
        margin-bottom: 0px; }
        .tag-charts-container .tag-charts-element > div .right-pane .comment-button-container > a {
          cursor: pointer; }
    .tag-charts-container .tag-charts-element > div.trend {
      display: -ms-flexbox;
      display: flex;
      min-height: 136px;
      position: relative; }
      .tag-charts-container .tag-charts-element > div.trend .right-pane {
        margin-top: 31px;
        margin-left: auto;
        margin-right: 30px;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
            flex-direction: column;
        -ms-flex-align: center;
            align-items: center; }
        .tag-charts-container .tag-charts-element > div.trend .right-pane .ui.selection.dropdown {
          margin-top: 4px;
          margin-bottom: 20px;
          line-height: 0.5em;
          min-height: auto; }
          .tag-charts-container .tag-charts-element > div.trend .right-pane .ui.selection.dropdown > i {
            padding-top: 0.5em;
            padding-bottom: 0.5em; }
          .tag-charts-container .tag-charts-element > div.trend .right-pane .ui.selection.dropdown .menu > .item {
            line-height: 1;
            padding-top: 6px !important;
            padding-bottom: 6px !important;
            display: -ms-flexbox;
            display: flex; }
            .tag-charts-container .tag-charts-element > div.trend .right-pane .ui.selection.dropdown .menu > .item span {
              font-size: 10px; }
    .tag-charts-container .tag-charts-element > div .chart-loading-container {
      position: absolute;
      left: calc(50% - 10px);
      top: calc(50% - 5px); }
    .tag-charts-container .tag-charts-element > div.waveform {
      margin-top: 0px;
      display: -ms-flexbox;
      display: flex;
      min-height: 0px;
      position: relative; }
      .tag-charts-container .tag-charts-element > div.waveform .right-pane > div {
        margin-bottom: 0px; }
    .tag-charts-container .tag-charts-element > div.spectrum {
      margin-top: 0px;
      display: -ms-flexbox;
      display: flex;
      min-height: 0px;
      position: relative; }
    .tag-charts-container .tag-charts-element .tag-charts-header {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-pack: justify;
          justify-content: space-between;
      padding: 0 30px;
      position: absolute;
      width: 100%;
      top: 16px; }
      .tag-charts-container .tag-charts-element .tag-charts-header h3 {
        font-family: "Petasense Open Sans";
        font-size: 14px;
        margin: 0px;
        font-weight: 600; }
      .tag-charts-container .tag-charts-element .tag-charts-header .tag-charts-buttons {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
            align-items: center;
        color: #AFAFAF;
        font-size: 10px; }
        .tag-charts-container .tag-charts-element .tag-charts-header .tag-charts-buttons .tag-charts-button-item {
          margin-right: 20px; }
          .tag-charts-container .tag-charts-element .tag-charts-header .tag-charts-buttons .tag-charts-button-item.fa {
            line-height: 20px; }
        .tag-charts-container .tag-charts-element .tag-charts-header .tag-charts-buttons .tag-charts-button-item, .tag-charts-container .tag-charts-element .tag-charts-header .tag-charts-buttons i {
          color: #AFAFAF; }
          .tag-charts-container .tag-charts-element .tag-charts-header .tag-charts-buttons .tag-charts-button-item.active, .tag-charts-container .tag-charts-element .tag-charts-header .tag-charts-buttons .tag-charts-button-item:hover, .tag-charts-container .tag-charts-element .tag-charts-header .tag-charts-buttons i.active, .tag-charts-container .tag-charts-element .tag-charts-header .tag-charts-buttons i:hover {
            color: #383838; }
        .tag-charts-container .tag-charts-element .tag-charts-header .tag-charts-buttons .tag-charts-button-ver-expand {
          height: 13.5px;
          width: 13.5px;
          border: 1.5px dashed #AFAFAF;
          border-radius: 2px; }
          .tag-charts-container .tag-charts-element .tag-charts-header .tag-charts-buttons .tag-charts-button-ver-expand.active, .tag-charts-container .tag-charts-element .tag-charts-header .tag-charts-buttons .tag-charts-button-ver-expand:hover {
            border-color: #383838; }
        .tag-charts-container .tag-charts-element .tag-charts-header .tag-charts-buttons .chart-more-dropdown {
          right: 0px;
          top: calc(100% + 10px);
          left: unset; }

/**
 -- used http://chir.ag/projects/name-that-color/ to name color
*/
.abc-checkbox {
  cursor: default;
  padding-left: 4px; }
  .abc-checkbox label {
    cursor: pointer;
    display: inline;
    vertical-align: top;
    position: relative;
    padding-left: 5px; }
    .abc-checkbox label::before {
      cursor: pointer;
      content: "";
      display: inline-block;
      position: absolute;
      width: 17px;
      height: 17px;
      top: 2px;
      left: 0;
      margin-left: -1.25rem;
      border: 1px solid #ced4da;
      border-radius: 3px;
      background-color: #fff;
      -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
      transition: border 0.15s ease-in-out, color 0.15s ease-in-out; }
    .abc-checkbox label::after {
      cursor: pointer;
      display: inline-block;
      position: absolute;
      width: 16px;
      height: 16px;
      left: 0;
      top: 2px;
      margin-left: -1.25rem;
      padding-left: 3px;
      padding-top: 1px;
      font-size: 11px;
      color: #495057; }
  .abc-checkbox input[type="checkbox"],
  .abc-checkbox input[type="radio"] {
    position: static;
    margin-left: 0;
    cursor: pointer;
    opacity: 0;
    z-index: 1; }
    .abc-checkbox input[type="checkbox"]:focus + label::before,
    .abc-checkbox input[type="radio"]:focus + label::before {
      outline: thin dotted;
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px; }
    .abc-checkbox input[type="checkbox"]:checked + label::after,
    .abc-checkbox input[type="radio"]:checked + label::after {
      font-family: "FontAwesome";
      content: "\F00C"; }
    .abc-checkbox input[type="checkbox"]:indeterminate + label::after,
    .abc-checkbox input[type="radio"]:indeterminate + label::after {
      display: block;
      content: "";
      width: 10px;
      height: 3px;
      background-color: #555555;
      border-radius: 2px;
      margin-left: -16.5px;
      margin-top: 7px; }
    .abc-checkbox input[type="checkbox"]:disabled + label,
    .abc-checkbox input[type="radio"]:disabled + label {
      opacity: 0.65; }
      .abc-checkbox input[type="checkbox"]:disabled + label::before,
      .abc-checkbox input[type="radio"]:disabled + label::before {
        background-color: #e9ecef;
        cursor: not-allowed; }
      .abc-checkbox input[type="checkbox"]:disabled + label::after,
      .abc-checkbox input[type="radio"]:disabled + label::after {
        cursor: not-allowed; }
  .abc-checkbox.abc-checkbox-circle label::before {
    border-radius: 50%; }
  .abc-checkbox.checkbox-inline {
    margin-top: 0; }

.abc-checkbox-primary input[type="checkbox"]:checked + label::before,
.abc-checkbox-primary input[type="radio"]:checked + label::before {
  background-color: #007bff;
  border-color: #007bff; }

.abc-checkbox-primary input[type="checkbox"]:checked + label::after,
.abc-checkbox-primary input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-danger input[type="checkbox"]:checked + label::before,
.abc-checkbox-danger input[type="radio"]:checked + label::before {
  background-color: #dc3545;
  border-color: #dc3545; }

.abc-checkbox-danger input[type="checkbox"]:checked + label::after,
.abc-checkbox-danger input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-info input[type="checkbox"]:checked + label::before,
.abc-checkbox-info input[type="radio"]:checked + label::before {
  background-color: #17a2b8;
  border-color: #17a2b8; }

.abc-checkbox-info input[type="checkbox"]:checked + label::after,
.abc-checkbox-info input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-warning input[type="checkbox"]:checked + label::before,
.abc-checkbox-warning input[type="radio"]:checked + label::before {
  background-color: #ffc107;
  border-color: #ffc107; }

.abc-checkbox-warning input[type="checkbox"]:checked + label::after,
.abc-checkbox-warning input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-success input[type="checkbox"]:checked + label::before,
.abc-checkbox-success input[type="radio"]:checked + label::before {
  background-color: #28a745;
  border-color: #28a745; }

.abc-checkbox-success input[type="checkbox"]:checked + label::after,
.abc-checkbox-success input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-primary input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-primary input[type="radio"]:indeterminate + label::before {
  background-color: #007bff;
  border-color: #007bff; }

.abc-checkbox-primary input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-primary input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-danger input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-danger input[type="radio"]:indeterminate + label::before {
  background-color: #dc3545;
  border-color: #dc3545; }

.abc-checkbox-danger input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-danger input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-info input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-info input[type="radio"]:indeterminate + label::before {
  background-color: #17a2b8;
  border-color: #17a2b8; }

.abc-checkbox-info input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-info input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-warning input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-warning input[type="radio"]:indeterminate + label::before {
  background-color: #ffc107;
  border-color: #ffc107; }

.abc-checkbox-warning input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-warning input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-success input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-success input[type="radio"]:indeterminate + label::before {
  background-color: #28a745;
  border-color: #28a745; }

.abc-checkbox-success input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-success input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-radio {
  cursor: default;
  padding-left: 4px; }
  .abc-radio label {
    cursor: pointer;
    display: inline;
    vertical-align: top;
    position: relative;
    padding-left: 5px; }
    .abc-radio label::before {
      cursor: pointer;
      content: "";
      display: inline-block;
      position: absolute;
      width: 17px;
      height: 17px;
      top: 2px;
      left: 0;
      margin-left: -20px;
      border: 1px solid #ced4da;
      border-radius: 50%;
      background-color: #fff;
      -webkit-transition: border 0.15s ease-in-out;
      transition: border 0.15s ease-in-out; }
    .abc-radio label::after {
      cursor: pointer;
      display: inline-block;
      position: absolute;
      content: " ";
      width: 11px;
      height: 11px;
      left: 3px;
      top: 5px;
      margin-left: -20px;
      border-radius: 50%;
      background-color: #495057;
      -webkit-transform: scale(0, 0);
              transform: scale(0, 0);
      -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33), -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33); }
  .abc-radio input[type="radio"] {
    position: static;
    margin-left: 0;
    cursor: pointer;
    opacity: 0;
    z-index: 1; }
    .abc-radio input[type="radio"]:focus + label::before {
      outline: thin dotted;
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px; }
    .abc-radio input[type="radio"]:checked + label::after {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1); }
    .abc-radio input[type="radio"]:disabled + label {
      opacity: 0.65; }
      .abc-radio input[type="radio"]:disabled + label::before {
        cursor: not-allowed; }
      .abc-radio input[type="radio"]:disabled + label::after {
        cursor: not-allowed; }
  .abc-radio.radio-inline {
    margin-top: 0; }

.abc-radio-primary input[type="radio"] + label::after {
  background-color: #007bff; }

.abc-radio-primary input[type="radio"]:checked + label::before {
  border-color: #007bff; }

.abc-radio-primary input[type="radio"]:checked + label::after {
  background-color: #007bff; }

.abc-radio-danger input[type="radio"] + label::after {
  background-color: #dc3545; }

.abc-radio-danger input[type="radio"]:checked + label::before {
  border-color: #dc3545; }

.abc-radio-danger input[type="radio"]:checked + label::after {
  background-color: #dc3545; }

.abc-radio-info input[type="radio"] + label::after {
  background-color: #17a2b8; }

.abc-radio-info input[type="radio"]:checked + label::before {
  border-color: #17a2b8; }

.abc-radio-info input[type="radio"]:checked + label::after {
  background-color: #17a2b8; }

.abc-radio-warning input[type="radio"] + label::after {
  background-color: #ffc107; }

.abc-radio-warning input[type="radio"]:checked + label::before {
  border-color: #ffc107; }

.abc-radio-warning input[type="radio"]:checked + label::after {
  background-color: #ffc107; }

.abc-radio-success input[type="radio"] + label::after {
  background-color: #28a745; }

.abc-radio-success input[type="radio"]:checked + label::before {
  border-color: #28a745; }

.abc-radio-success input[type="radio"]:checked + label::after {
  background-color: #28a745; }

label .was-validated .form-check-input:invalid .abc-checkbox:before, label
.was-validated .form-check-input:invalid .abc-radio:before, label .form-check-input.is-invalid .abc-checkbox:before, label
.form-check-input.is-invalid .abc-radio:before {
  border-color: #dc3545; }

@font-face {
  font-family: "Petasense Open Sans";
  src: url(/static/media/OpenSans-Regular_1.73d5e4b3.woff2); }

@font-face {
  font-family: "Petasense Open Sans";
  src: url(/static/media/OpenSans-Semibold_1.59d1fe1e.woff2);
  font-weight: 600; }

@font-face {
  font-family: 'Petasense Open Sans';
  src: url(/static/media/OpenSans-Bold_1.1b0edf91.woff2);
  font-weight: bold; }

.ps-hover-pointer:hover {
  cursor: pointer; }

.ps-hover-move {
  cursor: move; }

.hide {
  height: 0;
  width: 0;
  opacity: 0; }

.ps-logo-container {
  text-align: center;
  margin-top: 15vh;
  margin-bottom: 5vh; }
  .ps-logo-container img {
    width: 61%; }

.container.align-center {
  text-align: center; }

.container .ps-routes {
  width: 32%;
  margin: 0 auto; }

form.scrollable-form {
  overflow-y: scroll;
  max-height: 60vh; }
  form.scrollable-form::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(136, 134, 134, 0.925);
            box-shadow: inset 0 0 6px rgba(136, 134, 134, 0.925);
    background-color: #f5f5f5; }
  form.scrollable-form::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5; }
  form.scrollable-form::-webkit-scrollbar-thumb {
    background-color: #999b95; }

.ps-align-center {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }

.ps-align-center-column {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-direction: column;
      flex-direction: column; }

.list-error-message {
  color: #878983;
  padding-top: 20px;
  font-weight: bold; }
  .list-error-message.machine-list {
    border: 2px dashed #D8DCD3;
    padding: 20px;
    margin-top: 10px; }
  .list-error-message p {
    font-weight: 300; }

.top-padding-20 {
  padding-top: 20px; }

.small-row {
  width: 50%; }

.dropdown-title {
  max-width: 110px;
  white-space: normal !important; }

.help-block {
  color: #a94442;
  font-size: 12px;
  opacity: 0;
  margin: 0;
  max-height: 0;
  font-weight: bold;
  pointer-events: none;
  -webkit-transition: max-height 300ms ease, opacity 300ms ease;
  transition: max-height 300ms ease, opacity 300ms ease; }
  .help-block.onboarding-header {
    padding: 0px 0px 15px 0px; }
  .help-block.pop {
    opacity: 1;
    height: auto;
    margin-top: 5px;
    margin-bottom: 5px;
    max-height: 150px;
    -webkit-transition: max-height 300ms ease, opacity 300ms ease;
    transition: max-height 300ms ease, opacity 300ms ease; }

.ps-vertical-center {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

input.error {
  color: #a94442; }

.ps-help-block {
  color: #a94442;
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: bold;
  height: 0; }

.ps-horizontal-center {
  text-align: center; }

.ps-card {
  -webkit-transition: -webkit-box-shadow 0.2s;
  transition: -webkit-box-shadow 0.2s;
  transition: box-shadow 0.2s;
  transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0), 0 3px 6px rgba(0, 0, 0, 0);
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0), 0 3px 6px rgba(0, 0, 0, 0);
  background: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  display: inline-block;
  margin: 1vh auto;
  padding: 1.5vh;
  position: relative;
  text-align: center;
  width: 90%;
  height: 70px; }
  .ps-card:hover {
    cursor: pointer; }
  .ps-card:hover {
    -webkit-box-shadow: 0 4px 12px rgba(33, 33, 33, 0.3), 0 4px 12px rgba(0, 0, 0, 0.3);
            box-shadow: 0 4px 12px rgba(33, 33, 33, 0.3), 0 4px 12px rgba(0, 0, 0, 0.3); }
  .ps-card.active {
    border-color: #91b846;
    color: #91b846; }
    .ps-card.active:hover {
      -webkit-box-shadow: 0 5px 14px rgba(145, 184, 70, 0.8), 0 5px 14px rgba(145, 184, 70, 0.8);
              box-shadow: 0 5px 14px rgba(145, 184, 70, 0.8), 0 5px 14px rgba(145, 184, 70, 0.8);
      cursor: pointer; }
  .ps-card .title {
    text-align: left;
    margin: auto;
    font-size: 95%;
    font-weight: bold; }

.abc-checkbox {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .abc-checkbox label {
    font-family: "Petasense Open Sans";
    font-size: 16px;
    font-weight: normal;
    margin: 0px 10px 0px 19px; }
    .abc-checkbox label:after {
      color: #91b846;
      margin-left: -23px; }
    .abc-checkbox label:before {
      border-radius: 0px;
      margin-left: -23px;
      top: 2px; }

.full-screen-center {
  position: absolute;
  top: 50%;
  left: 50%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }

.fill-remaining-space {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-positive: 1;
      flex-grow: 1; }

.btn {
  height: 35px;
  font-size: 16px;
  margin: 3px 0;
  border-width: 0;
  width: 100%; }
  .btn.btn-primary {
    font-family: "Petasense Open Sans";
    background-color: #91b846;
    border-radius: 0px;
    color: white;
    margin-bottom: 10px; }
    .btn.btn-primary:hover, .btn.btn-primary:focus {
      background-color: #91b846; }
  .btn.btn-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    font-family: "Petasense Open Sans";
    background-color: "transparent";
    color: #91b846;
    -ms-flex-pack: center;
        justify-content: center; }

/**
------------------------------------------------------- Machine Severity CSS ------------------------------------------------------
 */
.ps-good-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-good-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-good-health:before {
    background: #91B846; }

.ps-slight-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-slight-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-slight-health:before {
    background: #4E89F4; }

.ps-moderate-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-moderate-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-moderate-health:before {
    background: #E4BE0C; }

.ps-warning-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-warning-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-warning-health:before {
    background: #F38F0A; }

.ps-critical-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-critical-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-critical-health:before {
    background: #E61111; }

.ps-unknown-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-unknown-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-unknown-health:before {
    background: #d3d3d3; }

.machine-condition-devider {
  height: 16px;
  width: 1px;
  background-color: #d8d8d6;
  margin: 0px 30px;
  visibility: hidden; }

.fixed-bar {
  position: fixed;
  background: #ffffff;
  width: 100%;
  z-index: 2;
  top: 0px; }

.ui.segment {
  border: none;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin: 0px;
  -webkit-box-shadow: none;
          box-shadow: none; }

.ui.grid > .row {
  padding-left: 1.1em;
  padding-top: 1rem;
  padding-bottom: 1rem; }

.custom-grid {
  border: 2px solid #f0f0f0;
  padding: 20px;
  width: 100%; }

/**
 -- used http://chir.ag/projects/name-that-color/ to name color
*/
.abc-checkbox {
  cursor: default;
  padding-left: 4px; }
  .abc-checkbox label {
    cursor: pointer;
    display: inline;
    vertical-align: top;
    position: relative;
    padding-left: 5px; }
    .abc-checkbox label::before {
      cursor: pointer;
      content: "";
      display: inline-block;
      position: absolute;
      width: 17px;
      height: 17px;
      top: 2px;
      left: 0;
      margin-left: -1.25rem;
      border: 1px solid #ced4da;
      border-radius: 3px;
      background-color: #fff;
      -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
      transition: border 0.15s ease-in-out, color 0.15s ease-in-out; }
    .abc-checkbox label::after {
      cursor: pointer;
      display: inline-block;
      position: absolute;
      width: 16px;
      height: 16px;
      left: 0;
      top: 2px;
      margin-left: -1.25rem;
      padding-left: 3px;
      padding-top: 1px;
      font-size: 11px;
      color: #495057; }
  .abc-checkbox input[type="checkbox"],
  .abc-checkbox input[type="radio"] {
    position: static;
    margin-left: 0;
    cursor: pointer;
    opacity: 0;
    z-index: 1; }
    .abc-checkbox input[type="checkbox"]:focus + label::before,
    .abc-checkbox input[type="radio"]:focus + label::before {
      outline: thin dotted;
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px; }
    .abc-checkbox input[type="checkbox"]:checked + label::after,
    .abc-checkbox input[type="radio"]:checked + label::after {
      font-family: "FontAwesome";
      content: "\F00C"; }
    .abc-checkbox input[type="checkbox"]:indeterminate + label::after,
    .abc-checkbox input[type="radio"]:indeterminate + label::after {
      display: block;
      content: "";
      width: 10px;
      height: 3px;
      background-color: #555555;
      border-radius: 2px;
      margin-left: -16.5px;
      margin-top: 7px; }
    .abc-checkbox input[type="checkbox"]:disabled + label,
    .abc-checkbox input[type="radio"]:disabled + label {
      opacity: 0.65; }
      .abc-checkbox input[type="checkbox"]:disabled + label::before,
      .abc-checkbox input[type="radio"]:disabled + label::before {
        background-color: #e9ecef;
        cursor: not-allowed; }
      .abc-checkbox input[type="checkbox"]:disabled + label::after,
      .abc-checkbox input[type="radio"]:disabled + label::after {
        cursor: not-allowed; }
  .abc-checkbox.abc-checkbox-circle label::before {
    border-radius: 50%; }
  .abc-checkbox.checkbox-inline {
    margin-top: 0; }

.abc-checkbox-primary input[type="checkbox"]:checked + label::before,
.abc-checkbox-primary input[type="radio"]:checked + label::before {
  background-color: #007bff;
  border-color: #007bff; }

.abc-checkbox-primary input[type="checkbox"]:checked + label::after,
.abc-checkbox-primary input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-danger input[type="checkbox"]:checked + label::before,
.abc-checkbox-danger input[type="radio"]:checked + label::before {
  background-color: #dc3545;
  border-color: #dc3545; }

.abc-checkbox-danger input[type="checkbox"]:checked + label::after,
.abc-checkbox-danger input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-info input[type="checkbox"]:checked + label::before,
.abc-checkbox-info input[type="radio"]:checked + label::before {
  background-color: #17a2b8;
  border-color: #17a2b8; }

.abc-checkbox-info input[type="checkbox"]:checked + label::after,
.abc-checkbox-info input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-warning input[type="checkbox"]:checked + label::before,
.abc-checkbox-warning input[type="radio"]:checked + label::before {
  background-color: #ffc107;
  border-color: #ffc107; }

.abc-checkbox-warning input[type="checkbox"]:checked + label::after,
.abc-checkbox-warning input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-success input[type="checkbox"]:checked + label::before,
.abc-checkbox-success input[type="radio"]:checked + label::before {
  background-color: #28a745;
  border-color: #28a745; }

.abc-checkbox-success input[type="checkbox"]:checked + label::after,
.abc-checkbox-success input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-primary input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-primary input[type="radio"]:indeterminate + label::before {
  background-color: #007bff;
  border-color: #007bff; }

.abc-checkbox-primary input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-primary input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-danger input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-danger input[type="radio"]:indeterminate + label::before {
  background-color: #dc3545;
  border-color: #dc3545; }

.abc-checkbox-danger input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-danger input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-info input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-info input[type="radio"]:indeterminate + label::before {
  background-color: #17a2b8;
  border-color: #17a2b8; }

.abc-checkbox-info input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-info input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-warning input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-warning input[type="radio"]:indeterminate + label::before {
  background-color: #ffc107;
  border-color: #ffc107; }

.abc-checkbox-warning input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-warning input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-success input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-success input[type="radio"]:indeterminate + label::before {
  background-color: #28a745;
  border-color: #28a745; }

.abc-checkbox-success input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-success input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-radio {
  cursor: default;
  padding-left: 4px; }
  .abc-radio label {
    cursor: pointer;
    display: inline;
    vertical-align: top;
    position: relative;
    padding-left: 5px; }
    .abc-radio label::before {
      cursor: pointer;
      content: "";
      display: inline-block;
      position: absolute;
      width: 17px;
      height: 17px;
      top: 2px;
      left: 0;
      margin-left: -20px;
      border: 1px solid #ced4da;
      border-radius: 50%;
      background-color: #fff;
      -webkit-transition: border 0.15s ease-in-out;
      transition: border 0.15s ease-in-out; }
    .abc-radio label::after {
      cursor: pointer;
      display: inline-block;
      position: absolute;
      content: " ";
      width: 11px;
      height: 11px;
      left: 3px;
      top: 5px;
      margin-left: -20px;
      border-radius: 50%;
      background-color: #495057;
      -webkit-transform: scale(0, 0);
              transform: scale(0, 0);
      -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33), -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33); }
  .abc-radio input[type="radio"] {
    position: static;
    margin-left: 0;
    cursor: pointer;
    opacity: 0;
    z-index: 1; }
    .abc-radio input[type="radio"]:focus + label::before {
      outline: thin dotted;
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px; }
    .abc-radio input[type="radio"]:checked + label::after {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1); }
    .abc-radio input[type="radio"]:disabled + label {
      opacity: 0.65; }
      .abc-radio input[type="radio"]:disabled + label::before {
        cursor: not-allowed; }
      .abc-radio input[type="radio"]:disabled + label::after {
        cursor: not-allowed; }
  .abc-radio.radio-inline {
    margin-top: 0; }

.abc-radio-primary input[type="radio"] + label::after {
  background-color: #007bff; }

.abc-radio-primary input[type="radio"]:checked + label::before {
  border-color: #007bff; }

.abc-radio-primary input[type="radio"]:checked + label::after {
  background-color: #007bff; }

.abc-radio-danger input[type="radio"] + label::after {
  background-color: #dc3545; }

.abc-radio-danger input[type="radio"]:checked + label::before {
  border-color: #dc3545; }

.abc-radio-danger input[type="radio"]:checked + label::after {
  background-color: #dc3545; }

.abc-radio-info input[type="radio"] + label::after {
  background-color: #17a2b8; }

.abc-radio-info input[type="radio"]:checked + label::before {
  border-color: #17a2b8; }

.abc-radio-info input[type="radio"]:checked + label::after {
  background-color: #17a2b8; }

.abc-radio-warning input[type="radio"] + label::after {
  background-color: #ffc107; }

.abc-radio-warning input[type="radio"]:checked + label::before {
  border-color: #ffc107; }

.abc-radio-warning input[type="radio"]:checked + label::after {
  background-color: #ffc107; }

.abc-radio-success input[type="radio"] + label::after {
  background-color: #28a745; }

.abc-radio-success input[type="radio"]:checked + label::before {
  border-color: #28a745; }

.abc-radio-success input[type="radio"]:checked + label::after {
  background-color: #28a745; }

label .was-validated .form-check-input:invalid .abc-checkbox:before, label
.was-validated .form-check-input:invalid .abc-radio:before, label .form-check-input.is-invalid .abc-checkbox:before, label
.form-check-input.is-invalid .abc-radio:before {
  border-color: #dc3545; }

@font-face {
  font-family: "Petasense Open Sans";
  src: url(/static/media/OpenSans-Regular_1.73d5e4b3.woff2); }

@font-face {
  font-family: "Petasense Open Sans";
  src: url(/static/media/OpenSans-Semibold_1.59d1fe1e.woff2);
  font-weight: 600; }

@font-face {
  font-family: 'Petasense Open Sans';
  src: url(/static/media/OpenSans-Bold_1.1b0edf91.woff2);
  font-weight: bold; }

.ps-hover-pointer:hover {
  cursor: pointer; }

.ps-hover-move {
  cursor: move; }

.hide {
  height: 0;
  width: 0;
  opacity: 0; }

.ps-logo-container {
  text-align: center;
  margin-top: 15vh;
  margin-bottom: 5vh; }
  .ps-logo-container img {
    width: 61%; }

.container.align-center {
  text-align: center; }

.container .ps-routes {
  width: 32%;
  margin: 0 auto; }

form.scrollable-form {
  overflow-y: scroll;
  max-height: 60vh; }
  form.scrollable-form::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(136, 134, 134, 0.925);
            box-shadow: inset 0 0 6px rgba(136, 134, 134, 0.925);
    background-color: #f5f5f5; }
  form.scrollable-form::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5; }
  form.scrollable-form::-webkit-scrollbar-thumb {
    background-color: #999b95; }

.ps-align-center {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }

.ps-align-center-column {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-direction: column;
      flex-direction: column; }

.list-error-message {
  color: #878983;
  padding-top: 20px;
  font-weight: bold; }
  .list-error-message.machine-list {
    border: 2px dashed #D8DCD3;
    padding: 20px;
    margin-top: 10px; }
  .list-error-message p {
    font-weight: 300; }

.top-padding-20 {
  padding-top: 20px; }

.small-row {
  width: 50%; }

.dropdown-title {
  max-width: 110px;
  white-space: normal !important; }

.help-block {
  color: #a94442;
  font-size: 12px;
  opacity: 0;
  margin: 0;
  max-height: 0;
  font-weight: bold;
  pointer-events: none;
  -webkit-transition: max-height 300ms ease, opacity 300ms ease;
  transition: max-height 300ms ease, opacity 300ms ease; }
  .help-block.onboarding-header {
    padding: 0px 0px 15px 0px; }
  .help-block.pop {
    opacity: 1;
    height: auto;
    margin-top: 5px;
    margin-bottom: 5px;
    max-height: 150px;
    -webkit-transition: max-height 300ms ease, opacity 300ms ease;
    transition: max-height 300ms ease, opacity 300ms ease; }

.ps-vertical-center {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

input.error {
  color: #a94442; }

.ps-help-block {
  color: #a94442;
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: bold;
  height: 0; }

.ps-horizontal-center {
  text-align: center; }

.ps-card {
  -webkit-transition: -webkit-box-shadow 0.2s;
  transition: -webkit-box-shadow 0.2s;
  transition: box-shadow 0.2s;
  transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0), 0 3px 6px rgba(0, 0, 0, 0);
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0), 0 3px 6px rgba(0, 0, 0, 0);
  background: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  display: inline-block;
  margin: 1vh auto;
  padding: 1.5vh;
  position: relative;
  text-align: center;
  width: 90%;
  height: 70px; }
  .ps-card:hover {
    cursor: pointer; }
  .ps-card:hover {
    -webkit-box-shadow: 0 4px 12px rgba(33, 33, 33, 0.3), 0 4px 12px rgba(0, 0, 0, 0.3);
            box-shadow: 0 4px 12px rgba(33, 33, 33, 0.3), 0 4px 12px rgba(0, 0, 0, 0.3); }
  .ps-card.active {
    border-color: #91b846;
    color: #91b846; }
    .ps-card.active:hover {
      -webkit-box-shadow: 0 5px 14px rgba(145, 184, 70, 0.8), 0 5px 14px rgba(145, 184, 70, 0.8);
              box-shadow: 0 5px 14px rgba(145, 184, 70, 0.8), 0 5px 14px rgba(145, 184, 70, 0.8);
      cursor: pointer; }
  .ps-card .title {
    text-align: left;
    margin: auto;
    font-size: 95%;
    font-weight: bold; }

.abc-checkbox {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .abc-checkbox label {
    font-family: "Petasense Open Sans";
    font-size: 16px;
    font-weight: normal;
    margin: 0px 10px 0px 19px; }
    .abc-checkbox label:after {
      color: #91b846;
      margin-left: -23px; }
    .abc-checkbox label:before {
      border-radius: 0px;
      margin-left: -23px;
      top: 2px; }

.full-screen-center {
  position: absolute;
  top: 50%;
  left: 50%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }

.fill-remaining-space {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-positive: 1;
      flex-grow: 1; }

.btn {
  height: 35px;
  font-size: 16px;
  margin: 3px 0;
  border-width: 0;
  width: 100%; }
  .btn.btn-primary {
    font-family: "Petasense Open Sans";
    background-color: #91b846;
    border-radius: 0px;
    color: white;
    margin-bottom: 10px; }
    .btn.btn-primary:hover, .btn.btn-primary:focus {
      background-color: #91b846; }
  .btn.btn-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    font-family: "Petasense Open Sans";
    background-color: "transparent";
    color: #91b846;
    -ms-flex-pack: center;
        justify-content: center; }

/**
------------------------------------------------------- Machine Severity CSS ------------------------------------------------------
 */
.ps-good-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-good-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-good-health:before {
    background: #91B846; }

.ps-slight-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-slight-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-slight-health:before {
    background: #4E89F4; }

.ps-moderate-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-moderate-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-moderate-health:before {
    background: #E4BE0C; }

.ps-warning-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-warning-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-warning-health:before {
    background: #F38F0A; }

.ps-critical-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-critical-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-critical-health:before {
    background: #E61111; }

.ps-unknown-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-unknown-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-unknown-health:before {
    background: #d3d3d3; }

.machine-condition-devider {
  height: 16px;
  width: 1px;
  background-color: #d8d8d6;
  margin: 0px 30px;
  visibility: hidden; }

.fixed-bar {
  position: fixed;
  background: #ffffff;
  width: 100%;
  z-index: 2;
  top: 0px; }

.ui.segment {
  border: none;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin: 0px;
  -webkit-box-shadow: none;
          box-shadow: none; }

.ui.grid > .row {
  padding-left: 1.1em;
  padding-top: 1rem;
  padding-bottom: 1rem; }

.custom-grid {
  border: 2px solid #f0f0f0;
  padding: 20px;
  width: 100%; }

.ps-info-label {
  font-size: 13px;
  color: #74777C; }

.ps-modal-overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(56, 56, 56, 0.96);
  z-index: 4; }

.ps-modal-content {
  position: fixed;
  top: 50%;
  bottom: auto;
  left: 50%;
  right: auto;
  border-radius: 2px;
  min-height: 5rem;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: white;
  width: 960px; }

.ps-modal-header {
  border-bottom: 1px solid #e5e5e5;
  padding: 1rem 0; }
  .ps-modal-header h4 {
    font-family: "Petasense Open Sans" !important; }
  .ps-modal-header.feature-modal {
    background-color: white;
    border-bottom: none;
    -ms-flex-positive: 1;
        flex-grow: 1; }
    .ps-modal-header.feature-modal .modal-title {
      font-size: 12px;
      font-weight: 600; }
  .ps-modal-header .close {
    margin-top: -2px; }
  .ps-modal-header .modal-title {
    margin: 0; }

/**
 -- used http://chir.ag/projects/name-that-color/ to name color
*/
.abc-checkbox {
  cursor: default;
  padding-left: 4px; }
  .abc-checkbox label {
    cursor: pointer;
    display: inline;
    vertical-align: top;
    position: relative;
    padding-left: 5px; }
    .abc-checkbox label::before {
      cursor: pointer;
      content: "";
      display: inline-block;
      position: absolute;
      width: 17px;
      height: 17px;
      top: 2px;
      left: 0;
      margin-left: -1.25rem;
      border: 1px solid #ced4da;
      border-radius: 3px;
      background-color: #fff;
      -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
      transition: border 0.15s ease-in-out, color 0.15s ease-in-out; }
    .abc-checkbox label::after {
      cursor: pointer;
      display: inline-block;
      position: absolute;
      width: 16px;
      height: 16px;
      left: 0;
      top: 2px;
      margin-left: -1.25rem;
      padding-left: 3px;
      padding-top: 1px;
      font-size: 11px;
      color: #495057; }
  .abc-checkbox input[type="checkbox"],
  .abc-checkbox input[type="radio"] {
    position: static;
    margin-left: 0;
    cursor: pointer;
    opacity: 0;
    z-index: 1; }
    .abc-checkbox input[type="checkbox"]:focus + label::before,
    .abc-checkbox input[type="radio"]:focus + label::before {
      outline: thin dotted;
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px; }
    .abc-checkbox input[type="checkbox"]:checked + label::after,
    .abc-checkbox input[type="radio"]:checked + label::after {
      font-family: "FontAwesome";
      content: "\F00C"; }
    .abc-checkbox input[type="checkbox"]:indeterminate + label::after,
    .abc-checkbox input[type="radio"]:indeterminate + label::after {
      display: block;
      content: "";
      width: 10px;
      height: 3px;
      background-color: #555555;
      border-radius: 2px;
      margin-left: -16.5px;
      margin-top: 7px; }
    .abc-checkbox input[type="checkbox"]:disabled + label,
    .abc-checkbox input[type="radio"]:disabled + label {
      opacity: 0.65; }
      .abc-checkbox input[type="checkbox"]:disabled + label::before,
      .abc-checkbox input[type="radio"]:disabled + label::before {
        background-color: #e9ecef;
        cursor: not-allowed; }
      .abc-checkbox input[type="checkbox"]:disabled + label::after,
      .abc-checkbox input[type="radio"]:disabled + label::after {
        cursor: not-allowed; }
  .abc-checkbox.abc-checkbox-circle label::before {
    border-radius: 50%; }
  .abc-checkbox.checkbox-inline {
    margin-top: 0; }

.abc-checkbox-primary input[type="checkbox"]:checked + label::before,
.abc-checkbox-primary input[type="radio"]:checked + label::before {
  background-color: #007bff;
  border-color: #007bff; }

.abc-checkbox-primary input[type="checkbox"]:checked + label::after,
.abc-checkbox-primary input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-danger input[type="checkbox"]:checked + label::before,
.abc-checkbox-danger input[type="radio"]:checked + label::before {
  background-color: #dc3545;
  border-color: #dc3545; }

.abc-checkbox-danger input[type="checkbox"]:checked + label::after,
.abc-checkbox-danger input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-info input[type="checkbox"]:checked + label::before,
.abc-checkbox-info input[type="radio"]:checked + label::before {
  background-color: #17a2b8;
  border-color: #17a2b8; }

.abc-checkbox-info input[type="checkbox"]:checked + label::after,
.abc-checkbox-info input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-warning input[type="checkbox"]:checked + label::before,
.abc-checkbox-warning input[type="radio"]:checked + label::before {
  background-color: #ffc107;
  border-color: #ffc107; }

.abc-checkbox-warning input[type="checkbox"]:checked + label::after,
.abc-checkbox-warning input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-success input[type="checkbox"]:checked + label::before,
.abc-checkbox-success input[type="radio"]:checked + label::before {
  background-color: #28a745;
  border-color: #28a745; }

.abc-checkbox-success input[type="checkbox"]:checked + label::after,
.abc-checkbox-success input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-primary input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-primary input[type="radio"]:indeterminate + label::before {
  background-color: #007bff;
  border-color: #007bff; }

.abc-checkbox-primary input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-primary input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-danger input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-danger input[type="radio"]:indeterminate + label::before {
  background-color: #dc3545;
  border-color: #dc3545; }

.abc-checkbox-danger input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-danger input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-info input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-info input[type="radio"]:indeterminate + label::before {
  background-color: #17a2b8;
  border-color: #17a2b8; }

.abc-checkbox-info input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-info input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-warning input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-warning input[type="radio"]:indeterminate + label::before {
  background-color: #ffc107;
  border-color: #ffc107; }

.abc-checkbox-warning input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-warning input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-success input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-success input[type="radio"]:indeterminate + label::before {
  background-color: #28a745;
  border-color: #28a745; }

.abc-checkbox-success input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-success input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-radio {
  cursor: default;
  padding-left: 4px; }
  .abc-radio label {
    cursor: pointer;
    display: inline;
    vertical-align: top;
    position: relative;
    padding-left: 5px; }
    .abc-radio label::before {
      cursor: pointer;
      content: "";
      display: inline-block;
      position: absolute;
      width: 17px;
      height: 17px;
      top: 2px;
      left: 0;
      margin-left: -20px;
      border: 1px solid #ced4da;
      border-radius: 50%;
      background-color: #fff;
      -webkit-transition: border 0.15s ease-in-out;
      transition: border 0.15s ease-in-out; }
    .abc-radio label::after {
      cursor: pointer;
      display: inline-block;
      position: absolute;
      content: " ";
      width: 11px;
      height: 11px;
      left: 3px;
      top: 5px;
      margin-left: -20px;
      border-radius: 50%;
      background-color: #495057;
      -webkit-transform: scale(0, 0);
              transform: scale(0, 0);
      -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33), -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33); }
  .abc-radio input[type="radio"] {
    position: static;
    margin-left: 0;
    cursor: pointer;
    opacity: 0;
    z-index: 1; }
    .abc-radio input[type="radio"]:focus + label::before {
      outline: thin dotted;
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px; }
    .abc-radio input[type="radio"]:checked + label::after {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1); }
    .abc-radio input[type="radio"]:disabled + label {
      opacity: 0.65; }
      .abc-radio input[type="radio"]:disabled + label::before {
        cursor: not-allowed; }
      .abc-radio input[type="radio"]:disabled + label::after {
        cursor: not-allowed; }
  .abc-radio.radio-inline {
    margin-top: 0; }

.abc-radio-primary input[type="radio"] + label::after {
  background-color: #007bff; }

.abc-radio-primary input[type="radio"]:checked + label::before {
  border-color: #007bff; }

.abc-radio-primary input[type="radio"]:checked + label::after {
  background-color: #007bff; }

.abc-radio-danger input[type="radio"] + label::after {
  background-color: #dc3545; }

.abc-radio-danger input[type="radio"]:checked + label::before {
  border-color: #dc3545; }

.abc-radio-danger input[type="radio"]:checked + label::after {
  background-color: #dc3545; }

.abc-radio-info input[type="radio"] + label::after {
  background-color: #17a2b8; }

.abc-radio-info input[type="radio"]:checked + label::before {
  border-color: #17a2b8; }

.abc-radio-info input[type="radio"]:checked + label::after {
  background-color: #17a2b8; }

.abc-radio-warning input[type="radio"] + label::after {
  background-color: #ffc107; }

.abc-radio-warning input[type="radio"]:checked + label::before {
  border-color: #ffc107; }

.abc-radio-warning input[type="radio"]:checked + label::after {
  background-color: #ffc107; }

.abc-radio-success input[type="radio"] + label::after {
  background-color: #28a745; }

.abc-radio-success input[type="radio"]:checked + label::before {
  border-color: #28a745; }

.abc-radio-success input[type="radio"]:checked + label::after {
  background-color: #28a745; }

label .was-validated .form-check-input:invalid .abc-checkbox:before, label
.was-validated .form-check-input:invalid .abc-radio:before, label .form-check-input.is-invalid .abc-checkbox:before, label
.form-check-input.is-invalid .abc-radio:before {
  border-color: #dc3545; }

@font-face {
  font-family: "Petasense Open Sans";
  src: url(/static/media/OpenSans-Regular_1.73d5e4b3.woff2); }

@font-face {
  font-family: "Petasense Open Sans";
  src: url(/static/media/OpenSans-Semibold_1.59d1fe1e.woff2);
  font-weight: 600; }

@font-face {
  font-family: 'Petasense Open Sans';
  src: url(/static/media/OpenSans-Bold_1.1b0edf91.woff2);
  font-weight: bold; }

.ps-hover-pointer:hover {
  cursor: pointer; }

.ps-hover-move {
  cursor: move; }

.hide {
  height: 0;
  width: 0;
  opacity: 0; }

.ps-logo-container {
  text-align: center;
  margin-top: 15vh;
  margin-bottom: 5vh; }
  .ps-logo-container img {
    width: 61%; }

.container.align-center {
  text-align: center; }

.container .ps-routes {
  width: 32%;
  margin: 0 auto; }

form.scrollable-form {
  overflow-y: scroll;
  max-height: 60vh; }
  form.scrollable-form::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(136, 134, 134, 0.925);
            box-shadow: inset 0 0 6px rgba(136, 134, 134, 0.925);
    background-color: #f5f5f5; }
  form.scrollable-form::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5; }
  form.scrollable-form::-webkit-scrollbar-thumb {
    background-color: #999b95; }

.ps-align-center {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }

.ps-align-center-column {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-direction: column;
      flex-direction: column; }

.list-error-message {
  color: #878983;
  padding-top: 20px;
  font-weight: bold; }
  .list-error-message.machine-list {
    border: 2px dashed #D8DCD3;
    padding: 20px;
    margin-top: 10px; }
  .list-error-message p {
    font-weight: 300; }

.top-padding-20 {
  padding-top: 20px; }

.small-row {
  width: 50%; }

.dropdown-title {
  max-width: 110px;
  white-space: normal !important; }

.help-block {
  color: #a94442;
  font-size: 12px;
  opacity: 0;
  margin: 0;
  max-height: 0;
  font-weight: bold;
  pointer-events: none;
  -webkit-transition: max-height 300ms ease, opacity 300ms ease;
  transition: max-height 300ms ease, opacity 300ms ease; }
  .help-block.onboarding-header {
    padding: 0px 0px 15px 0px; }
  .help-block.pop {
    opacity: 1;
    height: auto;
    margin-top: 5px;
    margin-bottom: 5px;
    max-height: 150px;
    -webkit-transition: max-height 300ms ease, opacity 300ms ease;
    transition: max-height 300ms ease, opacity 300ms ease; }

.ps-vertical-center {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

input.error {
  color: #a94442; }

.ps-help-block {
  color: #a94442;
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: bold;
  height: 0; }

.ps-horizontal-center {
  text-align: center; }

.ps-card {
  -webkit-transition: -webkit-box-shadow 0.2s;
  transition: -webkit-box-shadow 0.2s;
  transition: box-shadow 0.2s;
  transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0), 0 3px 6px rgba(0, 0, 0, 0);
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0), 0 3px 6px rgba(0, 0, 0, 0);
  background: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  display: inline-block;
  margin: 1vh auto;
  padding: 1.5vh;
  position: relative;
  text-align: center;
  width: 90%;
  height: 70px; }
  .ps-card:hover {
    cursor: pointer; }
  .ps-card:hover {
    -webkit-box-shadow: 0 4px 12px rgba(33, 33, 33, 0.3), 0 4px 12px rgba(0, 0, 0, 0.3);
            box-shadow: 0 4px 12px rgba(33, 33, 33, 0.3), 0 4px 12px rgba(0, 0, 0, 0.3); }
  .ps-card.active {
    border-color: #91b846;
    color: #91b846; }
    .ps-card.active:hover {
      -webkit-box-shadow: 0 5px 14px rgba(145, 184, 70, 0.8), 0 5px 14px rgba(145, 184, 70, 0.8);
              box-shadow: 0 5px 14px rgba(145, 184, 70, 0.8), 0 5px 14px rgba(145, 184, 70, 0.8);
      cursor: pointer; }
  .ps-card .title {
    text-align: left;
    margin: auto;
    font-size: 95%;
    font-weight: bold; }

.abc-checkbox {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .abc-checkbox label {
    font-family: "Petasense Open Sans";
    font-size: 16px;
    font-weight: normal;
    margin: 0px 10px 0px 19px; }
    .abc-checkbox label:after {
      color: #91b846;
      margin-left: -23px; }
    .abc-checkbox label:before {
      border-radius: 0px;
      margin-left: -23px;
      top: 2px; }

.full-screen-center {
  position: absolute;
  top: 50%;
  left: 50%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }

.fill-remaining-space {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-positive: 1;
      flex-grow: 1; }

.btn {
  height: 35px;
  font-size: 16px;
  margin: 3px 0;
  border-width: 0;
  width: 100%; }
  .btn.btn-primary {
    font-family: "Petasense Open Sans";
    background-color: #91b846;
    border-radius: 0px;
    color: white;
    margin-bottom: 10px; }
    .btn.btn-primary:hover, .btn.btn-primary:focus {
      background-color: #91b846; }
  .btn.btn-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    font-family: "Petasense Open Sans";
    background-color: "transparent";
    color: #91b846;
    -ms-flex-pack: center;
        justify-content: center; }

/**
------------------------------------------------------- Machine Severity CSS ------------------------------------------------------
 */
.ps-good-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-good-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-good-health:before {
    background: #91B846; }

.ps-slight-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-slight-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-slight-health:before {
    background: #4E89F4; }

.ps-moderate-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-moderate-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-moderate-health:before {
    background: #E4BE0C; }

.ps-warning-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-warning-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-warning-health:before {
    background: #F38F0A; }

.ps-critical-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-critical-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-critical-health:before {
    background: #E61111; }

.ps-unknown-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-unknown-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-unknown-health:before {
    background: #d3d3d3; }

.machine-condition-devider {
  height: 16px;
  width: 1px;
  background-color: #d8d8d6;
  margin: 0px 30px;
  visibility: hidden; }

.fixed-bar {
  position: fixed;
  background: #ffffff;
  width: 100%;
  z-index: 2;
  top: 0px; }

.ui.segment {
  border: none;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin: 0px;
  -webkit-box-shadow: none;
          box-shadow: none; }

.ui.grid > .row {
  padding-left: 1.1em;
  padding-top: 1rem;
  padding-bottom: 1rem; }

.custom-grid {
  border: 2px solid #f0f0f0;
  padding: 20px;
  width: 100%; }

.comment-modal-main {
  padding-top: 20px;
  padding-bottom: 15px;
  display: -ms-flexbox;
  display: flex; }

.comment-modal-chart-container {
  width: 70%; }

.comment-modal-comment-container {
  width: 30%;
  -ms-flex-direction: column;
      flex-direction: column;
  padding-left: 18px;
  padding-right: 30px; }
  .comment-modal-comment-container > div.form-group {
    height: 120px;
    padding-top: 22px;
    margin-bottom: 12px; }
  .comment-modal-comment-container .submit-button-container {
    text-align: right; }
    .comment-modal-comment-container .submit-button-container > button {
      width: 100px;
      height: 30px;
      padding: 0px; }

/**
 -- used http://chir.ag/projects/name-that-color/ to name color
*/
.abc-checkbox {
  cursor: default;
  padding-left: 4px; }
  .abc-checkbox label {
    cursor: pointer;
    display: inline;
    vertical-align: top;
    position: relative;
    padding-left: 5px; }
    .abc-checkbox label::before {
      cursor: pointer;
      content: "";
      display: inline-block;
      position: absolute;
      width: 17px;
      height: 17px;
      top: 2px;
      left: 0;
      margin-left: -1.25rem;
      border: 1px solid #ced4da;
      border-radius: 3px;
      background-color: #fff;
      -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
      transition: border 0.15s ease-in-out, color 0.15s ease-in-out; }
    .abc-checkbox label::after {
      cursor: pointer;
      display: inline-block;
      position: absolute;
      width: 16px;
      height: 16px;
      left: 0;
      top: 2px;
      margin-left: -1.25rem;
      padding-left: 3px;
      padding-top: 1px;
      font-size: 11px;
      color: #495057; }
  .abc-checkbox input[type="checkbox"],
  .abc-checkbox input[type="radio"] {
    position: static;
    margin-left: 0;
    cursor: pointer;
    opacity: 0;
    z-index: 1; }
    .abc-checkbox input[type="checkbox"]:focus + label::before,
    .abc-checkbox input[type="radio"]:focus + label::before {
      outline: thin dotted;
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px; }
    .abc-checkbox input[type="checkbox"]:checked + label::after,
    .abc-checkbox input[type="radio"]:checked + label::after {
      font-family: "FontAwesome";
      content: "\F00C"; }
    .abc-checkbox input[type="checkbox"]:indeterminate + label::after,
    .abc-checkbox input[type="radio"]:indeterminate + label::after {
      display: block;
      content: "";
      width: 10px;
      height: 3px;
      background-color: #555555;
      border-radius: 2px;
      margin-left: -16.5px;
      margin-top: 7px; }
    .abc-checkbox input[type="checkbox"]:disabled + label,
    .abc-checkbox input[type="radio"]:disabled + label {
      opacity: 0.65; }
      .abc-checkbox input[type="checkbox"]:disabled + label::before,
      .abc-checkbox input[type="radio"]:disabled + label::before {
        background-color: #e9ecef;
        cursor: not-allowed; }
      .abc-checkbox input[type="checkbox"]:disabled + label::after,
      .abc-checkbox input[type="radio"]:disabled + label::after {
        cursor: not-allowed; }
  .abc-checkbox.abc-checkbox-circle label::before {
    border-radius: 50%; }
  .abc-checkbox.checkbox-inline {
    margin-top: 0; }

.abc-checkbox-primary input[type="checkbox"]:checked + label::before,
.abc-checkbox-primary input[type="radio"]:checked + label::before {
  background-color: #007bff;
  border-color: #007bff; }

.abc-checkbox-primary input[type="checkbox"]:checked + label::after,
.abc-checkbox-primary input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-danger input[type="checkbox"]:checked + label::before,
.abc-checkbox-danger input[type="radio"]:checked + label::before {
  background-color: #dc3545;
  border-color: #dc3545; }

.abc-checkbox-danger input[type="checkbox"]:checked + label::after,
.abc-checkbox-danger input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-info input[type="checkbox"]:checked + label::before,
.abc-checkbox-info input[type="radio"]:checked + label::before {
  background-color: #17a2b8;
  border-color: #17a2b8; }

.abc-checkbox-info input[type="checkbox"]:checked + label::after,
.abc-checkbox-info input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-warning input[type="checkbox"]:checked + label::before,
.abc-checkbox-warning input[type="radio"]:checked + label::before {
  background-color: #ffc107;
  border-color: #ffc107; }

.abc-checkbox-warning input[type="checkbox"]:checked + label::after,
.abc-checkbox-warning input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-success input[type="checkbox"]:checked + label::before,
.abc-checkbox-success input[type="radio"]:checked + label::before {
  background-color: #28a745;
  border-color: #28a745; }

.abc-checkbox-success input[type="checkbox"]:checked + label::after,
.abc-checkbox-success input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-primary input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-primary input[type="radio"]:indeterminate + label::before {
  background-color: #007bff;
  border-color: #007bff; }

.abc-checkbox-primary input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-primary input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-danger input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-danger input[type="radio"]:indeterminate + label::before {
  background-color: #dc3545;
  border-color: #dc3545; }

.abc-checkbox-danger input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-danger input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-info input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-info input[type="radio"]:indeterminate + label::before {
  background-color: #17a2b8;
  border-color: #17a2b8; }

.abc-checkbox-info input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-info input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-warning input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-warning input[type="radio"]:indeterminate + label::before {
  background-color: #ffc107;
  border-color: #ffc107; }

.abc-checkbox-warning input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-warning input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-success input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-success input[type="radio"]:indeterminate + label::before {
  background-color: #28a745;
  border-color: #28a745; }

.abc-checkbox-success input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-success input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-radio {
  cursor: default;
  padding-left: 4px; }
  .abc-radio label {
    cursor: pointer;
    display: inline;
    vertical-align: top;
    position: relative;
    padding-left: 5px; }
    .abc-radio label::before {
      cursor: pointer;
      content: "";
      display: inline-block;
      position: absolute;
      width: 17px;
      height: 17px;
      top: 2px;
      left: 0;
      margin-left: -20px;
      border: 1px solid #ced4da;
      border-radius: 50%;
      background-color: #fff;
      -webkit-transition: border 0.15s ease-in-out;
      transition: border 0.15s ease-in-out; }
    .abc-radio label::after {
      cursor: pointer;
      display: inline-block;
      position: absolute;
      content: " ";
      width: 11px;
      height: 11px;
      left: 3px;
      top: 5px;
      margin-left: -20px;
      border-radius: 50%;
      background-color: #495057;
      -webkit-transform: scale(0, 0);
              transform: scale(0, 0);
      -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33), -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33); }
  .abc-radio input[type="radio"] {
    position: static;
    margin-left: 0;
    cursor: pointer;
    opacity: 0;
    z-index: 1; }
    .abc-radio input[type="radio"]:focus + label::before {
      outline: thin dotted;
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px; }
    .abc-radio input[type="radio"]:checked + label::after {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1); }
    .abc-radio input[type="radio"]:disabled + label {
      opacity: 0.65; }
      .abc-radio input[type="radio"]:disabled + label::before {
        cursor: not-allowed; }
      .abc-radio input[type="radio"]:disabled + label::after {
        cursor: not-allowed; }
  .abc-radio.radio-inline {
    margin-top: 0; }

.abc-radio-primary input[type="radio"] + label::after {
  background-color: #007bff; }

.abc-radio-primary input[type="radio"]:checked + label::before {
  border-color: #007bff; }

.abc-radio-primary input[type="radio"]:checked + label::after {
  background-color: #007bff; }

.abc-radio-danger input[type="radio"] + label::after {
  background-color: #dc3545; }

.abc-radio-danger input[type="radio"]:checked + label::before {
  border-color: #dc3545; }

.abc-radio-danger input[type="radio"]:checked + label::after {
  background-color: #dc3545; }

.abc-radio-info input[type="radio"] + label::after {
  background-color: #17a2b8; }

.abc-radio-info input[type="radio"]:checked + label::before {
  border-color: #17a2b8; }

.abc-radio-info input[type="radio"]:checked + label::after {
  background-color: #17a2b8; }

.abc-radio-warning input[type="radio"] + label::after {
  background-color: #ffc107; }

.abc-radio-warning input[type="radio"]:checked + label::before {
  border-color: #ffc107; }

.abc-radio-warning input[type="radio"]:checked + label::after {
  background-color: #ffc107; }

.abc-radio-success input[type="radio"] + label::after {
  background-color: #28a745; }

.abc-radio-success input[type="radio"]:checked + label::before {
  border-color: #28a745; }

.abc-radio-success input[type="radio"]:checked + label::after {
  background-color: #28a745; }

label .was-validated .form-check-input:invalid .abc-checkbox:before, label
.was-validated .form-check-input:invalid .abc-radio:before, label .form-check-input.is-invalid .abc-checkbox:before, label
.form-check-input.is-invalid .abc-radio:before {
  border-color: #dc3545; }

@font-face {
  font-family: "Petasense Open Sans";
  src: url(/static/media/OpenSans-Regular_1.73d5e4b3.woff2); }

@font-face {
  font-family: "Petasense Open Sans";
  src: url(/static/media/OpenSans-Semibold_1.59d1fe1e.woff2);
  font-weight: 600; }

@font-face {
  font-family: 'Petasense Open Sans';
  src: url(/static/media/OpenSans-Bold_1.1b0edf91.woff2);
  font-weight: bold; }

.ps-hover-pointer:hover {
  cursor: pointer; }

.ps-hover-move {
  cursor: move; }

.hide {
  height: 0;
  width: 0;
  opacity: 0; }

.ps-logo-container {
  text-align: center;
  margin-top: 15vh;
  margin-bottom: 5vh; }
  .ps-logo-container img {
    width: 61%; }

.container.align-center {
  text-align: center; }

.container .ps-routes {
  width: 32%;
  margin: 0 auto; }

form.scrollable-form {
  overflow-y: scroll;
  max-height: 60vh; }
  form.scrollable-form::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(136, 134, 134, 0.925);
            box-shadow: inset 0 0 6px rgba(136, 134, 134, 0.925);
    background-color: #f5f5f5; }
  form.scrollable-form::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5; }
  form.scrollable-form::-webkit-scrollbar-thumb {
    background-color: #999b95; }

.ps-align-center {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }

.ps-align-center-column {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-direction: column;
      flex-direction: column; }

.list-error-message {
  color: #878983;
  padding-top: 20px;
  font-weight: bold; }
  .list-error-message.machine-list {
    border: 2px dashed #D8DCD3;
    padding: 20px;
    margin-top: 10px; }
  .list-error-message p {
    font-weight: 300; }

.top-padding-20 {
  padding-top: 20px; }

.small-row {
  width: 50%; }

.dropdown-title {
  max-width: 110px;
  white-space: normal !important; }

.help-block {
  color: #a94442;
  font-size: 12px;
  opacity: 0;
  margin: 0;
  max-height: 0;
  font-weight: bold;
  pointer-events: none;
  -webkit-transition: max-height 300ms ease, opacity 300ms ease;
  transition: max-height 300ms ease, opacity 300ms ease; }
  .help-block.onboarding-header {
    padding: 0px 0px 15px 0px; }
  .help-block.pop {
    opacity: 1;
    height: auto;
    margin-top: 5px;
    margin-bottom: 5px;
    max-height: 150px;
    -webkit-transition: max-height 300ms ease, opacity 300ms ease;
    transition: max-height 300ms ease, opacity 300ms ease; }

.ps-vertical-center {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

input.error {
  color: #a94442; }

.ps-help-block {
  color: #a94442;
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: bold;
  height: 0; }

.ps-horizontal-center {
  text-align: center; }

.ps-card {
  -webkit-transition: -webkit-box-shadow 0.2s;
  transition: -webkit-box-shadow 0.2s;
  transition: box-shadow 0.2s;
  transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0), 0 3px 6px rgba(0, 0, 0, 0);
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0), 0 3px 6px rgba(0, 0, 0, 0);
  background: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  display: inline-block;
  margin: 1vh auto;
  padding: 1.5vh;
  position: relative;
  text-align: center;
  width: 90%;
  height: 70px; }
  .ps-card:hover {
    cursor: pointer; }
  .ps-card:hover {
    -webkit-box-shadow: 0 4px 12px rgba(33, 33, 33, 0.3), 0 4px 12px rgba(0, 0, 0, 0.3);
            box-shadow: 0 4px 12px rgba(33, 33, 33, 0.3), 0 4px 12px rgba(0, 0, 0, 0.3); }
  .ps-card.active {
    border-color: #91b846;
    color: #91b846; }
    .ps-card.active:hover {
      -webkit-box-shadow: 0 5px 14px rgba(145, 184, 70, 0.8), 0 5px 14px rgba(145, 184, 70, 0.8);
              box-shadow: 0 5px 14px rgba(145, 184, 70, 0.8), 0 5px 14px rgba(145, 184, 70, 0.8);
      cursor: pointer; }
  .ps-card .title {
    text-align: left;
    margin: auto;
    font-size: 95%;
    font-weight: bold; }

.abc-checkbox {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .abc-checkbox label {
    font-family: "Petasense Open Sans";
    font-size: 16px;
    font-weight: normal;
    margin: 0px 10px 0px 19px; }
    .abc-checkbox label:after {
      color: #91b846;
      margin-left: -23px; }
    .abc-checkbox label:before {
      border-radius: 0px;
      margin-left: -23px;
      top: 2px; }

.full-screen-center {
  position: absolute;
  top: 50%;
  left: 50%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }

.fill-remaining-space {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-positive: 1;
      flex-grow: 1; }

.btn {
  height: 35px;
  font-size: 16px;
  margin: 3px 0;
  border-width: 0;
  width: 100%; }
  .btn.btn-primary {
    font-family: "Petasense Open Sans";
    background-color: #91b846;
    border-radius: 0px;
    color: white;
    margin-bottom: 10px; }
    .btn.btn-primary:hover, .btn.btn-primary:focus {
      background-color: #91b846; }
  .btn.btn-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    font-family: "Petasense Open Sans";
    background-color: "transparent";
    color: #91b846;
    -ms-flex-pack: center;
        justify-content: center; }

/**
------------------------------------------------------- Machine Severity CSS ------------------------------------------------------
 */
.ps-good-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-good-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-good-health:before {
    background: #91B846; }

.ps-slight-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-slight-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-slight-health:before {
    background: #4E89F4; }

.ps-moderate-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-moderate-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-moderate-health:before {
    background: #E4BE0C; }

.ps-warning-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-warning-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-warning-health:before {
    background: #F38F0A; }

.ps-critical-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-critical-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-critical-health:before {
    background: #E61111; }

.ps-unknown-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-unknown-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-unknown-health:before {
    background: #d3d3d3; }

.machine-condition-devider {
  height: 16px;
  width: 1px;
  background-color: #d8d8d6;
  margin: 0px 30px;
  visibility: hidden; }

.fixed-bar {
  position: fixed;
  background: #ffffff;
  width: 100%;
  z-index: 2;
  top: 0px; }

.ui.segment {
  border: none;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin: 0px;
  -webkit-box-shadow: none;
          box-shadow: none; }

.ui.grid > .row {
  padding-left: 1.1em;
  padding-top: 1rem;
  padding-bottom: 1rem; }

.custom-grid {
  border: 2px solid #f0f0f0;
  padding: 20px;
  width: 100%; }

.feature-modal-main {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

.feature-modal-chart-container {
  height: 250px; }

.feature-modal-feature-container {
  height: auto; }
  .feature-modal-feature-container .ui.tabular.menu {
    min-height: auto;
    padding-left: 50px;
    margin-top: 30px;
    left: 0px;
    right: 0px;
    font-family: "Petasense Open Sans"; }
    .feature-modal-feature-container .ui.tabular.menu .item {
      color: #333;
      font-size: 14px;
      font-weight: normal;
      padding-top: 9px;
      padding-bottom: 9px; }
      .feature-modal-feature-container .ui.tabular.menu .item.active {
        color: #91b846; }
  .feature-modal-feature-container .feature-form {
    padding: 0px 0px 36px 47px; }
    .feature-modal-feature-container .feature-form .feature-form-row {
      display: -ms-flexbox;
      display: flex; }
      .feature-modal-feature-container .feature-form .feature-form-row .feature-form-element {
        margin-right: 16px; }
        .feature-modal-feature-container .feature-form .feature-form-row .feature-form-element .form-control {
          -webkit-box-shadow: none;
                  box-shadow: none; }
        .feature-modal-feature-container .feature-form .feature-form-row .feature-form-element .ui.dropdown > .text {
          font-weight: 600; }
        .feature-modal-feature-container .feature-form .feature-form-row .feature-form-element input {
          height: 38px;
          border: 1px solid #eeeeee;
          border-radius: 4px;
          font-family: "Petasense Open Sans";
          font-weight: 600; }
        .feature-modal-feature-container .feature-form .feature-form-row .feature-form-element .feature-additional-option-container {
          display: -ms-flexbox;
          display: flex;
          height: 38px; }
          .feature-modal-feature-container .feature-form .feature-form-row .feature-form-element .feature-additional-option-container label {
            font-size: 14px;
            font-weight: 600; }
        .feature-modal-feature-container .feature-form .feature-form-row .feature-form-element.first-column {
          width: 260px; }
          .feature-modal-feature-container .feature-form .feature-form-row .feature-form-element.first-column .ui.selection.dropdown {
            width: 100%;
            border-color: #eeeeee; }
          .feature-modal-feature-container .feature-form .feature-form-row .feature-form-element.first-column input {
            width: 100%; }
        .feature-modal-feature-container .feature-form .feature-form-row .feature-form-element.third-row {
          width: 100%; }
          .feature-modal-feature-container .feature-form .feature-form-row .feature-form-element.third-row input {
            width: 100%; }
        .feature-modal-feature-container .feature-form .feature-form-row .feature-form-element .feature-label {
          white-space: nowrap;
          margin: 12px 0px;
          font-size: 10px;
          color: #afafaf;
          font-weight: bold; }
        .feature-modal-feature-container .feature-form .feature-form-row .feature-form-element .frequency-input-container {
          display: -ms-flexbox;
          display: flex;
          margin-right: 16px;
          color: #5a5a5a; }
          .feature-modal-feature-container .feature-form .feature-form-row .feature-form-element .frequency-input-container span {
            padding-right: 16px;
            height: 38px;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-align: center;
                align-items: center;
            font-weight: 600; }
          .feature-modal-feature-container .feature-form .feature-form-row .feature-form-element .frequency-input-container .ui.label {
            color: #afafaf;
            background-color: #f7f7f7;
            font-weight: 600;
            height: 38px; }
          .feature-modal-feature-container .feature-form .feature-form-row .feature-form-element .frequency-input-container .frequency-input {
            width: 100px;
            min-width: 50px; }

.ps-feature-segment {
  pointer-events: none; }
  .ps-feature-segment .feature-rect {
    fill: rgba(216, 216, 216, 0.16); }
  .ps-feature-segment .feature-line {
    stroke: #afafaf;
    width: 2; }

.ps-feature-segment.highlight {
  pointer-events: none; }
  .ps-feature-segment.highlight .feature-rect {
    fill: rgba(216, 216, 216, 0.32); }
  .ps-feature-segment.highlight .feature-line {
    stroke: #6f6f6f;
    width: 3; }

.feature-trash-container {
  -ms-flex-positive: 1;
      flex-grow: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: end;
      justify-content: flex-end;
  padding-right: 15px; }
  .feature-trash-container a {
    cursor: pointer; }
    .feature-trash-container a svg > g > g {
      fill: #999b95; }

/**
 -- used http://chir.ag/projects/name-that-color/ to name color
*/
.abc-checkbox {
  cursor: default;
  padding-left: 4px; }
  .abc-checkbox label {
    cursor: pointer;
    display: inline;
    vertical-align: top;
    position: relative;
    padding-left: 5px; }
    .abc-checkbox label::before {
      cursor: pointer;
      content: "";
      display: inline-block;
      position: absolute;
      width: 17px;
      height: 17px;
      top: 2px;
      left: 0;
      margin-left: -1.25rem;
      border: 1px solid #ced4da;
      border-radius: 3px;
      background-color: #fff;
      -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
      transition: border 0.15s ease-in-out, color 0.15s ease-in-out; }
    .abc-checkbox label::after {
      cursor: pointer;
      display: inline-block;
      position: absolute;
      width: 16px;
      height: 16px;
      left: 0;
      top: 2px;
      margin-left: -1.25rem;
      padding-left: 3px;
      padding-top: 1px;
      font-size: 11px;
      color: #495057; }
  .abc-checkbox input[type="checkbox"],
  .abc-checkbox input[type="radio"] {
    position: static;
    margin-left: 0;
    cursor: pointer;
    opacity: 0;
    z-index: 1; }
    .abc-checkbox input[type="checkbox"]:focus + label::before,
    .abc-checkbox input[type="radio"]:focus + label::before {
      outline: thin dotted;
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px; }
    .abc-checkbox input[type="checkbox"]:checked + label::after,
    .abc-checkbox input[type="radio"]:checked + label::after {
      font-family: "FontAwesome";
      content: "\F00C"; }
    .abc-checkbox input[type="checkbox"]:indeterminate + label::after,
    .abc-checkbox input[type="radio"]:indeterminate + label::after {
      display: block;
      content: "";
      width: 10px;
      height: 3px;
      background-color: #555555;
      border-radius: 2px;
      margin-left: -16.5px;
      margin-top: 7px; }
    .abc-checkbox input[type="checkbox"]:disabled + label,
    .abc-checkbox input[type="radio"]:disabled + label {
      opacity: 0.65; }
      .abc-checkbox input[type="checkbox"]:disabled + label::before,
      .abc-checkbox input[type="radio"]:disabled + label::before {
        background-color: #e9ecef;
        cursor: not-allowed; }
      .abc-checkbox input[type="checkbox"]:disabled + label::after,
      .abc-checkbox input[type="radio"]:disabled + label::after {
        cursor: not-allowed; }
  .abc-checkbox.abc-checkbox-circle label::before {
    border-radius: 50%; }
  .abc-checkbox.checkbox-inline {
    margin-top: 0; }

.abc-checkbox-primary input[type="checkbox"]:checked + label::before,
.abc-checkbox-primary input[type="radio"]:checked + label::before {
  background-color: #007bff;
  border-color: #007bff; }

.abc-checkbox-primary input[type="checkbox"]:checked + label::after,
.abc-checkbox-primary input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-danger input[type="checkbox"]:checked + label::before,
.abc-checkbox-danger input[type="radio"]:checked + label::before {
  background-color: #dc3545;
  border-color: #dc3545; }

.abc-checkbox-danger input[type="checkbox"]:checked + label::after,
.abc-checkbox-danger input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-info input[type="checkbox"]:checked + label::before,
.abc-checkbox-info input[type="radio"]:checked + label::before {
  background-color: #17a2b8;
  border-color: #17a2b8; }

.abc-checkbox-info input[type="checkbox"]:checked + label::after,
.abc-checkbox-info input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-warning input[type="checkbox"]:checked + label::before,
.abc-checkbox-warning input[type="radio"]:checked + label::before {
  background-color: #ffc107;
  border-color: #ffc107; }

.abc-checkbox-warning input[type="checkbox"]:checked + label::after,
.abc-checkbox-warning input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-success input[type="checkbox"]:checked + label::before,
.abc-checkbox-success input[type="radio"]:checked + label::before {
  background-color: #28a745;
  border-color: #28a745; }

.abc-checkbox-success input[type="checkbox"]:checked + label::after,
.abc-checkbox-success input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-primary input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-primary input[type="radio"]:indeterminate + label::before {
  background-color: #007bff;
  border-color: #007bff; }

.abc-checkbox-primary input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-primary input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-danger input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-danger input[type="radio"]:indeterminate + label::before {
  background-color: #dc3545;
  border-color: #dc3545; }

.abc-checkbox-danger input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-danger input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-info input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-info input[type="radio"]:indeterminate + label::before {
  background-color: #17a2b8;
  border-color: #17a2b8; }

.abc-checkbox-info input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-info input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-warning input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-warning input[type="radio"]:indeterminate + label::before {
  background-color: #ffc107;
  border-color: #ffc107; }

.abc-checkbox-warning input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-warning input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-success input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-success input[type="radio"]:indeterminate + label::before {
  background-color: #28a745;
  border-color: #28a745; }

.abc-checkbox-success input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-success input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-radio {
  cursor: default;
  padding-left: 4px; }
  .abc-radio label {
    cursor: pointer;
    display: inline;
    vertical-align: top;
    position: relative;
    padding-left: 5px; }
    .abc-radio label::before {
      cursor: pointer;
      content: "";
      display: inline-block;
      position: absolute;
      width: 17px;
      height: 17px;
      top: 2px;
      left: 0;
      margin-left: -20px;
      border: 1px solid #ced4da;
      border-radius: 50%;
      background-color: #fff;
      -webkit-transition: border 0.15s ease-in-out;
      transition: border 0.15s ease-in-out; }
    .abc-radio label::after {
      cursor: pointer;
      display: inline-block;
      position: absolute;
      content: " ";
      width: 11px;
      height: 11px;
      left: 3px;
      top: 5px;
      margin-left: -20px;
      border-radius: 50%;
      background-color: #495057;
      -webkit-transform: scale(0, 0);
              transform: scale(0, 0);
      -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33), -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33); }
  .abc-radio input[type="radio"] {
    position: static;
    margin-left: 0;
    cursor: pointer;
    opacity: 0;
    z-index: 1; }
    .abc-radio input[type="radio"]:focus + label::before {
      outline: thin dotted;
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px; }
    .abc-radio input[type="radio"]:checked + label::after {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1); }
    .abc-radio input[type="radio"]:disabled + label {
      opacity: 0.65; }
      .abc-radio input[type="radio"]:disabled + label::before {
        cursor: not-allowed; }
      .abc-radio input[type="radio"]:disabled + label::after {
        cursor: not-allowed; }
  .abc-radio.radio-inline {
    margin-top: 0; }

.abc-radio-primary input[type="radio"] + label::after {
  background-color: #007bff; }

.abc-radio-primary input[type="radio"]:checked + label::before {
  border-color: #007bff; }

.abc-radio-primary input[type="radio"]:checked + label::after {
  background-color: #007bff; }

.abc-radio-danger input[type="radio"] + label::after {
  background-color: #dc3545; }

.abc-radio-danger input[type="radio"]:checked + label::before {
  border-color: #dc3545; }

.abc-radio-danger input[type="radio"]:checked + label::after {
  background-color: #dc3545; }

.abc-radio-info input[type="radio"] + label::after {
  background-color: #17a2b8; }

.abc-radio-info input[type="radio"]:checked + label::before {
  border-color: #17a2b8; }

.abc-radio-info input[type="radio"]:checked + label::after {
  background-color: #17a2b8; }

.abc-radio-warning input[type="radio"] + label::after {
  background-color: #ffc107; }

.abc-radio-warning input[type="radio"]:checked + label::before {
  border-color: #ffc107; }

.abc-radio-warning input[type="radio"]:checked + label::after {
  background-color: #ffc107; }

.abc-radio-success input[type="radio"] + label::after {
  background-color: #28a745; }

.abc-radio-success input[type="radio"]:checked + label::before {
  border-color: #28a745; }

.abc-radio-success input[type="radio"]:checked + label::after {
  background-color: #28a745; }

label .was-validated .form-check-input:invalid .abc-checkbox:before, label
.was-validated .form-check-input:invalid .abc-radio:before, label .form-check-input.is-invalid .abc-checkbox:before, label
.form-check-input.is-invalid .abc-radio:before {
  border-color: #dc3545; }

@font-face {
  font-family: "Petasense Open Sans";
  src: url(/static/media/OpenSans-Regular_1.73d5e4b3.woff2); }

@font-face {
  font-family: "Petasense Open Sans";
  src: url(/static/media/OpenSans-Semibold_1.59d1fe1e.woff2);
  font-weight: 600; }

@font-face {
  font-family: 'Petasense Open Sans';
  src: url(/static/media/OpenSans-Bold_1.1b0edf91.woff2);
  font-weight: bold; }

.ps-hover-pointer:hover {
  cursor: pointer; }

.ps-hover-move {
  cursor: move; }

.hide {
  height: 0;
  width: 0;
  opacity: 0; }

.ps-logo-container {
  text-align: center;
  margin-top: 15vh;
  margin-bottom: 5vh; }
  .ps-logo-container img {
    width: 61%; }

.container.align-center {
  text-align: center; }

.container .ps-routes {
  width: 32%;
  margin: 0 auto; }

form.scrollable-form {
  overflow-y: scroll;
  max-height: 60vh; }
  form.scrollable-form::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(136, 134, 134, 0.925);
            box-shadow: inset 0 0 6px rgba(136, 134, 134, 0.925);
    background-color: #f5f5f5; }
  form.scrollable-form::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5; }
  form.scrollable-form::-webkit-scrollbar-thumb {
    background-color: #999b95; }

.ps-align-center {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }

.ps-align-center-column {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-direction: column;
      flex-direction: column; }

.list-error-message {
  color: #878983;
  padding-top: 20px;
  font-weight: bold; }
  .list-error-message.machine-list {
    border: 2px dashed #D8DCD3;
    padding: 20px;
    margin-top: 10px; }
  .list-error-message p {
    font-weight: 300; }

.top-padding-20 {
  padding-top: 20px; }

.small-row {
  width: 50%; }

.dropdown-title {
  max-width: 110px;
  white-space: normal !important; }

.help-block {
  color: #a94442;
  font-size: 12px;
  opacity: 0;
  margin: 0;
  max-height: 0;
  font-weight: bold;
  pointer-events: none;
  -webkit-transition: max-height 300ms ease, opacity 300ms ease;
  transition: max-height 300ms ease, opacity 300ms ease; }
  .help-block.onboarding-header {
    padding: 0px 0px 15px 0px; }
  .help-block.pop {
    opacity: 1;
    height: auto;
    margin-top: 5px;
    margin-bottom: 5px;
    max-height: 150px;
    -webkit-transition: max-height 300ms ease, opacity 300ms ease;
    transition: max-height 300ms ease, opacity 300ms ease; }

.ps-vertical-center {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

input.error {
  color: #a94442; }

.ps-help-block {
  color: #a94442;
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: bold;
  height: 0; }

.ps-horizontal-center {
  text-align: center; }

.ps-card {
  -webkit-transition: -webkit-box-shadow 0.2s;
  transition: -webkit-box-shadow 0.2s;
  transition: box-shadow 0.2s;
  transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0), 0 3px 6px rgba(0, 0, 0, 0);
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0), 0 3px 6px rgba(0, 0, 0, 0);
  background: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  display: inline-block;
  margin: 1vh auto;
  padding: 1.5vh;
  position: relative;
  text-align: center;
  width: 90%;
  height: 70px; }
  .ps-card:hover {
    cursor: pointer; }
  .ps-card:hover {
    -webkit-box-shadow: 0 4px 12px rgba(33, 33, 33, 0.3), 0 4px 12px rgba(0, 0, 0, 0.3);
            box-shadow: 0 4px 12px rgba(33, 33, 33, 0.3), 0 4px 12px rgba(0, 0, 0, 0.3); }
  .ps-card.active {
    border-color: #91b846;
    color: #91b846; }
    .ps-card.active:hover {
      -webkit-box-shadow: 0 5px 14px rgba(145, 184, 70, 0.8), 0 5px 14px rgba(145, 184, 70, 0.8);
              box-shadow: 0 5px 14px rgba(145, 184, 70, 0.8), 0 5px 14px rgba(145, 184, 70, 0.8);
      cursor: pointer; }
  .ps-card .title {
    text-align: left;
    margin: auto;
    font-size: 95%;
    font-weight: bold; }

.abc-checkbox {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .abc-checkbox label {
    font-family: "Petasense Open Sans";
    font-size: 16px;
    font-weight: normal;
    margin: 0px 10px 0px 19px; }
    .abc-checkbox label:after {
      color: #91b846;
      margin-left: -23px; }
    .abc-checkbox label:before {
      border-radius: 0px;
      margin-left: -23px;
      top: 2px; }

.full-screen-center {
  position: absolute;
  top: 50%;
  left: 50%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }

.fill-remaining-space {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-positive: 1;
      flex-grow: 1; }

.btn {
  height: 35px;
  font-size: 16px;
  margin: 3px 0;
  border-width: 0;
  width: 100%; }
  .btn.btn-primary {
    font-family: "Petasense Open Sans";
    background-color: #91b846;
    border-radius: 0px;
    color: white;
    margin-bottom: 10px; }
    .btn.btn-primary:hover, .btn.btn-primary:focus {
      background-color: #91b846; }
  .btn.btn-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    font-family: "Petasense Open Sans";
    background-color: "transparent";
    color: #91b846;
    -ms-flex-pack: center;
        justify-content: center; }

/**
------------------------------------------------------- Machine Severity CSS ------------------------------------------------------
 */
.ps-good-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-good-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-good-health:before {
    background: #91B846; }

.ps-slight-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-slight-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-slight-health:before {
    background: #4E89F4; }

.ps-moderate-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-moderate-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-moderate-health:before {
    background: #E4BE0C; }

.ps-warning-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-warning-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-warning-health:before {
    background: #F38F0A; }

.ps-critical-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-critical-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-critical-health:before {
    background: #E61111; }

.ps-unknown-health {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .ps-unknown-health:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px; }
  .ps-unknown-health:before {
    background: #d3d3d3; }

.machine-condition-devider {
  height: 16px;
  width: 1px;
  background-color: #d8d8d6;
  margin: 0px 30px;
  visibility: hidden; }

.fixed-bar {
  position: fixed;
  background: #ffffff;
  width: 100%;
  z-index: 2;
  top: 0px; }

.ui.segment {
  border: none;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin: 0px;
  -webkit-box-shadow: none;
          box-shadow: none; }

.ui.grid > .row {
  padding-left: 1.1em;
  padding-top: 1rem;
  padding-bottom: 1rem; }

.custom-grid {
  border: 2px solid #f0f0f0;
  padding: 20px;
  width: 100%; }

.ps-checkbox input:checked ~ label:before {
  border-color: #AFC447 !important; }

.ps-checkbox input:checked ~ label:after {
  border-color: #AFC447 !important;
  color: #AFC447 !important; }

.ps-checkbox input ~ label {
  color: #999B95 !important;
  font-weight: 600; }

.ps-checkbox input:checked ~ label {
  color: #3C3D3A !important;
  font-weight: bold; }

.ed-dropdown-internal-input {
  color: #999B95;
  border: none !important; }
  .ed-dropdown-internal-input:focus {
    outline: none; }
  .ed-dropdown-internal-input:hover {
    outline: none; }

.ed-definition-name {
  font-size: 18px;
  color: #3C3D3A;
  border: none !important;
  width: 100%; }
  .ed-definition-name::-webkit-input-placeholder {
    color: #999B95;
    opacity: 1;
    /* Firefox */ }
  .ed-definition-name::-moz-placeholder {
    color: #999B95;
    opacity: 1;
    /* Firefox */ }
  .ed-definition-name:-ms-input-placeholder {
    color: #999B95;
    opacity: 1;
    /* Firefox */ }
  .ed-definition-name::-ms-input-placeholder {
    color: #999B95;
    opacity: 1;
    /* Firefox */ }
  .ed-definition-name::placeholder {
    color: #999B95;
    opacity: 1;
    /* Firefox */ }
  .ed-definition-name:focus, .ed-definition-name:hover {
    outline: none; }
  .ed-definition-name:disabled {
    background-color: transparent;
    opacity: 0.7; }

.ed-multiselect-selected-option {
  background-color: #E5E8E1;
  border-radius: 4px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  color: #383838;
  font-weight: 600;
  margin: 3px 4px;
  padding: 4px 10px;
  font-size: 11px; }
  .ed-multiselect-selected-option .ed-selected-option-close {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    margin-left: 4px;
    margin-bottom: 0px; }

.ed-multi-select-dropdown {
  border: 1px solid #E5E8E1;
  border-radius: 4px;
  margin-bottom: 1.5em; }
  .ed-multi-select-dropdown .ed-multi-select-dropdown-content {
    padding: 8px 10px; }
  .ed-multi-select-dropdown .ed-multi-select-checkbox-container {
    width: 33%;
    padding-left: 10px; }
  .ed-multi-select-dropdown .ed-multi-select-placeholder {
    color: #999B95;
    -ms-flex-item-align: center;
        align-self: center; }
  .ed-multi-select-dropdown .ed-dropdown-top-selected-container {
    -ms-flex-align: center;
        align-items: center;
    max-height: 100px;
    overflow-y: scroll;
    overflow: auto; }
  .ed-multi-select-dropdown .ed-dropdown-bottom-options-container {
    max-height: 90px;
    margin-top: 15px;
    overflow-y: scroll;
    overflow: auto; }
  .ed-multi-select-dropdown .ed-dropdown-all-checkbox {
    margin-top: 5px;
    margin-bottom: 5px; }

.ed-definition-list {
  padding-top: 145px; }
  .ed-definition-list .ed-definition-item {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    color: #3C3D3A;
    -webkit-transition: -webkit-box-shadow .2s;
    transition: -webkit-box-shadow .2s;
    transition: box-shadow .2s;
    transition: box-shadow .2s, -webkit-box-shadow .2s;
    -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0), 0 3px 6px rgba(0, 0, 0, 0);
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0), 0 3px 6px rgba(0, 0, 0, 0);
    background: #ffffff;
    margin: 0px 0px;
    padding: 10px 0px;
    border-radius: 4px;
    font-size: 16px;
    margin-left: -18px;
    margin-right: -22px;
    padding-left: 18px;
    padding-right: 22px;
    border-bottom: 1px solid #E5E8E1; }
    .ed-definition-list .ed-definition-item .col-xs-12 {
      padding-left: 0; }
    .ed-definition-list .ed-definition-item .ed-definition-list-params {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }

.ed-definition-more-button {
  margin-right: 12px;
  font-size: 17px;
  color: #999B95; }

.ed-definition-close-button {
  margin-right: 5px;
  color: #999B95;
  font-size: 17px; }

.ed-definition-internal-margin {
  margin-bottom: 10px; }

.ed-definition-section-heading {
  color: #383838;
  font-size: 12px;
  font-weight: bold;
  margin-top: 20px; }

.ed-definition-content-heading {
  color: #999B95;
  font-size: 12px;
  font-weight: 600; }

.ed-definition-clear-all {
  color: #999B95;
  font-size: 10px;
  font-weight: bold;
  line-height: 8px;
  -ms-flex-item-align: end;
      align-self: flex-end; }

.ed-definition-resource-type {
  width: 45%;
  padding-right: 15px; }
  .ed-definition-resource-type .disabled {
    opacity: 0.9 !important; }

.ed-definition-parameter {
  width: 45%;
  padding-right: 15px; }
  .ed-definition-parameter .disabled {
    opacity: 0.9 !important; }

.ed-definition-comparator {
  width: 30%;
  padding-right: 15px; }

.ed-definition-value {
  width: 20%;
  height: 38px !important; }
  .ed-definition-value input {
    height: 38px !important; }

.ed-definition-notification-checkbox {
  margin-right: 40px; }

.ed-definition-frequency-select {
  width: 45%;
  padding-right: 15px; }

.ed-definition-create-button {
  height: 32px;
  width: 64px;
  border-radius: 4px;
  font-size: 10px;
  font-weight: bold;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  color: #ffffff;
  background-color: #91b846; }
  .ed-definition-create-button:hover {
    color: #ffffff;
    opacity: 0.8; }

body {
  font-family: "Petasense Open Sans";
  overflow-y: hidden;
  background-color: white; }
  body.protected-page {
    background-color: #f7f7f7;
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }

th {
  display: table-cell;
  vertical-align: inherit;
  font-weight: bold;
  text-align: left; }

.clearfix:after {
  content: " ";
  /* Older browser do not support empty content */
  visibility: hidden;
  display: block;
  height: 0;
  clear: both; }

a:focus {
  outline: none; }

